import React from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import Select from 'react-select';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import BarChartIcon from '@mui/icons-material/BarChart';
import { metricOptions } from '../constants';

export const ChartHeader = ({
    headerRef,
    metric,
    setMetric,
    graphicType,
    setGraphicType,
    selectedStores,
    storeNames,
    metricProp
}) => {
    return (
        <div ref={headerRef} className="flex flex-row items-center px-5 text-kompamxblue text-base justify-center md:justify-between w-full" style={{ minHeight: "3.5rem" }}>
            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex items-center">
                    <div className="font-semibold mr-2">
                        Comparativa de {
                            metric === "Ventas" ? "Ventas" :
                                metric === "Pedidos" ? "Pedidos" :
                                    metric === "AOV" ? "Valor Promedio de Pedido" :
                                        metric === "Unidades" ? "Unidades Vendidas" :
                                            "Total Acumulado"
                        }
                    </div>
                    <Tooltip arrow title={
                        metric === "Ventas" ? `Comparación de ventas entre tiendas ${selectedStores.map(store => storeNames[store.value]).join(' y ')} en los ultimos 12 meses.` :
                            metric === "Pedidos" ? `Comparación de pedidos entre tiendas ${selectedStores.map(store => storeNames[store.value]).join(' y ')} en los ultimos 12 meses.` :
                                metric === "AOV" ? `Comparación del valor promedio de pedido entre tiendas ${selectedStores.map(store => storeNames[store.value]).join(' y ')} en los ultimos 12 meses.` :
                                    metric === "Unidades" ? `Comparación de unidades vendidas entre tiendas ${selectedStores.map(store => storeNames[store.value]).join(' y ')} en los ultimos 12 meses.` :
                                        `Comparación del total acumulado entre tiendas ${selectedStores.map(store => storeNames[store.value]).join(' y ')} en los ultimos 12 meses.`
                    }>
                        <InfoOutlined style={{ fontSize: 18 }} className="ml-auto" />
                    </Tooltip>
                </div>
                {!metricProp && (
                    <Select
                        className="ml-auto w-32 mr-2"
                        options={metricOptions}
                        value={metricOptions.find(option => option.value === metric)}
                        onChange={(option) => setMetric(option.value)}
                        placeholder="Métrica"
                    />
                )}
                <div className="flex flex-col md:flex-row">
                    <Tooltip arrow title="Ver gráfico de barras">
                        <BarChartIcon
                            style={{ fontSize: 24, cursor: 'pointer', color: graphicType === "BarChart" ? 'inherit' : 'lightgray' }}
                            className="mr-2"
                            onClick={() => setGraphicType("BarChart")}
                        />
                    </Tooltip>
                    <Tooltip arrow title="Ver gráfico de líneas">
                        <LineAxisIcon
                            style={{ fontSize: 24, cursor: 'pointer', color: graphicType === "LineChart" ? 'inherit' : 'lightgray' }}
                            onClick={() => setGraphicType("LineChart")}
                        />
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};
