import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend } from 'recharts';
import { Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import Select from 'react-select';
import { useTopbar } from '../../../helpers/TopbarContext';
import { useOrders } from '../../../helpers/OrdersContext';

const PeriodComparisonChart = ({ store: propStore, ordersGraph }) => {
    const [chartWidth, setChartWidth] = useState(window.innerWidth < 768 ? (window.innerWidth - 15) : ((window.innerWidth) * 0.5) - 50);
    const [selectedStore, setSelectedStore] = useState(null);
    const [chartData, setChartData] = useState([]);

    const { ordersData, stores, loading: ordersLoading } = useOrders();
    const { selectedChartInterval } = useTopbar();

    // Handle window resize
    useEffect(() => {
        const handleResize = () => {
            setChartWidth(window.innerWidth < 768 ? (window.innerWidth - 15) : ((window.innerWidth) * 0.5) - 50);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Initialize store selection
    useEffect(() => {
        if (propStore) {
            setSelectedStore(propStore);
        } else if (!selectedStore && stores.length > 0) {
            setSelectedStore(stores[0]);
        }
    }, [propStore, stores, selectedStore]);

    // Process chart data
    useEffect(() => {
        if (!ordersData || !selectedStore?.value) {
            setChartData([]);
            return;
        }

        const storeData = ordersData[selectedStore.value];
        if (!storeData) return;

        const { currentPeriod, comparisonPeriod } = storeData;

        const aggregateData = (orders = []) => {
            if (!Array.isArray(orders)) return [];

            const aggregated = {};
            orders.forEach(order => {
                const orderDate = order.date_timestamp instanceof Date
                    ? order.date_timestamp
                    : new Date(order.date_timestamp || order.order_creation_date);

                if (isNaN(orderDate.getTime())) return;

                let key;
                switch (selectedChartInterval.value) {
                    case 'SEMANA': {
                        const weekStart = new Date(orderDate);
                        weekStart.setDate(orderDate.getDate() - orderDate.getDay() + (orderDate.getDay() === 0 ? -6 : 1));
                        key = weekStart.toISOString().split('T')[0];
                        break;
                    }
                    case 'MES':
                        key = `${orderDate.getFullYear()}-${String(orderDate.getMonth() + 1).padStart(2, '0')}`;
                        break;
                    case 'ANO':
                        key = `${orderDate.getFullYear()}`;
                        break;
                    default: // 'DIA'
                        key = orderDate.toISOString().split('T')[0];
                }

                if (!aggregated[key]) {
                    aggregated[key] = { sales: 0, orders: 0 };
                }
                aggregated[key].sales += parseFloat(order.total_float || order.total_order_amount || 0);
                aggregated[key].orders += 1;
            });

            return Object.entries(aggregated)
                .map(([key, value]) => ({
                    date: key,
                    ...value
                }))
                .sort((a, b) => new Date(a.date) - new Date(b.date));
        };

        const currentAggregated = aggregateData(currentPeriod);
        const comparisonAggregated = comparisonPeriod ? aggregateData(comparisonPeriod) : [];

        const newChartData = currentAggregated.map((currentItem, index) => {
            const comparisonItem = comparisonAggregated[index] || { sales: 0, orders: 0 };
            return {
                date: currentItem.date,
                currentSales: currentItem.sales,
                currentOrders: currentItem.orders,
                comparisonSales: comparisonItem.sales,
                comparisonOrders: comparisonItem.orders
            };
        });

        setChartData(newChartData);
    }, [ordersData, selectedStore, selectedChartInterval]);

    const currencyFormatter = (value) => {
        return new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN'
        }).format(value);
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (!active || !payload || !payload.length) return null;

        const data = payload[0].payload;
        const date = new Date(data.date);
        let formattedDate;

        switch (selectedChartInterval.value) {
            case 'SEMANA':
                formattedDate = `Semana del ${date.toLocaleDateString()}`;
                break;
            case 'MES':
                formattedDate = `${date.toLocaleString('default', { month: 'long', year: 'numeric' })}`;
                break;
            case 'ANO':
                formattedDate = date.getFullYear().toString();
                break;
            default:
                formattedDate = date.toLocaleDateString();
        }

        const currentColor = selectedStore?.data?.color || "#8884d8";

        return (
            <div className="bg-white p-4 border border-gray-200 shadow-sm rounded-md">
                <p className="text-sm font-semibold">{formattedDate}</p>
                {!ordersGraph ? (
                    <>
                        <p style={{ color: currentColor }}>{`Ventas periodo actual: ${currencyFormatter(data.currentSales)}`}</p>
                        <p style={{ color: currentColor }}>{`Ventas periodo comparación: ${currencyFormatter(data.comparisonSales)}`}</p>
                    </>
                ) : (
                    <>
                        <p style={{ color: currentColor }}>{`Órdenes periodo actual: ${data.currentOrders}`}</p>
                        <p style={{ color: currentColor }}>{`Órdenes periodo comparación: ${data.comparisonOrders}`}</p>
                    </>
                )}
            </div>
        );
    };

    const renderChart = () => {
        const currentColor = selectedStore?.data?.color || "#8884d8";
        const previousColor = `${currentColor}80`; // Add 50% transparency

        return (
            <BarChart
                width={chartWidth - 40}
                height={300}
                data={chartData}
                margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="date"
                    tickFormatter={(value) => {
                        const date = new Date(value);
                        switch (selectedChartInterval.value) {
                            case 'SEMANA':
                                return `Sem ${date.getDate()}/${date.getMonth() + 1}`;
                            case 'MES':
                                return date.toLocaleString('default', { month: 'short' });
                            case 'ANO':
                                return date.getFullYear().toString();
                            default:
                                return date.toLocaleDateString();
                        }
                    }}
                />
                <YAxis tickFormatter={currencyFormatter} />
                <RechartsTooltip content={<CustomTooltip />} />
                <Legend />
                {!ordersGraph ? (
                    <>
                        <Bar
                            dataKey="currentSales"
                            fill={currentColor}
                            name="Ventas Periodo Actual"
                        />
                        <Bar
                            dataKey="comparisonSales"
                            fill={previousColor}
                            name="Ventas Periodo Comparación"
                        />
                    </>
                ) : (
                    <>
                        <Bar
                            dataKey="currentOrders"
                            fill={currentColor}
                            name="Órdenes Periodo Actual"
                        />
                        <Bar
                            dataKey="comparisonOrders"
                            fill={previousColor}
                            name="Órdenes Periodo Comparación"
                        />
                    </>
                )}
            </BarChart>
        );
    };

    return (
        <div className="flex flex-col items-start justify-start bg-white rounded-lg shadow-md m-1"
            style={{ minWidth: chartWidth, maxWidth: chartWidth, height: 400 }}>
            <div className="flex flex-col md:flex-row items-center px-5 text-kompamxblue text-base justify-center md:justify-between w-full"
                style={{ minHeight: "3.5rem" }}>
                <div className="flex items-center justify-start w-full">
                    <div className="font-semibold mr-2">
                        {ordersGraph ? "Órdenes" : "Ventas"} de Periodos
                    </div>
                    <Tooltip arrow title="Comparación de periodos">
                        <InfoOutlined style={{ fontSize: 18 }} />
                    </Tooltip>
                    {!propStore && (
                        <Select
                            className="ml-4 w-64"
                            options={stores}
                            value={selectedStore}
                            onChange={setSelectedStore}
                            placeholder="Selecciona una tienda"
                            isSearchable={false}
                        />
                    )}
                </div>
            </div>
            <hr className="w-full border-gray-300 mb-2" />

            <div className="flex items-center md:mt-0 w-full justify-center px-2 mb-2 h-full">
                {ordersLoading ? (
                    <div className="spinner" />
                ) : selectedStore ? (
                    renderChart()
                ) : (
                    <div className="flex justify-center items-center h-full w-full">
                        <p className="text-gray-500 text-lg">Selecciona una tienda para ver los datos</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PeriodComparisonChart;