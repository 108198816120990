export const convertTimestampToDate = (timestamp) => {
    if (!timestamp) return null;
    if (timestamp.toDate) {
        return timestamp.toDate();
    }
    if (typeof timestamp === 'string') {
        return new Date(timestamp);
    }
    if (typeof timestamp === 'number') {
        return new Date(timestamp * (timestamp < 1e12 ? 1000 : 1));
    }
    return null;
};

export const adjustDatesToTimezone = (start, end, timezone) => {
    const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    });

    const adjustDate = (date) => {
        const parts = formatter.formatToParts(date);
        const dateObject = {};
        parts.forEach(part => {
            if (['year', 'month', 'day', 'hour', 'minute', 'second'].includes(part.type)) {
                dateObject[part.type] = parseInt(part.value, 10);
            }
        });
        return new Date(Date.UTC(
            dateObject.year,
            dateObject.month - 1,
            dateObject.day,
            dateObject.hour,
            dateObject.minute,
            dateObject.second
        ));
    };

    return {
        adjustedStart: adjustDate(start),
        adjustedEnd: adjustDate(end)
    };
};

export const formatCurrency = (value) => {
    return `$${value.toLocaleString()}`;
};

export const getColor = (value) => {
    const intensity = Math.floor((value / 100) * 255);
    return `rgb(${255 - intensity}, ${255 - intensity}, 255)`;
};

