import React, { useState, useRef, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useTopbar } from '../../../../helpers/TopbarContext';
import { useOrders } from '../../../../helpers/OrdersContext';
import { useChartDimensions } from './hooks/useChartDimensions';
import { useChartData } from './hooks/useChartData';
import { ChartHeader } from './components/ChartHeader';
import { StoreSelect } from './components/StoreSelect';
import { Chart } from './components/Chart';

const StoreComparisonChart = ({ metricProp, storeIdsProp }) => {
    const {
        startDate,
        endDate,
        selectedChartInterval
    } = useTopbar();

    const { ordersData, stores, loading: ordersLoading } = useOrders();

    const [metric, setMetric] = useState(metricProp || "Ventas");
    const [graphicType, setGraphicType] = useState("BarChart");
    const [selectedStores, setSelectedStores] = useState([]);
    const [storeNames, setStoreNames] = useState({});

    const legendRef = useRef(null);
    const selectRef = useRef(null);
    const headerRef = useRef(null);

    const { chartWidth, chartHeight } = useChartDimensions(selectRef, legendRef, headerRef);
    const { renderChartData, totalsByStore } = useChartData(
        ordersData,
        selectedStores,
        metric,
        startDate,
        endDate,
        selectedChartInterval
    );

    // Initialize store options and names
    useEffect(() => {
        if (stores.length > 0) {
            const namesMap = {};
            stores.forEach(store => {
                namesMap[store.value] = store.label;
            });
            setStoreNames(namesMap);

            if (stores.length > 1 && !storeIdsProp) {
                setSelectedStores([stores[0], stores[1]]);
            } else if (storeIdsProp) {
                const selectedStoresFromProp = stores.filter(option => storeIdsProp.includes(option.value));
                setSelectedStores(selectedStoresFromProp);
            }
        }
    }, [stores, storeIdsProp]);

    useEffect(() => {
        if (metricProp) setMetric(metricProp);
    }, [metricProp]);

    if (stores.length < 2 && !storeIdsProp && !ordersLoading) {
        return (
            <div className="flex flex-col items-start justify-start bg-white rounded-lg shadow-md m-1" style={{ minWidth: chartWidth, maxWidth: chartWidth, height: 400 }}>
                <ChartHeader
                    headerRef={headerRef}
                    metric={metric}
                    selectedStores={selectedStores}
                    storeNames={storeNames}
                />
                <hr className="w-full border-gray-300" />
                <div className="flex items-center md:mt-0 w-full h-full justify-center px-2 mb-2">
                    <p className="text-gray-500 text-lg">
                        Selecciona al menos dos tiendas para comparar.
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-start justify-start bg-white rounded-lg shadow-md m-1" style={{ minWidth: chartWidth, maxWidth: chartWidth, height: 400 }}>
            <ChartHeader
                headerRef={headerRef}
                metric={metric}
                setMetric={setMetric}
                graphicType={graphicType}
                setGraphicType={setGraphicType}
                selectedStores={selectedStores}
                storeNames={storeNames}
                metricProp={metricProp}
            />
            <hr className="w-full border-gray-300" />

            {!storeIdsProp && stores.length > 2 && (
                <StoreSelect
                    selectRef={selectRef}
                    stores={stores}
                    selectedStores={selectedStores}
                    setSelectedStores={setSelectedStores}
                />
            )}

            {!ordersLoading ? (
                renderChartData.length ? (
                    <div className="flex items-center justify-center w-full h-full">
                        <Chart
                            graphicType={graphicType}
                            chartWidth={chartWidth}
                            chartHeight={chartHeight}
                            renderChartData={renderChartData}
                            selectedChartInterval={selectedChartInterval}
                            metric={metric}
                            selectedStores={selectedStores}
                            storeNames={storeNames}
                            totalsByStore={totalsByStore}
                            legendRef={legendRef}
                            ordersData={ordersData} // Make sure we pass ordersData here
                        />
                    </div>
                ) : (
                    <div className="flex items-center justify-center h-full w-full">
                        <p className="text-gray-500 text-lg">
                            No hay datos para mostrar
                        </p>
                    </div>
                )
            ) : (
                <div className="flex items-center justify-center h-full w-full">
                    <div className="spinner" />
                </div>
            )}

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};

export default StoreComparisonChart;