import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import supabaseClient from '../helpers/supabase';
import { useTopbar } from '../helpers/TopbarContext';
import { useOrders } from '../helpers/OrdersContext';
import { toast } from 'react-toastify';
import useAuth from '../hooks/useAuth';

const TrafficContext = createContext();

const BATCH_SIZE = 1000;

export function TrafficProvider({ children }) {
    const [state, setState] = useState({
        trafficData: {},
        stores: [],
        loading: false,
        error: null
    });

    const { currentUser } = useAuth();
    const {
        startDate,
        endDate,
        selectedTimezone,
        selectedChartInterval
    } = useTopbar();

    const { ordersData, stores: orderStores } = useOrders();

    // Fetch stores function - using stores from OrdersContext
    const fetchStores = async (userId) => {
        if (!userId) return [];
        try {
            const { data: stores, error } = await supabaseClient
                .from('stores')
                .select('id, custom_name, color')
                .eq('user_id', userId)
                .order('custom_name');

            if (error) throw error;

            return stores.map(store => ({
                value: store.id,
                label: store.custom_name || store.id,
                data: {
                    custom_name: store.custom_name,
                    color: store.color
                }
            }));
        } catch (error) {
            console.error("Error fetching stores:", error);
            toast.error("Error al cargar las tiendas");
            return [];
        }
    };

    // Fetch traffic data function with visit and order data combined
    const fetchTrafficData = async (userId, storeId, start, end) => {
        const isMercadoLibre = storeId.startsWith('ml_');
        const tableName = isMercadoLibre ? 'meli_days' : 'az_days';
        const pageViewsField = isMercadoLibre ? 'total_visits' : 'page_views';

        let query = supabaseClient
            .from(tableName)
            .select('*')
            .gte('date', start.toISOString().split('T')[0])
            .lte('date', end.toISOString().split('T')[0])
            .order('date', { ascending: true });

        // Use different column names based on platform
        if (isMercadoLibre) {
            query = query
                .eq('para_usuario', userId)
                .eq('para_tienda', storeId);
        } else {
            query = query
                .eq('user_id', userId)
                .eq('store_id', storeId);
        }

        const { data: visitsData, error } = await query;

        if (error) throw error;

        // Get orders data from OrdersContext
        const storeOrders = ordersData[storeId]?.currentPeriod || [];

        // Create a map of orders by date
        const ordersByDate = {};
        storeOrders.forEach(order => {
            const orderDate = new Date(order.date_timestamp);
            const dateKey = orderDate.toISOString().split('T')[0];

            if (!ordersByDate[dateKey]) {
                ordersByDate[dateKey] = 0;
            }
            ordersByDate[dateKey] += order.units_sold || 1;
        });

        // Combine visits and orders data
        return visitsData.map(day => {
            const dateKey = day.date;
            const pageViews = day[pageViewsField] || 0;
            const sales = ordersByDate[dateKey] || 0;

            return {
                name: dateKey,
                pageviews: pageViews,
                sales: sales,
                conversion: pageViews > 0 ? (sales / pageViews * 100) : 0
            };
        });
    };

    // Aggregate data based on interval
    const aggregateData = (data, interval) => {
        const aggregated = {};

        data.forEach(item => {
            let key;
            const date = new Date(item.name);

            switch (interval) {
                case 'SEMANA':
                    const day = date.getDay();
                    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
                    key = new Date(date.setDate(diff)).toISOString().split('T')[0];
                    break;
                case 'MES':
                    key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
                    break;
                case 'ANO':
                    key = `${date.getFullYear()}`;
                    break;
                default: // 'DIA'
                    key = item.name;
            }

            if (!aggregated[key]) {
                aggregated[key] = {
                    name: key,
                    pageviews: 0,
                    sales: 0,
                    conversion: 0
                };
            }

            aggregated[key].pageviews += item.pageviews;
            aggregated[key].sales += item.sales;
        });

        // Recalculate conversion rates
        Object.values(aggregated).forEach(item => {
            item.conversion = item.pageviews > 0
                ? (item.sales / item.pageviews * 100)
                : 0;

            if (isNaN(item.conversion)) {
                item.conversion = 0;
            }
            if (item.conversion === Infinity) {
                item.conversion = 100;
            }
        });

        return Object.values(aggregated).sort((a, b) => new Date(a.name) - new Date(b.name));
    };

    useEffect(() => {
        if (!currentUser?.uid) return;

        let mounted = true;
        const fetchData = async () => {
            setState(prev => ({ ...prev, loading: true, error: null }));

            try {
                const userStores = await fetchStores(currentUser.uid);
                if (!userStores.length || !mounted) return;

                const adjustedDates = {
                    start: new Date(startDate),
                    end: new Date(endDate)
                };

                // Process stores in batches
                const chunkedStores = chunk(userStores, 2);
                const newTrafficData = {};

                for (const storeChunk of chunkedStores) {
                    if (!mounted) break;

                    await Promise.all(storeChunk.map(async (store) => {
                        const data = await fetchTrafficData(
                            currentUser.uid,
                            store.value,
                            adjustedDates.start,
                            adjustedDates.end
                        );

                        if (mounted) {
                            newTrafficData[store.value] = {
                                rawData: data,
                                aggregatedData: selectedChartInterval
                                    ? aggregateData(data, selectedChartInterval.value)
                                    : data
                            };
                        }
                    }));
                }

                if (mounted) {
                    setState(prev => ({
                        ...prev,
                        trafficData: newTrafficData,
                        stores: userStores,
                        loading: false
                    }));
                }
            } catch (error) {
                console.error('Error fetching traffic data:', error);
                if (mounted) {
                    setState(prev => ({
                        ...prev,
                        error,
                        loading: false
                    }));
                    toast.error("Error al cargar los datos de tráfico");
                }
            }
        };

        const debounceTimer = setTimeout(fetchData, 300);
        return () => {
            mounted = false;
            clearTimeout(debounceTimer);
        };
    }, [currentUser, startDate, endDate, selectedTimezone, selectedChartInterval, ordersData]);

    const value = useMemo(() => ({
        ...state,
        aggregateData
    }), [state]);

    return (
        <TrafficContext.Provider value={value}>
            {children}
        </TrafficContext.Provider>
    );
}

export function useTraffic() {
    const context = useContext(TrafficContext);
    if (!context) {
        throw new Error('useTraffic must be used within a TrafficProvider');
    }
    return context;
}

// Utility function
function chunk(array, size) {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
    }
    return chunks;
}