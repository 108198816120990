import { LlamadaALaAccion } from '../components/onboarding/LlamadaALaAccion'
import { PieDePagina } from '../components/onboarding/PieDePagina'
import { Cabecera } from '../components/onboarding/Cabecera'
import { Heroe } from '../components/onboarding/Heroe'
import { Precios } from '../components/onboarding/Precios'
import { CaracteristicasPrincipales } from '../components/onboarding/CaracteristicasPrincipales'
import { CaracteristicasSecundarias } from '../components/onboarding/CaracteristicasSecundarias'
import { Testimonios } from '../components/onboarding/Testimonios'
import { PreguntasFrecuentes } from '../components/onboarding/PreguntasFrecuentes'

export default function OnBoarding() {
  return (
    <div className="bg-white">
      <Cabecera />
      <main>
        <Heroe />
        <CaracteristicasPrincipales />
        <CaracteristicasSecundarias />
        <LlamadaALaAccion />
        <Testimonios />
        <Precios />
        <PreguntasFrecuentes />
      </main>
      <PieDePagina />
    </div >
  )
}
