export const calculateMetricValue = (orders, date, metric, interval) => {
    const ordersInPeriod = orders.filter(order => {
        const orderDate = new Date(order.date_timestamp || order.order_creation_date);

        switch (interval) {
            case 'DIA':
                return orderDate.toISOString().split('T')[0] === date.toISOString().split('T')[0];
            case 'SEMANA': {
                const weekStart = new Date(date);
                weekStart.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1));
                const weekEnd = new Date(weekStart);
                weekEnd.setDate(weekStart.getDate() + 6);
                return orderDate >= weekStart && orderDate <= weekEnd;
            }
            case 'MES':
                return orderDate.getMonth() === date.getMonth() &&
                    orderDate.getFullYear() === date.getFullYear();
            case 'ANO':
                return orderDate.getFullYear() === date.getFullYear();
            default:
                return false;
        }
    });

    switch (metric) {
        case 'Ventas':
            return ordersInPeriod.reduce((sum, order) =>
                sum + (parseFloat(order.total_float || order.total_order_amount) || 0), 0);
        case 'Pedidos':
            return ordersInPeriod.length;
        case 'AOV': {
            const totalSales = ordersInPeriod.reduce((sum, order) =>
                sum + (parseFloat(order.total_float || order.total_order_amount) || 0), 0);
            return ordersInPeriod.length > 0 ? totalSales / ordersInPeriod.length : 0;
        }
        case 'Unidades':
            return ordersInPeriod.reduce((sum, order) =>
                sum + (parseInt(order.unidades_vendidas || order.units_sold) || 0), 0);
        case 'TotalAcumulado':
            return orders
                .filter(order => new Date(order.date_timestamp || order.order_creation_date) <= date)
                .reduce((sum, order) =>
                    sum + (parseFloat(order.total_float || order.total_order_amount) || 0), 0);
        default:
            return 0;
    }
};