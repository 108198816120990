// src/components/payment/StripeForm.js
import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { doc, getDoc, onSnapshot, updateDoc, serverTimestamp, arrayUnion, collection, addDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { motion } from 'framer-motion';
import { Loader2 } from 'lucide-react';
import { auth, db, functions } from '../../helpers/firebase';
import PaymentForm from './PaymentForm';
import { TrialExpiredNotice } from './TrialExpiredNotice';
import { BenefitsList } from './BenefitsList';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const StripeForm = () => {
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (!user) {
                try {
                    const anonUser = await signInAnonymously(auth);
                    setUser(anonUser.user);
                } catch (error) {
                    console.error("Error signing in anonymously:", error);
                    setError("Error al iniciar sesión");
                    setIsLoading(false);
                }
                return;
            }

            setUser(user);

            try {
                const userRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userRef);

                if (!userDoc.exists() || !userDoc.data().stripeCustomerId) {
                    const initStripeCustomer = httpsCallable(functions, 'initializeStripeCustomer');
                    await initStripeCustomer();
                }

                const unsubscribeUser = onSnapshot(userRef, (snapshot) => {
                    if (snapshot.exists()) {
                        setUserData(snapshot.data());
                    }
                    setIsLoading(false);
                }, (error) => {
                    setError('Error cargando datos del usuario');
                    setIsLoading(false);
                });

                return () => unsubscribeUser();
            } catch (error) {
                console.error('Error:', error);
                setError('Error al cargar información del usuario');
                setIsLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Loader2 className="w-12 h-12 animate-spin text-indigo-600" />
            </div>
        );
    }

    const handleSaveCard = async (paymentMethodId) => {
        console.log('🎯 Iniciando handleSaveCard con paymentMethodId:', paymentMethodId);
        setIsLoading(true);

        try {
            const userRef = doc(db, 'users', user.uid);

            // 1. Actualizar el método de pago predeterminado
            console.log('💳 Actualizando método de pago predeterminado...');
            await updateDoc(userRef, {
                default_payment_method: paymentMethodId,
                updated_at: serverTimestamp()
            });

            // 2. Crear o actualizar la suscripción en Stripe
            console.log('📝 Creando suscripción en Stripe...');
            const createSubscription = httpsCallable(functions, 'createSubscription');
            const { data: subscriptionData } = await createSubscription({
                paymentMethodId,
                priceId: 'price_premium_monthly', // Asegúrate de que este ID coincida con tu Stripe
                customerId: userData.stripeCustomerId
            });

            console.log('📦 Datos de suscripción recibidos:', subscriptionData);

            // 3. Actualizar el documento del usuario con los datos de la suscripción
            console.log('🔄 Actualizando estado de suscripción en Firestore...');
            const subscriptionUpdateData = {
                subscription_status: 'active',
                subscription_type: 'premium',
                subscription_id: subscriptionData.subscriptionId,
                subscription_current_period_end: subscriptionData.currentPeriodEnd,
                subscription_current_period_start: subscriptionData.currentPeriodStart,
                subscription_cancel_at_period_end: false,
                updated_at: serverTimestamp(),
                payment_methods: arrayUnion({
                    id: paymentMethodId,
                    type: 'card',
                    status: 'active',
                    is_default: true,
                    created_at: serverTimestamp()
                })
            };

            await updateDoc(userRef, subscriptionUpdateData);
            console.log('✅ Documento de usuario actualizado exitosamente');

            // 4. Configurar el webhook para manejar eventos de la suscripción
            console.log('🎣 Configurando webhook para la suscripción...');
            const setupWebhook = httpsCallable(functions, 'setupSubscriptionWebhook');
            await setupWebhook({
                subscriptionId: subscriptionData.subscriptionId,
                userId: user.uid
            });

            // 5. Registrar la transacción exitosa
            console.log('📊 Registrando transacción...');
            const transactionRef = collection(db, 'transactions');
            await addDoc(transactionRef, {
                user_id: user.uid,
                payment_method_id: paymentMethodId,
                subscription_id: subscriptionData.subscriptionId,
                amount: 10000, // $100 USD en centavos
                currency: 'usd',
                status: 'successful',
                type: 'subscription_activation',
                created_at: serverTimestamp()
            });

            // 6. Notificar al usuario y redirigir
            console.log('🎉 Proceso completado exitosamente');
            toast.success('¡Tu suscripción ha sido activada exitosamente!');

            // Pequeña pausa para asegurar que Firestore se actualice
            await new Promise(resolve => setTimeout(resolve, 1000));

            // Redirigir al usuario al dashboard
            navigate('/tablero');

        } catch (error) {
            console.error('❌ Error en handleSaveCard:', error);
            setError(error.message || 'Error al procesar la suscripción');
            toast.error('Hubo un error al procesar tu suscripción. Por favor, intenta de nuevo.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="bg-gray-50 min-h-screen w-full px-4 sm:px-6 lg:px-8 py-8">
            <div className="max-w-2xl mx-auto">
                {error && (
                    <motion.div
                        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4"
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                    >
                        <strong className="font-bold">Error: </strong>
                        <span>{error}</span>
                    </motion.div>
                )}

                <motion.div
                    className="space-y-6"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                >
                    <TrialExpiredNotice />
                    <BenefitsList />

                    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                        <div className="p-6">
                            <PaymentForm
                                onSave={handleSaveCard}
                                userData={userData}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default StripeForm;