import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { useTopbar } from '../helpers/TopbarContext';
import supabaseClient from '../helpers/supabase';
import { toast } from 'react-toastify';
import useAuth from '../hooks/useAuth';

const AdsContext = createContext();

const BATCH_SIZE = 1000;

const fetchStoreAdsData = async (userId, storeId, startDate, endDate) => {
    const isMercadoLibre = storeId.startsWith('ml_');
    const tableName = isMercadoLibre ? 'meli_ads_by_day' : 'az_ads_data';

    let allAdsData = [];
    let offset = 0;

    while (true) {
        const query = supabaseClient
            .from(tableName)
            .select('*')
            .eq('user_id', userId)
            .eq('store_id', storeId)
            .gte('date', startDate.toISOString().split('T')[0])
            .lte('date', endDate.toISOString().split('T')[0])
            .order('date', { ascending: true })
            .range(offset, offset + BATCH_SIZE - 1);

        const { data: adsData, error } = await query;

        if (error) throw error;
        if (!adsData?.length) break;

        const transformedData = adsData.map(item => ({
            date: item.date,
            sales: isMercadoLibre ? item.ads_amount : item.sales7d,
            cost: isMercadoLibre ? item.ads_cost : item.cost,
            impressions: isMercadoLibre ? item.ads_impressions : item.impressions,
            clicks: isMercadoLibre ? item.ads_clicks : item.clicks
        }));

        allAdsData.push(...transformedData);

        if (adsData.length < BATCH_SIZE) break;
        offset += BATCH_SIZE;
    }

    return allAdsData;
};

export function AdsProvider({ children }) {
    const [state, setState] = useState({
        adsInfo: {},
        loading: false,
        error: null
    });

    const { currentUser } = useAuth();
    const { startDate, endDate, selectedTimezone } = useTopbar();

    const fetchStores = useCallback(async (userId) => {
        if (!userId) return [];
        try {
            const { data: stores, error } = await supabaseClient
                .from('stores')
                .select('id, custom_name')
                .eq('user_id', userId)
                .order('custom_name');

            if (error) throw error;

            return stores.map(store => ({
                value: store.id,
                label: store.custom_name || store.id
            }));
        } catch (error) {
            console.error("Error fetching stores:", error);
            toast.error("Error al cargar las tiendas");
            return [];
        }
    }, []);

    useEffect(() => {
        if (!currentUser?.uid) return;

        let mounted = true;
        const fetchData = async () => {
            setState(prev => ({ ...prev, loading: true, error: null }));

            try {
                const userStores = await fetchStores(currentUser.uid);
                if (!userStores.length || !mounted) return;

                const newAdsInfo = {};

                // Process stores in batches to control concurrency
                const chunkedStores = chunk(userStores, 2);

                for (const storeChunk of chunkedStores) {
                    if (!mounted) break;

                    await Promise.all(storeChunk.map(async (store) => {
                        const adsData = await fetchStoreAdsData(
                            currentUser.uid,
                            store.value,
                            startDate,
                            endDate
                        );

                        if (mounted) {
                            newAdsInfo[store.value] = adsData;
                        }
                    }));
                }

                if (mounted) {
                    setState(prev => ({
                        ...prev,
                        adsInfo: newAdsInfo,
                        loading: false
                    }));
                }
            } catch (error) {
                console.error('Error fetching ads data:', error);
                if (mounted) {
                    setState(prev => ({
                        ...prev,
                        error,
                        loading: false
                    }));
                    toast.error("Error al cargar los datos de publicidad");
                }
            }
        };

        const debounceTimer = setTimeout(fetchData, 300);
        return () => {
            mounted = false;
            clearTimeout(debounceTimer);
        };
    }, [currentUser, startDate, endDate, selectedTimezone, fetchStores]);

    const value = useMemo(() => ({
        ...state
    }), [state]);

    return (
        <AdsContext.Provider value={value}>
            {children}
        </AdsContext.Provider>
    );
}

export function useAds() {
    const context = useContext(AdsContext);
    if (!context) {
        throw new Error('useAds must be used within an AdsProvider');
    }
    return context;
}

// Utility function
function chunk(array, size) {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
    }
    return chunks;
}