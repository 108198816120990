import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip as RechartsTooltip } from 'recharts';
import { useOrders } from '../../../../helpers/OrdersContext';
import az from '../../../../assets/merchantspring/az.jpg';
import ml from '../../../../assets/merchantspring/ml.jpg';
import mx from '../../../../assets/merchantspring/mx.svg';
import { CustomLegend, CustomTooltip } from './components';

const SalesMixChart = ({ storeIds, productsProp }) => {
    const [chartWidth, setChartWidth] = useState(window.innerWidth < 768 ? ((window.innerWidth - 15)) : ((window.innerWidth * 0.5) - 50));

    const { metrics, stores, loading } = useOrders();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setChartWidth((window.innerWidth - 15));
                return;
            }
            const newChartWidth = ((window.innerWidth * 0.5) - 50);
            setChartWidth(newChartWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const memoizedChartData = useMemo(() => {
        if (!metrics || Object.keys(metrics).length === 0) return [];

        // Filter stores if storeIds is provided
        const relevantStoreIds = storeIds && storeIds.length > 0
            ? storeIds
            : Object.keys(metrics);

        // console.log('metrics', metrics);

        // Transform metrics data into chart format
        const chartData = relevantStoreIds.map(storeId => {
            const storeMetrics = metrics[storeId];
            if (!storeMetrics) return null;

            const storeInfo = stores.find(store => store.value === storeId);
            if (!storeInfo) return null;

            const prefix = storeId.split('_')[0];
            const logo = prefix === 'ml' ? ml : prefix === 'az' ? az : '';
            const mxLogo = storeInfo.label !== 'Otras tiendas' ? mx : '';

            return {
                name: storeInfo.label,
                value: storeMetrics.current.Ventas || 0,
                logo,
                mxLogo,
                id: storeId,
                color: storeInfo.data.color || 'black'
            };
        }).filter(Boolean);

        // Calculate totals and percentages
        const totalValue = chartData.reduce((sum, item) => sum + item.value, 0);

        return chartData
            .map(item => ({
                ...item,
                percent: totalValue ? (item.value / totalValue) : 0,
                total: totalValue
            }))
            .sort((a, b) => b.value - a.value);
    }, [metrics, stores, storeIds]);

    return (
        <div className="flex flex-col items-start justify-start bg-white rounded-lg shadow-md m-1" style={{ minWidth: chartWidth, maxWidth: chartWidth, height: 400 }}>
            <div className="flex flex-row px-5 items-center w-full text-kompamxblue text-base" style={{ minHeight: "3.5rem" }}>
                <div className="font-semibold mr-2">
                    Mezcla de ventas
                </div>
                <Tooltip arrow title="Acciones que cada una de sus tiendas aportan a sus ventas totales.">
                    <InfoOutlined style={{ fontSize: 18 }} className='ml-auto' />
                </Tooltip>
            </div>
            <hr className="w-full border-gray-300" />
            {loading ? (
                <div className="flex items-center justify-center w-full h-full">
                    <div className='spinner' />
                </div>
            ) : memoizedChartData.length === 0 ? (
                <div className="flex justify-center items-center h-full w-full">
                    <p className="text-gray-500 text-lg">No hay datos para mostrar</p>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center h-full w-full">
                    <PieChart width={chartWidth} height={300}>
                        <Pie
                            data={memoizedChartData}
                            outerRadius={(chartWidth) > 550 ? 100 : (chartWidth / 8)}
                            cx={(chartWidth) > 550 ? ((chartWidth / 5) + 25) : (chartWidth / 6)}
                            cy={150}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {memoizedChartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                        </Pie>
                        <Legend
                            layout="vertical"
                            align="right"
                            verticalAlign="middle"
                            content={(props) => <CustomLegend {...props} chartWidth={chartWidth} />}
                        />
                        <RechartsTooltip content={<CustomTooltip />} />
                    </PieChart>
                </div>
            )}
        </div>
    );
};

export default SalesMixChart;