import { useState, useEffect } from 'react';

export const useChartDimensions = (selectRef, legendRef, headerRef) => {
    const [selectHeight, setSelectHeight] = useState(0);
    const [legendHeight, setLegendHeight] = useState(0);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [chartWidth, setChartWidth] = useState(
        window.innerWidth < 768 ? (window.innerWidth - 15) : ((window.innerWidth * 0.5) - 50)
    );

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setChartWidth((window.innerWidth - 15));
                return;
            }
            const newChartWidth = (window.innerWidth * 0.5) - 50;
            setChartWidth(newChartWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (legendRef.current) {
            const measureLegendHeight = () => {
                const height = legendRef.current.getBoundingClientRect().height;
                setLegendHeight(height);
            };

            measureLegendHeight();
            const resizeObserver = new ResizeObserver(measureLegendHeight);
            resizeObserver.observe(legendRef.current);
            return () => resizeObserver.disconnect();
        }
    }, [legendRef.current]);

    useEffect(() => {
        if (selectRef.current) {
            const measureSelectHeight = () => {
                const height = selectRef.current.getBoundingClientRect().height;
                setSelectHeight(height);
            };

            measureSelectHeight();
            const resizeObserver = new ResizeObserver(measureSelectHeight);
            resizeObserver.observe(selectRef.current);
            return () => resizeObserver.disconnect();
        }
    }, [selectRef.current]);

    useEffect(() => {
        if (headerRef.current) {
            const measureHeaderHeight = () => {
                const height = headerRef.current.getBoundingClientRect().height;
                setHeaderHeight(height);
            };

            measureHeaderHeight();
            const resizeObserver = new ResizeObserver(measureHeaderHeight);
            resizeObserver.observe(headerRef.current);
            return () => resizeObserver.disconnect();
        }
    }, [headerRef.current]);

    const chartHeight = 420 - (selectHeight + (legendHeight || 41) + (headerHeight || 56));

    return { chartWidth, chartHeight, selectHeight, legendHeight, headerHeight };
};