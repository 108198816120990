// TestContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

const TestContext = createContext();

export function TestProvider({ children }) {
    const [testMode, setTestMode] = useState(false);;

    const value = {
        testMode,
        setTestMode,
    };

    return (
        <TestContext.Provider value={value}>
            {children}
        </TestContext.Provider>
    );
}

export function useTest() {
    const context = useContext(TestContext);
    if (context === undefined) {
        throw new Error('useTest must be used within a TestProvider');
    }
    return context;
}