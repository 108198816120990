import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { useTopbar } from '../helpers/TopbarContext';
import supabaseClient from '../helpers/supabase';
import { toast } from 'react-toastify';
import useAuth from '../hooks/useAuth';

const OrdersContext = createContext();

// Constants
const BATCH_SIZE = 1000;
const DEFAULT_METRICS = {
    Ventas: 0,
    Pedidos: 0,
    AOV: 0,
    Unidades: 0,
    TotalAcumulado: 0,
    TotalVentas: 0
};

// Memoized timezone adjustment function
const adjustToTimezone = memoize((date, timezone) => {
    if (!date || !timezone) return date;
    const tz = timezone?.value || timezone;

    try {
        return new Date(date.toLocaleString('en-US', { timeZone: tz }));
    } catch (error) {
        console.warn('Timezone conversion failed:', error);
        return date;
    }
});

const fetchProductInfo = async (storeId, productIds) => {
    const isMercadoLibre = storeId.startsWith('ml_');
    // if (!isMercadoLibre) console.log('Is MercadoLibre:', isMercadoLibre, productIds);
    const tableName = isMercadoLibre ? 'meli_products' : 'az_products';
    const idField = isMercadoLibre ? 'listing_id' : 'asin1';
    const thumbnailField = isMercadoLibre ? 'listing_thumbnail' : 'thumbnail';

    // Split productIds into chunks to avoid query size limitations
    const chunks = chunk(productIds, 100);
    let allProducts = new Map();

    // if (!isMercadoLibre) console.log('Chunks:', chunks);

    for (const productIdChunk of chunks) {
        // if (!isMercadoLibre) console.log('Product ID Chunk:', productIdChunk);
        const { data: products, error } = await supabaseClient
            .from(tableName)
            .select('*')
            .in(idField, productIdChunk);

        // if length ofg data > 0
        // if (products.length > 0 && !isMercadoLibre) console.log('Data:', products);
        // else if (!isMercadoLibre) console.log('Data:', products);

        if (error) throw error;
        if (!products?.length) continue;

        products.forEach(product => {
            allProducts.set(product[idField], {
                thumbnail: product[thumbnailField] || null,
                available_quantity: isMercadoLibre ?
                    product.listing_available_quantity :
                    product.quantity
            });
        });
    }

    return allProducts;
};

// Fetch order items function
const fetchOrderItems = async (userId, storeId, orderIds, isMercadoLibre) => {
    const tableName = isMercadoLibre ? 'meli_order_items' : 'az_order_items';

    // Split orderIds into chunks to avoid query size limitations
    const chunks = chunk(orderIds, 100);
    let allItems = [];

    for (const orderIdChunk of chunks) {
        const { data: items, error } = await supabaseClient
            .from(tableName)
            .select('*')
            .in('order_id', orderIdChunk);

        if (error) throw error;
        if (!items?.length) continue;

        // Get unique product IDs from items
        const productIds = [...new Set(items.map(item => item.product_id))];

        // Fetch product information for all items in this chunk
        const productInfo = await fetchProductInfo(storeId, productIds);

        const transformedItems = items.map(item => ({
            order_item_id: isMercadoLibre ? item.id.toString() : item.order_item_id,
            order_id: item.order_id.toString(),
            product_id: item.product_id,
            seller_sku: item.seller_sku,
            title: item.title,
            quantity: isMercadoLibre ? item.quantity : item.quantity_ordered,
            unit_price: isMercadoLibre ? parseFloat(item.unit_price) : parseFloat(item.item_price),
            condition: isMercadoLibre ? item.condition : item.condition_id,
            category_id: isMercadoLibre ? item.category_id : null,
            variation_id: isMercadoLibre ? item.variation_id : null,
            sale_fee: isMercadoLibre ? parseFloat(item.sale_fee) : null,
            shipping_price: isMercadoLibre ? null : parseFloat(item.shipping_price),
            item_tax: isMercadoLibre ? null : parseFloat(item.item_tax),
            shipping_tax: isMercadoLibre ? null : parseFloat(item.shipping_tax),
            // Add product info
            thumbnail: productInfo.get(item.product_id)?.thumbnail || null,
            available_quantity: productInfo.get(item.product_id)?.available_quantity || 0
        }));

        allItems.push(...transformedItems);
    }

    return allItems;
};

// Modified fetch store orders function with order items
const fetchStoreOrders = async (userId, storeId, startDate, endDate) => {
    const isMercadoLibre = storeId.startsWith('ml_');
    const tableName = isMercadoLibre ? 'meli_orders' : 'az_orders';
    const dateField = isMercadoLibre ? 'order_creation_date' : 'date_timestamp';

    let allOrders = [];
    let offset = 0;

    while (true) {
        const { data: orders, error } = await supabaseClient
            .from(tableName)
            .select('*')
            .eq('user_id', userId)
            .eq('store_id', storeId)
            .gte(dateField, startDate.toISOString())
            .lte(dateField, endDate.toISOString())
            .order(dateField, { ascending: true })
            .range(offset, offset + BATCH_SIZE - 1);

        if (error) throw error;
        if (!orders?.length) break;

        const orderIds = orders.map(order => order.order_id);
        const orderItems = await fetchOrderItems(userId, storeId, orderIds, isMercadoLibre);

        const transformedOrders = orders.map(order => ({
            date_timestamp: new Date(order[dateField]),
            total_float: parseFloat(isMercadoLibre ? order.total_float : order.total_order_amount) || 0,
            units_sold: parseInt(isMercadoLibre ? order.units_sold : order.unidades_vendidas) || 1,
            order_id: order.order_id.toString(),
            items: orderItems.filter(item => item.order_id === order.order_id.toString())
        }));

        allOrders.push(...transformedOrders);

        if (orders.length < BATCH_SIZE) break;
        offset += BATCH_SIZE;
    }

    return allOrders;
};

// Calculate metrics function
const calculateMetrics = (orders, startDate, endDate) => {
    if (!Array.isArray(orders)) return DEFAULT_METRICS;

    const start = new Date(startDate);
    const end = new Date(endDate);

    let totalSales = 0;
    let totalUnits = 0;
    let orderCount = 0;

    for (const order of orders) {
        const orderDate = order.date_timestamp;
        if (orderDate >= start && orderDate <= end) {
            totalSales += order.total_float;
            totalUnits += order.units_sold;
            orderCount++;
        }
    }

    return {
        Ventas: totalSales,
        Pedidos: orderCount,
        AOV: orderCount ? totalSales / orderCount : 0,
        Unidades: totalUnits,
        TotalAcumulado: totalSales,
        TotalVentas: totalSales
    };
};

export function OrdersProvider({ children }) {
    const [state, setState] = useState({
        ordersData: {},
        stores: [],
        loading: false,
        error: null,
        metrics: {}
    });

    const { currentUser } = useAuth();
    const {
        startDate,
        endDate,
        comparisonStartDate,
        comparisonEndDate,
        selectedTimezone,
    } = useTopbar();

    // Memoized stores fetch
    const fetchStores = useCallback(async (userId) => {
        if (!userId) return [];
        try {
            const { data: stores, error } = await supabaseClient
                .from('stores')
                .select('id, custom_name, color')
                .eq('user_id', userId)
                .order('custom_name');

            if (error) throw error;

            return stores.map(store => ({
                value: store.id,
                label: store.custom_name || store.id,
                data: {
                    custom_name: store.custom_name,
                    color: store.color
                }
            }));
        } catch (error) {
            console.error("Error fetching stores:", error);
            toast.error("Error al cargar las tiendas");
            return [];
        }
    }, []);

    useEffect(() => {
        if (!currentUser?.uid) return;

        let mounted = true;
        const fetchData = async () => {
            setState(prev => ({ ...prev, loading: true, error: null }));

            try {
                const userStores = await fetchStores(currentUser.uid);
                if (!userStores.length || !mounted) return;

                const adjustedDates = {
                    start: adjustToTimezone(new Date(startDate), selectedTimezone),
                    end: adjustToTimezone(new Date(endDate), selectedTimezone),
                    comparisonStart: comparisonStartDate ?
                        adjustToTimezone(new Date(comparisonStartDate), selectedTimezone) : null,
                    comparisonEnd: comparisonEndDate ?
                        adjustToTimezone(new Date(comparisonEndDate), selectedTimezone) : null
                };

                // Process stores in batches to control concurrency
                const chunkedStores = chunk(userStores, 2);
                const newOrdersData = {};

                for (const storeChunk of chunkedStores) {
                    if (!mounted) break;

                    await Promise.all(storeChunk.map(async (store) => {
                        const [currentPeriod, comparisonPeriod] = await Promise.all([
                            fetchStoreOrders(
                                currentUser.uid,
                                store.value,
                                adjustedDates.start,
                                adjustedDates.end
                            ),
                            adjustedDates.comparisonStart && adjustedDates.comparisonEnd ?
                                fetchStoreOrders(
                                    currentUser.uid,
                                    store.value,
                                    adjustedDates.comparisonStart,
                                    adjustedDates.comparisonEnd
                                ) : Promise.resolve(null)
                        ]);

                        if (mounted) {
                            newOrdersData[store.value] = {
                                currentPeriod,
                                comparisonPeriod
                            };
                        }
                    }));
                }

                if (mounted) {
                    const newMetrics = {};
                    for (const [storeId, periods] of Object.entries(newOrdersData)) {
                        newMetrics[storeId] = {
                            current: calculateMetrics(
                                periods.currentPeriod,
                                adjustedDates.start,
                                adjustedDates.end
                            ),
                            comparison: periods.comparisonPeriod ?
                                calculateMetrics(
                                    periods.comparisonPeriod,
                                    adjustedDates.comparisonStart,
                                    adjustedDates.comparisonEnd
                                ) : null
                        };
                    }

                    setState(prev => ({
                        ...prev,
                        ordersData: newOrdersData,
                        metrics: newMetrics,
                        stores: userStores,
                        loading: false
                    }));
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                if (mounted) {
                    setState(prev => ({
                        ...prev,
                        error,
                        loading: false
                    }));
                    toast.error("Error al cargar los datos");
                }
            }
        };

        const debounceTimer = setTimeout(fetchData, 300);
        return () => {
            mounted = false;
            clearTimeout(debounceTimer);
        };
    }, [currentUser, startDate, endDate, comparisonStartDate, comparisonEndDate, selectedTimezone, fetchStores]);

    const value = useMemo(() => ({
        ...state
    }), [state]);

    return (
        <OrdersContext.Provider value={value}>
            {children}
        </OrdersContext.Provider>
    );
}

export function useOrders() {
    const context = useContext(OrdersContext);
    if (!context) {
        throw new Error('useOrders must be used within an OrdersProvider');
    }
    return context;
}

// Utility functions
function memoize(fn) {
    const cache = new Map();
    return (...args) => {
        const key = args.join('|');
        if (cache.has(key)) return cache.get(key);
        const result = fn(...args);
        cache.set(key, result);
        return result;
    };
}

function chunk(array, size) {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
    }
    return chunks;
}