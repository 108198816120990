import React, { useState } from 'react'
import { Boton } from '../../components/onboarding/Boton'
import { CampoSeleccion, CampoTexto } from '../../components/onboarding/Campos'
import { Logo } from '../../components/onboarding/Logo'
import { DisenoFino } from '../../components/onboarding/DisenoFino'
import { Link } from 'react-router-dom'
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../../helpers/firebase'
import { doc, setDoc } from 'firebase/firestore'

export default function Registro() {
  const [fuenteReferencia, setFuenteReferencia] = useState('')
  const [fuenteReferenciaOtro, setFuenteReferenciaOtro] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [errorColor, setErrorColor] = useState('red')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [nombre, setNombre] = useState('')
  const [apellido, setApellido] = useState('')

  const handleSignUp = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      await createUserWithEmailAndPassword(auth, email, password)
      await setDoc(doc(db, 'users', auth.currentUser.uid), {
        email,
        first_name: nombre,
        last_name: apellido,
        referral_source: fuenteReferencia === 'otro' ? fuenteReferenciaOtro : fuenteReferencia,
      })
      setErrorColor('green')
      setLoading(false)
    } catch (error) {
      console.error(error.code, error.message)
      setLoading(false)
      setErrorColor('red')
      if (error.code === 'auth/email-already-in-use') {
        setError('Ya existe una cuenta con el correo electrónico que proporcionaste')
      } else if (error.code === 'auth/invalid-email') {
        setError('El correo electrónico que proporcionaste no es válido')
      } else if (error.code === 'auth/weak-password') {
        setError('La contraseña que proporcionaste es débil')
      } else {
        setError('Error al registrarse')
      }
    }
  }

  return (
    <DisenoFino>
      <div className="flex">
        <Link to="/" aria-label="Inicio">
          <Logo className="h-10 w-auto" />
        </Link>
      </div>
      <h2 className="mt-20 text-lg font-semibold text-gray-900">
        Comienza gratis
      </h2>
      <p className="mt-2 text-sm text-gray-700">
        ¿Ya estás registrado?{' '}
        <Link
          to="/iniciar-sesion"
          className="font-medium text-blue-600 hover:underline"
        >
          Inicia sesión
        </Link>{' '}
        en tu cuenta.
      </p>
      <form
        action="#"
        className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2"
      >
        <CampoTexto
          etiqueta="Nombre"
          nombre="first_name"
          tipo="text"
          autoComplete="given-name"
          required
          onChange={(e) => setNombre(e.target.value)}
          value={nombre}
        />
        <CampoTexto
          etiqueta="Apellido"
          nombre="last_name"
          tipo="text"
          autoComplete="family-name"
          required
          onChange={(e) => setApellido(e.target.value)}
          value={apellido}
        />
        <CampoTexto
          className="col-span-full"
          etiqueta="Correo electrónico"
          nombre="email"
          tipo="email"
          autoComplete="email"
          required
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <CampoTexto
          className="col-span-full"
          etiqueta="Contraseña"
          nombre="password"
          tipo="password"
          autoComplete="new-password"
          required
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <CampoSeleccion
          className="col-span-full"
          label="¿Cómo te enteraste de nosotros?"
          name="referral_source"
          value={fuenteReferencia}
          onChange={(e) => setFuenteReferencia(e.target.value)}
        >
          <option value="">Selecciona una opción</option>
          <option value="google">Búsqueda en Google</option>
          <option value="amigo">Recomendación de un amigo</option>
          <option value="redes">Anuncio en redes sociales</option>
          <option value="podcast">Podcast sobre e-commerce</option>
          <option value="otro">Otro</option>
        </CampoSeleccion>
        {fuenteReferencia === 'otro' && (
          <CampoTexto
            className="col-span-full"
            etiqueta="Especifica cómo te enteraste de nosotros"
            nombre="referral_source_other"
            tipo="text"
            required
            onChange={(e) => setFuenteReferenciaOtro(e.target.value)}
            value={fuenteReferenciaOtro}
          />
        )}
        <div className="col-span-full">
          <Boton
            type="submit"
            variante="solido"
            color="azul"
            className="w-full"
            onClick={handleSignUp}
            disabled={loading}
          >
            <span>
              Registrarse <span aria-hidden="true">&rarr;</span>
            </span>
          </Boton>
        </div>
      </form>
    </DisenoFino>
  )
}