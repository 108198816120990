import { Tooltip } from '@mui/material';
import { formatCurrency, getInitials } from './utils';

// Custom Tooltip component
const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
            <div className="bg-kompamx-800 text-white p-2 border-2 rounded-md shadow-lg border-kompamx-300">
                <p className="label">{`${data.name}: ${(data.value / data.total * 100).toFixed(2)}%`}</p>
            </div>
        );
    }
    return null;
};

// Custom Legend component
const CustomLegend = ({ payload, chartWidth }) => {
    return (
        <ul className="recharts-default-legend px-2 py-2">
            {payload.map((entry, index) => (
                <li key={`item-${index}`} className="flex flex-row text-sm justify-start mb-2">
                    <svg height="10" width="10" className="mr-2 mt-2">
                        <rect width="10" height="10" style={{ fill: entry.color, strokeWidth: 1, stroke: "black" }} />
                    </svg>
                    <div className="flex flex-col">
                        <Tooltip title={chartWidth < 500 ? entry.payload.name : ''}>
                            <span className="text-black">
                                {entry.payload.logo && (
                                    <img
                                        src={entry.payload.logo}
                                        alt={entry.payload.name}
                                        className="inline-block w-4 h-4 mr-1"
                                    />
                                )}
                                {entry.payload.mxLogo && (
                                    <img
                                        src={entry.payload.mxLogo}
                                        alt={entry.payload.name}
                                        className="inline-block w-4 h-4 mr-1"
                                    />
                                )}
                                {" "}{chartWidth < 500 ? getInitials(entry.payload.name) : entry.payload.name}
                            </span>
                        </Tooltip>
                        <span className="text-left">
                            {formatCurrency(entry.payload.value) || "$0.00"}
                            <span className="text-gray-400">
                                {" "}
                                {`(${(entry.payload.percent * 100).toFixed(2)}%)`}
                            </span>
                        </span>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export { CustomTooltip, CustomLegend };