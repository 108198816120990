import React, { useEffect, useState } from 'react';
import { db, auth, functions } from '../helpers/firebase';
import { httpsCallable } from 'firebase/functions';
import { setDoc, doc, getDoc, getDocs, collection, deleteDoc, serverTimestamp } from "firebase/firestore";
import { Edit, Refresh, Check, Clear, LinkOff, Task, Palette, ContentCopy } from '@mui/icons-material';
import MercadoLibreLogo from '../assets/mercado-libre-logo.svg';
import AmazonLogo from '../assets/amazon-logo-white.png';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2';
import CountrySelector from '../components/CountrySelector';
import Footer from '../components/Footer';
import { ChromePicker } from 'react-color';
import '../pages/(auth)/old/amazonConnect.css';
import useAuth from '../hooks/useAuth';

const colores = [
    "#2987f9",
    "#08c3f5 ",
    "#8800c8",
    "#029dfc ",
    "#47107d",
    "#84007f"
]

export default function AgregarTienda() {
    // Estado para almacenar múltiples tiendas
    const [tiendasMercadoLibre, setTiendasMercadoLibre] = useState([]);
    const [tiendasAmazon, setTiendasAmazon] = useState([]);
    const [selectedAmazonCountryCode, setSelectedAmazonCountryCode] = useState('MX');
    const [selectedAmazonAdsCountryCode, setSelectedAmazonAdsCountryCode] = useState('MX');
    const [refreshingTokens, setRefreshingTokens] = useState({});

    // Constantes para Mercado Libre
    //  const CLIENT_ID_ML = "3516733548819218";
    const CLIENT_ID_ML = "867338387206048";
    const REDIRECT_URI_ML = encodeURIComponent("https://kompa.mx/ml-connect"); // URL codificada
    const ML_AUTH_URL = `https://auth.mercadolibre.com.mx/authorization?response_type=code&client_id=${CLIENT_ID_ML}&redirect_uri=${REDIRECT_URI_ML}`;

    // Constantes para Amazon
    const APP_ID_AMAZON = "amzn1.sp.solution.2bdb3059-2ec6-4fad-94f9-a6401f842e69";
    const APP_ID_AMAZON_ADS = "amzn1.application-oa2-client.98765e3cf55f41439a2fbb715a8242a9";
    const AMAZON_AUTH_URL = `https://sellercentral.amazon.com.mx/apps/authorize/consent?application_id=${APP_ID_AMAZON}&state=${selectedAmazonCountryCode || 'MX'}&version=beta`;
    const AMAZON_ADS_AUTH_URL = `https://www.amazon.com/ap/oa?client_id=${APP_ID_AMAZON_ADS}&scope=advertising::campaign_management&response_type=code&redirect_uri=https://kompa.mx/amazon-connect&state=${selectedAmazonAdsCountryCode || 'MX'}`;

    const [successMessages, setSuccessMessages] = useState({});

    useEffect(() => {
        document.title = 'Agregar Tiendas | Kompa';
    }, []);

    // Función para mostrar y ocultar el mensaje de éxito
    const showSuccessMessage = (storeId, message) => {
        setSuccessMessages(prevState => ({ ...prevState, [storeId]: message }));
        setTimeout(() => {
            setSuccessMessages(prevState => ({ ...prevState, [storeId]: '' }));
        }, 3000); // Mensaje desaparece después de 3 segundos
    };

    // Función para activar/desactivar el spinner de una tienda específica
    const toggleSpinner = (storeId, isRefreshing) => {
        setRefreshingTokens(prevState => ({ ...prevState, [storeId]: isRefreshing }));
    };

    const [loadingStores, setLoadingStores] = useState(false);

    const { currentUser } = useAuth();

    useEffect(() => {
        const cargarTiendas = async () => {
            if (!currentUser || !currentUser.uid) {
                // No hay usuario autenticado, no se puede cargar las tiendas
                return;
            }

            setLoadingStores(true);

            try {
                const userRef = doc(db, 'users', currentUser.uid);
                const storesRef = collection(userRef, 'stores');
                const snapshot = await getDocs(storesRef);

                const tiendasML = [];
                const tiendasAmz = [];

                for (const doc of snapshot.docs) {
                    const tienda = { doc_id: doc.id, ...doc.data() };

                    // Determinar el tipo de tienda basado en el prefijo del ID
                    if (tienda.doc_id.startsWith('ml_')) {
                        tiendasML.push(tienda);
                    } else if (tienda.doc_id.startsWith('az_')) {
                        // tiendasAmz.push(tienda);
                        // see if there is a doc in users/{user_id}/ads_accounts/{ads_account_id}/ads_profiles/{ads_profile_id}/
                        // that has accountInfo field in its property id is the same as tienda.id
                        // if there is set paraCuentaDeAnuncios and paraPerfilDeAnuncios
                        const adsAccountsRef = collection(userRef, 'ads_accounts');
                        const adsAccountsSnapshot = await getDocs(adsAccountsRef);
                        let paraCuentaDeAnuncios = null;
                        let paraPerfilDeAnuncios = null;
                        for (const adsAccountDoc of adsAccountsSnapshot.docs) {
                            const adsProfilesRef = collection(adsAccountDoc.ref, 'ads_profiles');
                            const adsProfilesSnapshot = await getDocs(adsProfilesRef);
                            adsProfilesSnapshot.forEach(adsProfileDoc => {
                                const adsProfileData = adsProfileDoc.data();
                                if (adsProfileData.accountInfo && adsProfileData.accountInfo.id === tienda.id) {
                                    paraCuentaDeAnuncios = adsAccountDoc.id;
                                    paraPerfilDeAnuncios = adsProfileDoc.id;
                                }
                            });
                        }
                        tiendasAmz.push({
                            ...tienda,
                            paraCuentaDeAnuncios,
                            paraPerfilDeAnuncios
                        });
                    }
                }

                setTiendasMercadoLibre(tiendasML);
                setTiendasAmazon(tiendasAmz);
            } catch (error) {
                console.error('Error al cargar tiendas:', error);
            } finally {
                setLoadingStores(false);
            }
        };

        cargarTiendas();
    }, [currentUser]); // Dependencia en el usuario actual

    const [storesBeingDeleted, setStoresBeingDeleted] = useState({});

    // Función para desconectar una tienda de Mercado Libre
    const desconectarTiendaMercadoLibre = async (idTienda) => {

        // Preguntar al usuario si está seguro de desconectar la tienda
        const result = await Swal.fire({
            title: '¿Estás seguro de desconectar la tienda?',
            text: "No podrás revertir esta acción.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#aaa',
            confirmButtonText: 'Sí, desconectar',
            cancelButtonText: 'Cancelar'
        });

        if (!result.isConfirmed) return;

        if (!currentUser || !currentUser.uid) return;
        if (!idTienda) return;

        setStoresBeingDeleted(prevState => ({ ...prevState, [idTienda]: true }));

        const userRef = doc(db, 'users', currentUser.uid);
        const storeRef = doc(userRef, 'stores', `ml_${idTienda}`);

        deleteDoc(storeRef).then(() => {
            const tiendasML = tiendasMercadoLibre.filter(tienda => tienda.id !== idTienda);
            setTiendasMercadoLibre(tiendasML);
        }).catch((error) => {
            console.error("Error al desconectar tienda:", error);
        }).finally(() => {
            setStoresBeingDeleted(prevState => ({ ...prevState, [idTienda]: false }));
        });
    };

    // Función para refrescar el token de Mercado Libre
    const handleRefreshTokenML = async (idTienda) => {
        toggleSpinner(idTienda, true); // Activa el spinner
        if (!currentUser || !currentUser.uid) return;
        if (!idTienda) return;

        try {
            const userRef = doc(db, 'users', currentUser.uid);
            const storeRef = collection(userRef, 'stores');
            const storeDocRef = doc(storeRef, `ml_${idTienda}`); // ml_ es el prefijo para tiendas de Mercado Libre
            const storeDocSnap = await getDoc(storeDocRef);
            if (!storeDocSnap.exists()) {
                console.error('No existe la tienda en la base de datos.');
                return;
            }

            const storeData = storeDocSnap.data();
            if (!storeData) {
                console.error('No hay datos de la tienda en la base de datos.');
                return;
            }

            const refreshToken = httpsCallable(functions, 'refreshMercadoLibreToken_v2');
            const result = await refreshToken({ refresh_token: storeData.token_data.refresh_token });

            if (result.data) {
                await setDoc(storeDocRef, { "token_data": result.data }, { merge: true });
                showSuccessMessage(idTienda, "Listo");
                setTiendasMercadoLibre(prevState => {
                    const tiendasML = prevState.map(tienda => {
                        if (tienda.id === idTienda) {
                            tienda.token_data = result.data;
                        }
                        return tienda;
                    });
                    return tiendasML;
                });

            }
        } catch (error) {
            console.error('Error al refrescar el token de Mercado Libre:', error);
        }
        toggleSpinner(idTienda, false); // Desactiva el spinner
    }

    // Función para desconectar una tienda de Amazon
    const desconectarTiendaAmazon = async (idTienda) => {

        // Preguntar al usuario si está seguro de desconectar la tienda
        const result = await Swal.fire({
            title: '¿Estás seguro de desconectar la tienda?',
            text: "No podrás revertir esta acción.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#aaa',
            confirmButtonText: 'Sí, desconectar',
            cancelButtonText: 'Cancelar'
        });

        if (!result.isConfirmed) return;

        if (!currentUser || !currentUser.uid) return;
        if (!idTienda) return;

        setStoresBeingDeleted(prevState => ({ ...prevState, [idTienda]: true }));

        const userRef = doc(db, 'users', currentUser.uid);
        const storeRef = doc(userRef, 'stores', `az_${idTienda}`);

        deleteDoc(storeRef).then(() => {
            const tiendasAmz = tiendasAmazon.filter(tienda => tienda.id !== idTienda);
            setTiendasAmazon(tiendasAmz);
        }).catch((error) => {
            console.error("Error al desconectar tienda:", error);
        }).finally(() => {
            setStoresBeingDeleted(prevState => ({ ...prevState, [idTienda]: false }));
        });
    };

    // Función para refrescar el token de Amazon
    const handleRefreshTokenAmazon = async (idTienda) => {
        toggleSpinner(idTienda, true); // Activa el spinner

        if (!currentUser || !currentUser.uid) return;
        if (!idTienda) return;

        try {
            const userRef = doc(db, 'users', currentUser.uid);
            const storeRef = collection(userRef, 'stores');
            const storeDocRef = doc(storeRef, `az_${idTienda}`); // az_ es el prefijo para tiendas de Amazon
            const storeDocSnap = await getDoc(storeDocRef);
            if (!storeDocSnap.exists()) {
                console.error('No existe la tienda en la base de datos.');
                return;
            }

            const storeData = storeDocSnap.data();
            if (!storeData) {
                console.error('No hay datos de la tienda en la base de datos.');
                return;
            }

            const refreshToken = httpsCallable(functions, 'refreshAmazonToken');
            const result = await refreshToken({
                refresh_token: storeData.token_data.refresh_token,
                sellingPartnerId: idTienda
            });

            if (result.data) {
                await setDoc(storeDocRef, { "token_data": result.data }, { merge: true });

                setTiendasAmazon(prevState => {
                    const tiendasAmz = prevState.map(tienda => {
                        if (tienda.id === idTienda) {
                            tienda.token_data = result.data;
                        }
                        return tienda;
                    });
                    return tiendasAmz;
                });

                showSuccessMessage(idTienda, "Listo");
            }
        } catch (error) {
            console.error('Error al refrescar el token de Amazon:', error);
        }
        toggleSpinner(idTienda, false); // Desactiva el spinner
    }

    // Estado para editar el nombre de la tienda
    const [editStoreId, setEditStoreId] = useState(null);
    const [editedName, setEditedName] = useState("");

    // Función para editar el nombre de la tienda
    const handleEditClick = (prefix, storeId) => () => {
        setEditStoreId(storeId);
        let editedName = "";
        if (prefix === 'ml') {
            const tienda = tiendasMercadoLibre.find(tienda => tienda.id === storeId);
            editedName = tienda.custom_name || "";
        } else if (prefix === 'az') {
            const tienda = tiendasAmazon.find(tienda => tienda.id === storeId);
            editedName = tienda.custom_name || "";
        }
        setEditedName(editedName);
    };

    const handleKeyDown = (prefix, idTienda, event, newName) => {
        if (event.key === 'Enter') {
            updateStoreName(prefix, idTienda, newName);
        }
    };

    const handleSaveClick = (prefix, idTienda, newName) => {
        updateStoreName(prefix, idTienda, newName);
    };

    const [updatingStoreName, setUpdatingStoreName] = useState({});

    const updateStoreName = async (prefix, idTienda, newName) => {

        if (!currentUser || !currentUser.uid) return;
        if (!idTienda) return;

        setUpdatingStoreName(prevState => ({ ...prevState, [`${prefix}_${idTienda}`]: true }));
        const userRef = doc(db, 'users', currentUser.uid);
        const storeRef = doc(userRef, 'stores', `${prefix}_${idTienda}`);
        await setDoc(storeRef, { "custom_name": newName.toString() }, { merge: true });
        if (prefix === 'ml') {
            const tiendasML = tiendasMercadoLibre.map(tienda => {
                if (tienda.id === idTienda) {
                    tienda.custom_name = newName;
                }
                return tienda;
            });
            setTiendasMercadoLibre(tiendasML);
        } else if (prefix === 'az') {
            const tiendasAmz = tiendasAmazon.map(tienda => {
                if (tienda.id === idTienda) {
                    tienda.custom_name = newName;
                }
                return tienda;
            }
            );
            setTiendasAmazon(tiendasAmz);
        }

        setUpdatingStoreName(prevState => ({ ...prevState, [`${prefix}_${idTienda}`]: false }));
        setEditStoreId(null);
    };

    const generatePendingTaskDoc = async (data) => {
        const pendingTaskRef = doc(collection(db, 'pending_tasks'));
        await setDoc(pendingTaskRef, {
            ...data,
            status: 'pending',
            fecha: serverTimestamp()
        });
    };

    const generateRandomColorForStore = (storeId) => {
        const randomIndex = Math.floor(Math.random() * colores.length);

        if (!currentUser || !currentUser.uid || !storeId) return "black";

        const userRef = doc(db, 'users', currentUser.uid, 'stores', storeId);

        setDoc(userRef, { color: colores[randomIndex] }, { merge: true });

        if (storeId.startsWith('ml_')) {
            const tiendasML = tiendasMercadoLibre.map(tienda => {
                if (`ml_${tienda.id}` === storeId) {
                    tienda.color = colores[randomIndex];
                }
                return tienda;
            });
            setTiendasMercadoLibre(tiendasML);
        }

        if (storeId.startsWith('az_')) {
            const tiendasAmz = tiendasAmazon.map(tienda => {
                if (`az_${tienda.id}` === storeId) {
                    tienda.color = colores[randomIndex];
                }
                return tienda;
            });
            setTiendasAmazon(tiendasAmz);
        }

        return colores[randomIndex];
    };

    const [hoveredColors, setHoveredColors] = useState({});

    // Cuando necesitas obtener el color de hover:
    const getColorHover = (color) => {
        return calculateDarkerTone(color);
    };

    const [colorPickersVisible, setColorPickersVisible] = useState({});

    const [lastColorChangeForStore, setLastColorChangeForStore] = useState({});

    const handleColorChange = async (color, tiendaId) => {
        if (!auth || !currentUser || !currentUser.uid || !tiendaId) return;

        if (tiendaId.startsWith('ml_')) {
            const tiendasML = tiendasMercadoLibre.map(tienda => {
                if (`ml_${tienda.id}` === tiendaId) {
                    tienda.color = color.hex;
                }
                return tienda;
            });
            setTiendasMercadoLibre(tiendasML);
        }

        if (tiendaId.startsWith('az_')) {
            const tiendasAmz = tiendasAmazon.map(tienda => {
                if (`az_${tienda.id}` === tiendaId) {
                    tienda.color = color.hex;
                }
                return tienda;
            });
            setTiendasAmazon(tiendasAmz);
        }

        setLastColorChangeForStore({ [tiendaId]: color.hex });
    };

    const saveColorChange = async (color, tiendaId) => {
        if (!auth || !currentUser || !currentUser.uid || !tiendaId) return;

        const userRef = doc(db, 'users', currentUser.uid, 'stores', tiendaId);
        await setDoc(userRef, { color: color }, { merge: true });

    };

    const [lastScrollTop, setLastScrollTop] = useState(0);

    const handleScroll = (e) => {
        const scrollTop = e.target.scrollTop;  // Obtiene la posición actual del scroll
        if (scrollTop < lastScrollTop) {
            // scrollear hasta arriba el big-container, suavemente
            const bigContainer = document.getElementById('big-container');
            bigContainer.scrollTop = 0;

        }
        setLastScrollTop(scrollTop);  // Actualiza la última posición del scroll
    };

    useEffect(() => {
        const contentToPdf = document.getElementById('content-to-pdf');
        contentToPdf.addEventListener('scroll', handleScroll);

        return () => {
            contentToPdf.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);  // Dependencias en las que el efecto depende

    const calculateDarkerTone = (color) => {
        const hex = color.replace('#', '');
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        const factor = 0.7;
        const newR = Math.floor(r * factor);
        const newG = Math.floor(g * factor);
        const newB = Math.floor(b * factor);

        // Usamos padStart para asegurar que cada valor tenga al menos dos dígitos
        const newRHex = newR.toString(16).padStart(2, '0');
        const newGHex = newG.toString(16).padStart(2, '0');
        const newBHex = newB.toString(16).padStart(2, '0');

        return `#${newRHex}${newGHex}${newBHex}`;
    };

    // Renderizado de tiendas conectadas y botones para conectar nuevas tiendas
    return (
        <div
            id="big-container"
            style={{
                height: (tiendasAmazon.length > 0 || tiendasMercadoLibre.length > 0) ? 'calc(100vh - 4rem)' : '100vh',
                overflowY: 'auto'
            }}
            className="relative"
            onClick={() => {
                // Cierra todos los pickers abiertos cuando se hace clic fuera de ellos
                setColorPickersVisible({});
                if (Object.keys(lastColorChangeForStore).length > 0) {
                    saveColorChange(lastColorChangeForStore[Object.keys(lastColorChangeForStore)[0]], Object.keys(lastColorChangeForStore)[0]);
                    setLastColorChangeForStore({});
                }
            }}
        >
            <div
                id="content-to-pdf"
                className={`flex flex-col justify-start items-center bg-gray-100 py-5 px-5`}
                style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}
            >
                <h1 className='text-4xl font-bold text-gray-800'>
                    {tiendasAmazon.length > 0 || tiendasMercadoLibre.length > 0 ?
                        "Agregar Tiendas" : "Conecta tu primera tienda"
                    }
                </h1>

                <p className='text-xl text-gray-600 text-center'>
                    {tiendasAmazon.length > 0 || tiendasMercadoLibre.length > 0 ?
                        "Conecta tiendas para comenzar a analizar sus ventas." :
                        "Conecta tu primera tienda para comenzar a analizar tus ventas."
                    }
                </p>

                {/* Separador */}
                <hr className="my-8 w-full border-gray-300" />

                <div className="flex flex-col xl:flex-row items-center xl:items-start justify-center">
                    <div className="flex flex-col items-center justify-start">
                        {/* Listado de tiendas de Mercado Libre */}
                        {loadingStores ?
                            <div className="spinner-yellow my-6" /> :
                            tiendasMercadoLibre.map((tienda, index) => (
                                <div key={index} className={`mb-4 flex flex-col md:flex-col items-center justify-center border border-gray-300 p-4 bg-white shadow-lg rounded-lg`}>
                                    <div className="flex flex-row items-center justify-center w-full">
                                        <div
                                            className="flex justify-center items-center text-gray-500 hover:text-gray-700 font-semibold transition duration-100 ease-in-out ml-2 w-full"
                                        >
                                            {
                                                updatingStoreName[`ml_${tienda.id}`] ? (
                                                    <p className="text-gray-400 font-normal">
                                                        Actualizando nombre...
                                                    </p>
                                                ) : editStoreId === tienda.id ? (
                                                    <>
                                                        <input
                                                            className="border-0 focus:outline-none bg-transparent font-medium text-gray-500 w-full"
                                                            type="text"
                                                            value={editStoreId === tienda.id ? editedName : tienda.id.toString()}
                                                            onChange={(event) => setEditedName(event.target.value)}
                                                            onKeyDown={(event) => handleKeyDown('ml', tienda.id, event, editedName)}
                                                            autoFocus
                                                        />
                                                        <Tooltip arrow title="Guardar" className="cursor-pointer">
                                                            <Check
                                                                style={{ fontSize: 20, marginRight: 8, color: 'green' }}
                                                                onClick={() => handleSaveClick('ml', tienda.id, editedName)}
                                                            />
                                                        </Tooltip>
                                                        <Tooltip arrow title="Cancelar" className="cursor-pointer">
                                                            <Clear
                                                                style={{ fontSize: 20, marginRight: 8, color: 'red' }}
                                                                onClick={() => setEditStoreId(null)}
                                                            />
                                                        </Tooltip>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Tooltip arrow title="Editar nombre" className="cursor-pointer">
                                                            <Edit
                                                                style={{ fontSize: 20, marginRight: 8 }}
                                                                onClick={handleEditClick('ml', tienda.id)}
                                                            />
                                                        </Tooltip>
                                                        <p
                                                            className="truncate"
                                                            onClick={handleEditClick('ml', tienda.id)}
                                                        >
                                                            {tienda['custom_name'] || <span className="text-gray-400">Escribe un nombre para la tienda...</span>}
                                                        </p>
                                                        <span
                                                            className="relative h-4 w-4 rounded-full inline-block ml-2 shadow-md cursor-pointer hover:shadow-lg border border-gray-300"
                                                            style={{ backgroundColor: tienda.color || generateRandomColorForStore(`ml_${tienda.id}`) }}
                                                            onClick={(e) => {
                                                                // Esto evita que el clic se propague al contenedor principal y cierre el picker
                                                                e.stopPropagation();
                                                                setColorPickersVisible({ [`ml_${tienda.id}`]: !colorPickersVisible[`ml_${tienda.id}`] });
                                                            }}
                                                        >
                                                            {colorPickersVisible[`ml_${tienda.id}`] && (
                                                                <div
                                                                    className="absolute z-10 top-0 left-1/2 transform -translate-x-1/2 mt-5"
                                                                    onClick={(e) => e.stopPropagation()} // Evita que los clics dentro del picker se propaguen
                                                                >
                                                                    <ChromePicker
                                                                        color={tienda.color}
                                                                        onChangeComplete={(color) => handleColorChange(color, `ml_${tienda.id}`)}
                                                                        disableAlpha={true}
                                                                    />
                                                                </div>
                                                            )}
                                                        </span>
                                                    </>
                                                )}
                                        </div>
                                    </div>

                                    {tienda.data_loading && <p className="text-gray-400 text-sm">
                                        (<span className="loadingNoStyle">
                                            Cargando datos...
                                        </span>)
                                    </p>}

                                    <hr className="my-4 w-full border-gray-300" />

                                    <div className="flex flex-col items-center justify-center">
                                        <div className="flex flex-row items-center">
                                            {successMessages[tienda.id] ? (
                                                <div style={{ color: 'green', display: 'flex', alignItems: 'center' }}>
                                                    <Check style={{ color: 'green', marginRight: 8 }} />
                                                    {successMessages[tienda.id]}
                                                </div>) : refreshingTokens[tienda.id] ?
                                                <div className="spinner-yellow my-6" /> :
                                                refreshingTokens[tienda.id] ?
                                                    <div className="spinner-yellow h-20" /> :
                                                    <button onClick={() => handleRefreshTokenML(tienda.id)}
                                                        className=" text-blue-500 hover:text-blue-700 font-semibold py-2 px-4 rounded ml-2 transition duration-100 ease-in-out">
                                                        <Refresh style={{ fontSize: 20, marginRight: 8 }} />
                                                        Refrescar Código
                                                    </button>
                                            }

                                            {!storesBeingDeleted[tienda.id] ?
                                                <button onClick={() => desconectarTiendaMercadoLibre(tienda.id)}
                                                    className="flex items-center justify-center text-red-500 hover:text-red-700 font-semibold py-2 px-4 rounded mr-2 transition duration-100 ease-in-out">
                                                    <LinkOff style={{ fontSize: 20, marginRight: 8 }} />
                                                    Desconectar
                                                </button> :
                                                <div className="spinner-red my-6" />
                                            }
                                        </div>
                                        <div className="flex flex-row items-center justify-center">
                                            <button
                                                onClick={async () => {
                                                    // set tienda.data_loading = true
                                                    const tiendasML = tiendasMercadoLibre.map(tienda2 => {
                                                        if (tienda.id === tienda2.id) {
                                                            tienda2.data_loading = true;
                                                        }
                                                        return tienda2;
                                                    });

                                                    setTiendasMercadoLibre(tiendasML);

                                                    await generatePendingTaskDoc({
                                                        type: '[ML] 1/2 traer productos de la tienda',
                                                        paraTienda: `ml_${tienda.id}`,
                                                        paraUsuario: currentUser.uid
                                                    });
                                                    await generatePendingTaskDoc({
                                                        type: '[ML] 1/1 traer órdenes de la tienda',
                                                        paraTienda: `ml_${tienda.id}`,
                                                        paraUsuario: currentUser.uid
                                                    });
                                                    await generatePendingTaskDoc({
                                                        type: '[ML] 1/1 traer visitas de la tienda',
                                                        paraTienda: `ml_${tienda.id}`,
                                                        paraUsuario: currentUser.uid
                                                    });

                                                    await generatePendingTaskDoc({
                                                        type: '[ML] 1/1 traer anuncios de la tienda por día',
                                                        paraTienda: `ml_${tienda.id}`,
                                                        paraUsuario: currentUser.uid
                                                    });
                                                    Swal.fire({
                                                        title: 'Tareas creadas',
                                                        text: 'Las tareas se han creado correctamente, recibirás un correo cuando estén listas.',
                                                        icon: 'success',
                                                        confirmButtonText: 'Cerrar ventana'
                                                    });
                                                }}
                                                className="flex items-center justify-center text-green-500 hover:text-green-700 font-semibold py-2 px-4 rounded mr-2 transition duration-100 ease-in-out">
                                                <Task style={{ fontSize: 20, marginRight: 8 }} />
                                                Refrescar Datos
                                            </button>

                                            {tienda.color &&
                                                <button
                                                    onClick={() => generateRandomColorForStore(`ml_${tienda.id}`)}
                                                    className="flex items-center justify-center font-semibold py-2 px-4 rounded mr-2 transition duration-100 ease-in-out"
                                                    onMouseEnter={() => {
                                                        setHoveredColors(prevState => ({ ...prevState, [`ml_${tienda.id}`]: true }));
                                                    }}
                                                    onMouseLeave={() => setHoveredColors(prevState => ({ ...prevState, [`ml_${tienda.id}`]: false }))}
                                                    style={{
                                                        color: hoveredColors[`ml_${tienda.id}`] ? getColorHover(tienda.color) : tienda.color,
                                                    }}
                                                >
                                                    <Palette style={{ fontSize: 20, marginRight: 8 }} />
                                                    Color Aleatorio
                                                </button>
                                            }
                                        </div>
                                    </div>

                                    <h2 className="text-2xl font-bold">{tienda.nombre}</h2>
                                </div>
                            ))
                        }
                        {/* Botón para agregar nueva tienda de Mercado Libre */}
                        <button onClick={() => window.open(ML_AUTH_URL, "_blank")} // </div>window.location.href = ML_AUTH_URL}
                            className='bg-yellow-400 hover:bg-yellow-600 text-black font-semibold py-3 px-6 rounded-full shadow-lg flex items-center transition duration-100 ease-in-out'
                        >
                            <img src={MercadoLibreLogo} alt="Mercado Libre" className="h-9 mr-2" />
                            Conectar nueva tienda Mercado Libre
                            <ContentCopy
                                style={{ fontSize: 20, marginLeft: 12 }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigator.clipboard.writeText(ML_AUTH_URL);
                                    Swal.fire({
                                        title: 'Enlace copiado',
                                        text: 'El enlace se ha copiado al portapapeles.',
                                        icon: 'success',
                                        confirmButtonText: 'Cerrar ventana'
                                    });
                                }}
                            />
                        </button>
                    </div>

                    {/* Separador */}
                    <hr className="w-full border-gray-300 my-8 xl:hidden block" />
                    <div className="h-full py-8">
                        <hr className="h-[100%] border-t-0 border-r border-gray-300 ml-11 mr-7 w-1 hidden xl:block" />
                    </div>

                    <div className="flex flex-col items-center justify-start h-full">
                        {/* Listado de tiendas de Amazon */}
                        {loadingStores ?
                            <div className="spinner my-6" /> :
                            tiendasAmazon.map((tienda, index) => (tienda.token_data && tienda.token_data.access_token &&
                                <div key={index} className="mb-4 flex flex-col md:flex-col items-center justify-center border border-gray-300 rounded-lg p-4 bg-white shadow-lg">
                                    <div>
                                        <div
                                            className="flex items-center text-gray-500 hover:text-gray-700 font-semibold transition duration-100 ease-in-out ml-2"
                                        >
                                            {updatingStoreName[`az_${tienda.id}`] ? (
                                                <p className="text-gray-400 font-normal">
                                                    Actualizando nombre...
                                                </p>
                                            ) : editStoreId === tienda.id ? (
                                                <>
                                                    <input
                                                        className="border-0 focus:outline-none bg-transparent font-medium text-gray-500"
                                                        type="text"
                                                        value={editStoreId === tienda.id ? editedName : tienda.id.toString()}
                                                        onChange={(event) => setEditedName(event.target.value)}
                                                        onKeyDown={(event) => handleKeyDown('az', tienda.id, event, editedName)}
                                                        autoFocus
                                                    />
                                                    <Tooltip arrow title="Guardar" className="cursor-pointer">
                                                        <Check
                                                            style={{ fontSize: 20, marginRight: 8, color: 'green' }}
                                                            onClick={() => handleSaveClick('az', tienda.id, editedName)}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip arrow title="Cancelar" className="cursor-pointer">
                                                        <Clear
                                                            style={{ fontSize: 20, marginRight: 8, color: 'red' }}
                                                            onClick={() => setEditStoreId(null)}
                                                        />
                                                    </Tooltip>
                                                </>
                                            ) : (
                                                <>
                                                    <Tooltip arrow title="Editar nombre" className="cursor-pointer">
                                                        <Edit
                                                            style={{ fontSize: 20, marginRight: 8 }}
                                                            onClick={handleEditClick('az', tienda.id)}
                                                        />
                                                    </Tooltip>
                                                    <p
                                                        className="truncate"
                                                        onClick={handleEditClick('az', tienda.id)}
                                                    >
                                                        {tienda['custom_name'] || <span className="text-gray-400">Escribe un nombre para la tienda...</span>}
                                                    </p>
                                                    <span
                                                        className="relative h-4 w-4 rounded-full inline-block ml-2 shadow-md cursor-pointer hover:shadow-lg border border-gray-300"
                                                        style={{ backgroundColor: tienda.color || generateRandomColorForStore(`az_${tienda.id}`) }}
                                                        onClick={(e) => {
                                                            // Esto evita que el clic se propague al contenedor principal y cierre el picker
                                                            e.stopPropagation();
                                                            setColorPickersVisible({ [`az_${tienda.id}`]: !colorPickersVisible[`az_${tienda.id}`] });
                                                        }}
                                                    >
                                                        {colorPickersVisible[`az_${tienda.id}`] && (
                                                            <div
                                                                className="absolute z-10 top-0 left-1/2 transform -translate-x-1/2 mt-5"
                                                                onClick={(e) => e.stopPropagation()} // Evita que los clics dentro del picker se propaguen
                                                            >
                                                                <ChromePicker
                                                                    color={tienda.color}
                                                                    onChangeComplete={(color) => handleColorChange(color, `az_${tienda.id}`)}
                                                                    disableAlpha={true}
                                                                />
                                                            </div>
                                                        )}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    {tienda.data_loading && <p className="text-gray-400 text-sm">
                                        (
                                        <span className="loadingNoStyle">
                                            Cargando datos...
                                        </span>
                                        )
                                    </p>
                                    }

                                    <hr className="my-4 w-full border-gray-300" />

                                    <div className="flex flex-col items-center justify-center">
                                        <div className="flex flex-row items-center">
                                            {successMessages[tienda.id] ? (
                                                <div style={{ color: 'green', display: 'flex', alignItems: 'center' }}>
                                                    <Check style={{ color: 'green', marginRight: 8 }} />
                                                    {successMessages[tienda.id]}
                                                </div>) : refreshingTokens[tienda.id] ?
                                                <div className="spinner my-6" /> :
                                                <div className="flex flex-col items-center">
                                                    <button onClick={() => handleRefreshTokenAmazon(tienda.id)}
                                                        className="text-blue-500 hover:text-blue-700  font-semibold py-2 px-4 rounded ml-2 transition duration-100 ease-in-out">
                                                        <Refresh style={{ fontSize: 20, marginRight: 8 }} />
                                                        Refrescar Código
                                                    </button>
                                                </div>
                                            }
                                            {!storesBeingDeleted[tienda.id] ?
                                                <button onClick={() => desconectarTiendaAmazon(tienda.id)}
                                                    className="flex items-center justify-center text-red-500 hover:text-red-700 font-semibold py-2 px-4 rounded mr-2 transition duration-100 ease-in-out">
                                                    <LinkOff style={{ fontSize: 20, marginRight: 8 }} />
                                                    Desconectar
                                                </button> :
                                                <div className="spinner-red my-6" />
                                            }

                                        </div>
                                        <div className="flex flex-row items-center justify-center">
                                            <button
                                                onClick={async () => {
                                                    // set tienda data loading to true
                                                    const tiendasAmz = tiendasAmazon.map(tienda2 => {
                                                        if (tienda.id === tienda2.id) {
                                                            tienda2.data_loading = true;
                                                        }
                                                        return tienda2;
                                                    });

                                                    setTiendasAmazon(tiendasAmz);

                                                    // await generatePendingTaskDoc({
                                                    //     type: '[AZ] 1/3 generar reporte de productos',
                                                    //     paraTienda: `az_${tienda.id}`,
                                                    //     paraUsuario: currentUser.uid
                                                    // });

                                                    // await generatePendingTaskDoc({
                                                    //     type: '[AZ] 1/3 generar reporte de ventas y tráfico',
                                                    //     paraTienda: `az_${tienda.id}`,
                                                    //     paraUsuario: currentUser.uid
                                                    // });

                                                    // await generatePendingTaskDoc({
                                                    //     type: '[AZ] 1/1 traer órdenes de Amazon',
                                                    //     paraTienda: `az_${tienda.id}`,
                                                    //     paraUsuario: currentUser.uid
                                                    // });

                                                    if (tienda.paraCuentaDeAnuncios && tienda.paraPerfilDeAnuncios) {
                                                        await generatePendingTaskDoc({
                                                            type: '[AZAds] 1/3 generar reporte de productos anunciados',
                                                            paraTienda: `az_${tienda.id}`,
                                                            paraUsuario: currentUser.uid,
                                                            paraCuentaDeAnuncios: tienda.paraCuentaDeAnuncios,
                                                            paraPerfilDeAnuncios: tienda.paraPerfilDeAnuncios
                                                        });
                                                        await generatePendingTaskDoc({
                                                            type: '[AZAds] 1/3 generar reporte de campaña diario',
                                                            paraTienda: `az_${tienda.id}`,
                                                            paraUsuario: currentUser.uid,
                                                            paraCuentaDeAnuncios: tienda.paraCuentaDeAnuncios,
                                                            paraPerfilDeAnuncios: tienda.paraPerfilDeAnuncios
                                                        });
                                                    }

                                                    Swal.fire({
                                                        title: 'Tareas creadas',
                                                        text: 'Las tareas se han creado correctamente, recibirás un correo cuando estén listas.',
                                                        icon: 'success',
                                                        confirmButtonText: 'Cerrar ventana'
                                                    });
                                                }}
                                                className="flex items-center justify-center text-green-500 hover:text-green-700 font-semibold py-2 px-4 rounded mr-2 transition duration-100 ease-in-out">
                                                <Task style={{ fontSize: 20, marginRight: 8 }} />
                                                Refrescar Datos
                                            </button>
                                            {tienda.color &&
                                                <button
                                                    onClick={() => generateRandomColorForStore(`az_${tienda.id}`)}
                                                    className="flex items-center justify-center font-semibold py-2 px-4 rounded mr-2 transition duration-100 ease-in-out"
                                                    onMouseEnter={() => {
                                                        setHoveredColors(prevState => ({ ...prevState, [`az_${tienda.id}`]: true }));

                                                    }}
                                                    onMouseLeave={() => setHoveredColors(prevState => ({ ...prevState, [`az_${tienda.id}`]: false }))}
                                                    style={{
                                                        color: hoveredColors[`az_${tienda.id}`] ? getColorHover(tienda.color) : tienda.color,
                                                    }}
                                                >
                                                    <Palette style={{ fontSize: 20, marginRight: 8 }} />
                                                    Color Aleatorio
                                                </button>
                                            }
                                        </div>
                                    </div>

                                </div>
                            ))
                        }
                        {/* Botón para agregar nueva tienda de Amazon */}
                        <button onClick={() => window.open(AMAZON_AUTH_URL, "_blank")} // </div>window.location.href = AMAZON_AUTH_URL}
                            className='bg-gray-800 hover:bg-gray-900 text-white font-semibold py-4 px-6 mt-4 rounded-full shadow-lg flex items-center transition duration-100 ease-in-out'
                        >
                            <img src={AmazonLogo} alt="Amazon" className="h-6 mr-4" />
                            Conectar nueva tienda Amazon
                            <CountrySelector
                                setSelectedCountryCode={setSelectedAmazonCountryCode}
                                selectedCountryCode={selectedAmazonCountryCode}
                            />
                            <ContentCopy
                                style={{ fontSize: 20, marginLeft: 12 }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigator.clipboard.writeText(AMAZON_AUTH_URL);
                                    Swal.fire({
                                        title: 'Enlace copiado',
                                        text: 'El enlace se ha copiado al portapapeles.',
                                        icon: 'success',
                                        confirmButtonText: 'Cerrar ventana'
                                    });
                                }}
                            />
                        </button>

                        {/* Botón para agregar nueva tienda de Amazon Ads */}
                        <button onClick={() => window.open(AMAZON_ADS_AUTH_URL, "_blank")} // </div>window.location.href = AMAZON_ADS_AUTH_URL}
                            className='bg-orange-500 hover:bg-orange-600 text-white font-semibold py-4 px-6 rounded-full shadow-lg flex items-center transition duration-100 ease-in-out mt-4'
                        >
                            <img src={AmazonLogo} alt="Amazon" className="h-6 mr-4" />
                            Conectar nueva tienda Amazon Ads
                            <CountrySelector
                                setSelectedCountryCode={setSelectedAmazonAdsCountryCode}
                                selectedCountryCode={selectedAmazonAdsCountryCode}
                            />
                            <ContentCopy
                                style={{ fontSize: 20, marginLeft: 12 }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigator.clipboard.writeText(AMAZON_ADS_AUTH_URL);
                                    Swal.fire({
                                        title: 'Enlace copiado',
                                        text: 'El enlace se ha copiado al portapapeles.',
                                        icon: 'success',
                                        confirmButtonText: 'Cerrar ventana'
                                    });
                                }}
                            />
                        </button>
                    </div>
                </div >
            </div >
            <Footer />
        </div >
    );
}
