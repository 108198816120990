import React, { useState, useEffect } from 'react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import clsx from 'clsx';
import { Contenedor } from './Contenedor';
import { BarChart3, Trophy, PackageSearch, GitCompare, PieChart, TrendingUp } from 'lucide-react';

// Import images
import screenshotAds from '../../assets/demo/graphs/ads.png';
import screenshotMeli from '../../assets/demo/graphs/metricas_meli.png';
import screenshotMostSold from '../../assets/demo/graphs/most_sold.png';
import screenshotComparison from '../../assets/demo/graphs/storecomparison.png';
import screenshotSalesMix from '../../assets/demo/graphs/salesmixchart.png';
import screenshotTraffic from '../../assets/demo/graphs/traffic_and_conversion.png';

const caracteristicas = [
  {
    nombre: 'Análisis de Publicidad',
    resumen: 'Monitorea el rendimiento de tus campañas publicitarias.',
    descripcion: 'Visualiza métricas clave como ACoS, TACoS, ROAS y gastos publicitarios para optimizar tus campañas y maximizar el retorno de inversión.',
    imagen: screenshotAds,
    icono: BarChart3
  },
  {
    nombre: 'Métricas de MercadoLibre',
    resumen: 'Seguimiento detallado de tu reputación como vendedor.',
    descripcion: 'Monitorea tus niveles de reputación, calificaciones positivas y negativas, y el total de ventas completadas en MercadoLibre.',
    imagen: screenshotMeli,
    icono: Trophy
  },
  {
    nombre: 'Productos Más Vendidos',
    resumen: 'Identifica tus productos estrella y los de bajo rendimiento.',
    descripcion: 'Analiza el desempeño de tus productos con datos de ventas, inventario disponible y tendencias por canal de venta.',
    imagen: screenshotMostSold,
    icono: PackageSearch
  },
  {
    nombre: 'Comparación de Tiendas',
    resumen: 'Compara el rendimiento entre diferentes tiendas.',
    descripcion: 'Analiza métricas clave como ventas, pedidos, valor promedio de pedido y unidades vendidas entre múltiples tiendas.',
    imagen: screenshotComparison,
    icono: GitCompare
  },
  {
    nombre: 'Mezcla de Ventas',
    resumen: 'Visualiza la distribución de tus ventas por canal.',
    descripcion: 'Obtén una vista clara de cómo se distribuyen tus ventas entre diferentes plataformas y canales de venta.',
    imagen: screenshotSalesMix,
    icono: PieChart
  },
  {
    nombre: 'Tráfico y Conversión',
    resumen: 'Analiza el rendimiento de tus visitas y conversiones.',
    descripcion: 'Monitorea las tendencias de tráfico y tasas de conversión para optimizar el rendimiento de tus listados.',
    imagen: screenshotTraffic,
    icono: TrendingUp
  },
];

function Caracteristica({ caracteristica, esActiva, className, ...props }) {
  const Icon = caracteristica.icono;
  return (
    <div
      className={clsx(className, !esActiva && 'opacity-75 hover:opacity-100')}
      {...props}
    >
      <div
        className={clsx(
          'w-9 rounded-lg',
          esActiva ? 'bg-blue-600' : 'bg-slate-500',
        )}
      >
        <div className="h-9 w-9 flex items-center justify-center">
          <Icon className="h-5 w-5 text-white" />
        </div>
      </div>
      <h3
        className={clsx(
          'mt-6 text-sm font-medium',
          esActiva ? 'text-blue-600' : 'text-slate-600',
        )}
      >
        {caracteristica.nombre}
      </h3>
      <p className="mt-2 font-display text-xl text-slate-900">
        {caracteristica.resumen}
      </p>
      <p className="mt-4 text-sm text-slate-600">{caracteristica.descripcion}</p>
    </div>
  )
}

function CaracteristicasMovil() {
  return (
    <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {caracteristicas.map((caracteristica) => (
        <div key={caracteristica.resumen}>
          <Caracteristica caracteristica={caracteristica} className="mx-auto max-w-2xl" esActiva />
          <div className="relative mt-10 pb-10">
            <div className="absolute -inset-x-4 bottom-0 top-8 bg-slate-200 sm:-inset-x-6" />
            <div className="relative mx-auto w-full overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
              <img
                className="w-full"
                src={caracteristica.imagen}
                alt=""
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

function CaracteristicasEscritorio() {
  return (
    <TabGroup className="hidden lg:mt-20 lg:block">
      {({ selectedIndex }) => (
        <>
          <TabList className="grid grid-cols-3 gap-x-8 gap-y-10">
            {caracteristicas.map((caracteristica, indiceCaracteristica) => (
              <Caracteristica
                key={caracteristica.resumen}
                caracteristica={{
                  ...caracteristica,
                  nombre: (
                    <Tab className="ui-not-focus-visible:outline-none outline-none">
                      <span className="absolute inset-0" />
                      {caracteristica.nombre}
                    </Tab>
                  ),
                }}
                esActiva={indiceCaracteristica === selectedIndex}
                className="relative"
              />
            ))}
          </TabList>
          <TabPanels className="relative mt-20 overflow-hidden rounded-[2rem] bg-slate-200 px-14 py-16 xl:px-16">
            <div className="-mx-5 flex">
              {caracteristicas.map((caracteristica, indiceCaracteristica) => (
                <TabPanel
                  static
                  key={caracteristica.resumen}
                  className={clsx(
                    'px-5 transition duration-500 ease-in-out ui-not-focus-visible:outline-none',
                    indiceCaracteristica !== selectedIndex && 'opacity-60',
                  )}
                  style={{ transform: `translateX(-${selectedIndex * 100}%)` }}
                  aria-hidden={indiceCaracteristica !== selectedIndex}
                >
                  <div className="w-[32.75rem] p-5 overflow-hidden rounded-xl bg-white shadow-xl shadow-slate-900/5 ring-1 ring-slate-500/10">
                    <img
                      className="w-full"
                      src={caracteristica.imagen}
                      alt=""
                      sizes="52.75rem"
                    />
                  </div>
                </TabPanel>
              ))}
            </div>
          </TabPanels>
        </>
      )}
    </TabGroup>
  )
}

export function CaracteristicasSecundarias() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section
      id="caracteristicas-secundarias"
      aria-label="Herramientas analíticas para optimizar tu negocio"
      className="pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32"
      style={{ maxWidth: '100vw' }}
    >
      <Contenedor>
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="text-slate-900 text-[2.45rem]">
            Analítica avanzada para decisiones informadas.
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            Herramientas potentes para monitorear y optimizar el rendimiento de tus tiendas en línea.
          </p>
        </div>
        <CaracteristicasMovil />
        <CaracteristicasEscritorio />
      </Contenedor>
    </section>
  )
}