/* global Chart */

import React, { useEffect, useRef } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { AutoGraph, DataObject, ExpandMore, TableChart, Troubleshoot, UnfoldLess } from '@mui/icons-material';
import "./Results.css"
import { functions } from '../../helpers/firebase';
import { httpsCallable } from 'firebase/functions';

export default function Results({ rawResults, query, naturalQuery, random, canvasId, lastCanvasId }) {

    const hiddenRef = useRef(null);
    const jsonPretty = useRef(null);

    const handleCopy = (event) => {
        if (hiddenRef.current && event.target.hasAttribute('data-copy')) {
            event.preventDefault();
            const text = hiddenRef.current.getAttribute('data-value');
            event.clipboardData.setData('text/plain', text);
        }
    };

    const formatNumberWithCommas = (num) => {
        // first round to two decimal places and then add commas
        // if no decimals are needed, use num.toFixed(0)
        if (num % 1 === 0) {
            return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const toTitleCase = (str) => {
        const exceptions = ['de', 'del', 'y', 'la', 'el', 'en', 'con', 'a']; // Lista de palabras que no se capitalizan
        return str.replace(/\w\S*/g, (txt, index) => {
            // Si la palabra está en la lista de excepciones y no es la primera palabra
            if (exceptions.includes(txt.toLowerCase()) && index !== 0) {
                return txt.toLowerCase();
            }
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };

    const [showFullQuery, setShowFullQuery] = React.useState(false);

    const handleSeeFullQuery = () => {
        setShowFullQuery(!showFullQuery);
    };

    const data = JSON.parse(rawResults);

    const [graphHasBeenGenerated, setGraphHasBeenGenerated] = React.useState(false);
    const [shouldDisplayGraph, setShouldDisplayGraph] = React.useState(false);

    const generateChart = async () => {
        // Usar solo los primeros tres resultados para simplificar el ejemplo
        const slicedData = JSON.parse(rawResults).slice(0, 3);

        const generateGraph = httpsCallable(functions, 'generate_recharts_graph_code_v1');
        let errors = [];
        let codes_generated = [];
        // if graph has been generated, return
        setGraphHasBeenGenerated(false);
        // for 5 times
        for (let i = 0; i < 5; i++) {
            try {
                const result = await generateGraph({ data: slicedData, errors, codes_generated })

                let generatedCode = result.data.code;
                codes_generated.push(generatedCode);


                // replace from "const pureJson =" to "}"
                // to `const pureJson = ${JSON.stringify(data)};`
                const pureJson = JSON.stringify(data);
                // Ajuste de la expresión regular para capturar saltos de línea
                generatedCode = generatedCode.replace(/const pureJson =[\s\S]+?;/, `const pureJson = ${pureJson};`);

                // replace all myChart with canvasId
                generatedCode = generatedCode.replace(/myChart/g, canvasId);


                // Ejecutar el código generado para crear el gráfico
                // eval(generatedCode);
                // if not window[canvasId] then create it

                eval(generatedCode);
                setShouldDisplayGraph(true);
                setGraphHasBeenGenerated(true);
                // break out of the loop if no error
                break;
            } catch (error) {
                // Manejar el error aquí
                errors.push(`Error generating graph: ${error}`);
            }
        }
    }

    const canvasRef = useRef(null);

    // . to .. to ...
    const [dots, setDots] = React.useState('.');
    const [dotsCount, setDotsCount] = React.useState(0);

    // set timeout
    useEffect(() => {
        const interval = setInterval(() => {
            setDotsCount(dotsCount + 1);
            if (dotsCount === 3) {
                setDotsCount(0);
            }
            setDots('.'.repeat(dotsCount));
        }, 500);
        return () => clearInterval(interval);
    }, [dotsCount]);

    // generate chart
    useEffect(() => {
        if (shouldDisplayGraph && canvasRef.current) {
            if (window[canvasId] instanceof Chart) {
                window[canvasId].destroy();
                setGraphHasBeenGenerated(false);
            }
            generateChart();
        }
    }, [shouldDisplayGraph, canvasId]);

    useEffect(() => {
        return () => {
            if (window[canvasId] instanceof Chart) {
                window[canvasId].destroy();
            }
        };
    }, [canvasId]);

    useEffect(() => {
        setShouldDisplayGraph(false);
    }, [query, naturalQuery, random, lastCanvasId]);

    const [resultsAnimated, setResultsAnimated] = React.useState("");
    const [loadingAnalysis, setLoadingAnalysis] = React.useState(false);

    const analyseResults = async (rawResults) => {
        if (resultsAnimated) {
            setResultsAnimated("");
            return;
        }
        setLoadingAnalysis(true);
        const data = JSON.parse(rawResults);

        const analyseResults = httpsCallable(functions, 'generate_data_analysis_v1');

        try {
            const result = await analyseResults({ data });
            // show letter for letter
            // until resultsAnimated === result.data.analysis
            // using setInterval or setTimeout

            const analysisText = result.data.analysis;
            let timeout = 10; // tiempo entre cada letra: 20 ms
            let index = 0;
            let resultBeingAnimated = "";
            let isBold = false;

            const interval = setInterval(() => {
                if (analysisText[index] === '*' && analysisText[index + 1] === '*') {
                    isBold = !isBold;
                    resultBeingAnimated += isBold ? "<b>" : "</b>";
                    index += 2; // Skip the asterisks
                } else {
                    resultBeingAnimated += analysisText[index];
                    index++;
                }
                setResultsAnimated(resultBeingAnimated);
                if (index >= analysisText.length) {
                    clearInterval(interval);
                    setLoadingAnalysis(false);
                }
            }, timeout);
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const convertToBoldHTML = (text) => {
        const convertedText = text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
        return { __html: convertedText };
    };

    useEffect(() => {
        if (rawResults) {
            setResultsAnimated("");
        }
        setShouldDisplayGraph(false);
    }, [rawResults]);

    return (data && data.length > 0 ?
        <div className=" max-w-full p-4 overflox-x-scroll">
            <div style={{ maxHeight: `${shouldDisplayGraph ? '400px' : '0px'}`, minHeight: `${shouldDisplayGraph ? '400px' : '0px'}`, backgroundColor: 'transparent', justifyItems: 'center', alignItems: 'center', flexDirection: 'row', display: 'flex', transition: 'max-height 0.5s ease-in-out' }} className='relative'>
                {canvasId && <canvas ref={canvasRef} id={canvasId} className={`mx-auto ${shouldDisplayGraph ? "h-full w-full" : "h-0 w-0"}`} />}
                {!graphHasBeenGenerated && shouldDisplayGraph && <p className="text-gray-400 mt-4 absolute w-full text-center text-sm loadingNoStyle">Generando gráfico{dots}</p>}
            </div>

            <div className={`flex justify-between items-center `}>
                {resultsAnimated && <p className="text-gray-400 mt-4 whitespace-pre-wrap" dangerouslySetInnerHTML={convertToBoldHTML(resultsAnimated)} />}
            </div>

            {false &&
                <div className={`flex justify-between px-3 mb-2 items-center `}> {/* ${showFullQuery ? "items-start" : "items-center"} */}
                    <div className={`flex w-full flex-row items-center transition-all duration-500 my-0 ${showFullQuery ? "" : "my-auto"}`}>

                        <div className=" h-auto flex flex-row items-center ml-auto">
                            <div className="flex flex-row">
                                <Tooltip arrow title={shouldDisplayGraph ? "Ocultar Gráfico" : "Generar Gráfico"}>
                                    <IconButton onClick={() => setShouldDisplayGraph(!shouldDisplayGraph)} disabled={shouldDisplayGraph && !graphHasBeenGenerated} className={`${(shouldDisplayGraph && !graphHasBeenGenerated) ? 'icon' : ''}`}>
                                        <div className="icon-container">
                                            <AutoGraph className=
                                                {(shouldDisplayGraph && !graphHasBeenGenerated) ? 'icon' : 'text-gray-400'} />
                                        </div>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip arrow title={loadingAnalysis ? "Analizando..." : resultsAnimated ? "Ocultar Análisis" : "Analizar Resultados"}>
                                    <IconButton onClick={() => { analyseResults(rawResults) }} disabled={loadingAnalysis}>
                                        <div className="icon-container">
                                            <Troubleshoot className=
                                                {loadingAnalysis ? 'icon' : 'text-gray-400'} />
                                        </div>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                <div className="flex flex-row flex-shrink overflow-hidden" style={{ flexBasis: 'auto', flexGrow: 0, width: '100%' }}>
                    <div className="w-full max-h-64 overflow-y-auto overflow-x-auto" onCopy={handleCopy}>
                        <div className="inline-block min-w-full align-middle">
                            <table className="w-full table-fixed border-separate border-spacing-0 border-tools-table-outline">

                                <thead className='sticky top-0 z-10 bg-gray-100'>
                                    <tr>
                                        {Object.keys(data[0]).map((key, index) => (
                                            <th
                                                className={`text-sm border-l truncate min-w-24 border-b border-t m-0 border-[rgba(0,0,0,.15)] text-left font-semibold bg-[rgba(0,0,0,.1)] px-3 py-1 ${index === 0 ? 'rounded-tl-md' : ''} ${index === Object.keys(data[0]).length - 1 ? 'rounded-tr-md border-r' : ''}`}
                                                style={{ minWidth: '26rem' }}
                                                key={index}
                                            >
                                                {toTitleCase(key.replace(/_/g, ' '))}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((row, indexRow) => (
                                        <tr key={indexRow}>
                                            {Object.values(row).map((value, indexCol) => (
                                                <td
                                                    style={{ minWidth: '26rem' }}
                                                    key={indexCol}
                                                    className={`border-l truncate border-b m-0 border-[rgba(0,0,0,.15)] text-sm min-w-24 text-left px-3 py-1 ${(indexRow === data.length - 1 && indexCol === 0) ? 'rounded-bl-md' : ''} ${(indexRow === data.length - 1 && indexCol === Object.values(row).length - 1) ? 'rounded-br-md' : ''} ${indexCol === Object.values(row).length - 1 ? 'border-r' : ''}`} data-copy data-value={typeof value === 'number' ? value : null}
                                                >
                                                    {typeof value === 'string' ? value : (typeof value === 'number' ? formatNumberWithCommas(value) : value)}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
        </div >
        : null
    );
}
