import React, { useEffect, useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import CasinoIcon from '@mui/icons-material/Casino';
import './Chat.css'
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { setDoc, doc, getDocs, collection, serverTimestamp, orderBy, query, increment, where, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from '../helpers/firebase';
import useDB from '../hooks/useDB';
import Results from '../components/query/Results';
import { Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useTopbar } from '../helpers/TopbarContext';
import MenuIcon from '@mui/icons-material/Menu';
import useAuth from '../hooks/useAuth';

export default function Chat() {
    const [sidebarOpen, setSidebarOpen] = React.useState(false)
    const [message, setMessage] = React.useState('Traer el inventario ordenado de mayor a menor existencia')
    const [messages, setMessages] = React.useState([])
    const { currentUser } = useAuth();

    const {
        executeNaturalQuery,
        loadingRawQuery,
        loadingRandomQuery,
        resultsHistory
    } = useDB("");


    const sendMessage = async () => {
        if (message === '') return
        const tempMessage = message;
        setMessages([...messages, { type: 'user', message: tempMessage }]);
        setMessage('')

        // upload the message to the database as subcollection of the current chat
        const chatRef = doc(db, "chats", selectedChatId);

        const messageRef = doc(collection(chatRef, "messages"));

        await setDoc(messageRef, {
            type: 'user',
            message: tempMessage,
            timestamp: serverTimestamp(),
            created_by: currentUser.uid,
        });

        // increment by 1 messages field in the chat document
        await updateDoc(chatRef, { messages_count: increment(1) });

        try {
            const result = await executeNaturalQuery(tempMessage);

            if (result) {
                setMessages(prevMessages => [...prevMessages, { type: 'bot', message: result }]); // Add the result to the messages state

                // upload the message to the database as subcollection of the current chat
                const messageRef = doc(collection(chatRef, "messages"));
                await setDoc(messageRef, {
                    type: 'bot',
                    message: {
                        rawResults: JSON.stringify((JSON.parse(result.rawResults)).slice(0, 10)),
                        canvasId: result.canvasId,
                        query: result.query,
                    },
                    timestamp: serverTimestamp(),
                    created_by: currentUser.uid,
                });

                // increment by 1 messages field in the chat document
                await updateDoc(chatRef, { messages_count: increment(1) });

            } else {
                setMessages(prevMessages => [...prevMessages, { type: 'bot', message: 'No fui capaz de generar esa información. Disculpa.' }]);

                // upload the message to the database as subcollection of the current chat
                const messageRef = doc(collection(chatRef, "messages"));
                await setDoc(messageRef, {
                    type: 'bot',
                    message: 'No fui capaz de generar esa información. Disculpa.',
                    timestamp: serverTimestamp(),
                    created_by: currentUser.uid,
                });

                // increment by 1 messages field in the chat document
                await updateDoc(chatRef, { messages_count: increment(1) });
            }
        } catch (error) {
            setMessages(prevMessages => [...prevMessages, { type: 'bot', message: 'No fui capaz de generar esa información. Disculpa.' }]);

            // upload the message to the database as subcollection of the current chat
            const messageRef = doc(collection(chatRef, "messages"));
            await setDoc(messageRef, {
                type: 'bot',
                message: 'No fui capaz de generar esa información. Disculpa.',
                timestamp: serverTimestamp(),
                created_by: currentUser.uid,
            });

            // increment by 1 messages field in the chat document
            await updateDoc(chatRef, { messages_count: increment(1) });
        }
    }

    const [chatsGroupedByDate, setChatsGroupedByDate] = React.useState({
        "Hoy": [],
        "Ayer": [],
        "7 días anteriores": [],
        "30 días anteriores": [],
    });

    const [selectedChatId, setSelectedChatId] = React.useState(1);

    const createChat = async (title) => {
        const date = new Date();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        const chat = {
            title: `${title} - ${time}`,
            messages: [],
            timestamp: serverTimestamp(),
            messages_count: 0,
        }

        // Guarda el chat en Firestore

        const chatRef = doc(collection(db, "chats"));
        await setDoc(chatRef, chat);

        setSelectedChatId(chatRef.id);
        setMessages([]);
        // set in Hoy group add first in the array
        const tempChatsGroupedByDate = chatsGroupedByDate;
        tempChatsGroupedByDate["Hoy"].unshift({ id: chatRef.id, ...chat });
        setChatsGroupedByDate(tempChatsGroupedByDate);

    }

    const handleChangeChat = async (chat) => {
        setSelectedChatId(chat.id);
        setMessages([]);
        // get messages from db
        const chatRef = doc(db, "chats", chat.id);
        const messagesRef = collection(chatRef, "messages");

        const q = query(messagesRef, orderBy("timestamp", "asc"));
        const querySnapshot = await getDocs(q);

        const tempMessages = [];
        querySnapshot.forEach((doc) => {
            const message = doc.data();
            tempMessages.push(message);
        });
        setMessages(tempMessages);

    }

    // when new messages scroll to bottom
    const messagesEndRef = React.useRef(null)
    React.useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, [messages])

    const [loadingChats, setLoadingChats] = React.useState(false);
    const [initialFetchDone, setInitialFetchDone] = React.useState(false);

    const getChats = async (shouldCreateChat = true) => {
        setLoadingChats(true);
        // Nuevo chat y la hora en formato 14:54:54
        // if no messages in current chat, create a new chat

        if (shouldCreateChat) {
            // delete all empty chats
            const qEmpty = query(collection(db, "chats"), where("messages_count", "==", 0));
            const queryEmptySnapshot = await getDocs(qEmpty);
            queryEmptySnapshot.forEach((doc) => {
                deleteDoc(doc.ref);
            });

            await createChat('Nuevo chat');
        }

        const q = query(collection(db, "chats"), orderBy("timestamp", "desc"));
        const querySnapshot = await getDocs(q);

        // agrupar por hoy, ayer, 7 días, 30 días

        const tempChatsGroupedByDate = {
            "Hoy": [],
            "Ayer": [],
            "7 días anteriores": [],
            "30 días anteriores": [],
        }

        querySnapshot.forEach((doc) => {
            const chat = doc.data();
            // if not chat.timestamp
            if (!chat.timestamp) {
                return
            }
            const chatDate = new Date(chat.timestamp.toDate());
            const currentDate = new Date();

            const diffTime = Math.abs(currentDate - chatDate);
            const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

            if (diffDays === 0) {
                tempChatsGroupedByDate["Hoy"].push({ id: doc.id, ...chat });
            } else if (diffDays === 1) {
                tempChatsGroupedByDate["Ayer"].push({ id: doc.id, ...chat });
            } else if (diffDays <= 7) {
                tempChatsGroupedByDate["7 días anteriores"].push({ id: doc.id, ...chat });
            } else if (diffDays <= 30) {
                tempChatsGroupedByDate["30 días anteriores"].push({ id: doc.id, ...chat });
            }

            setChatsGroupedByDate(tempChatsGroupedByDate);

        });

        return querySnapshot.docs;
    }

    React.useEffect(() => {
        if (!initialFetchDone && !loadingChats) {
            getChats(true).then(() => setInitialFetchDone(true));
        }
    }, [initialFetchDone, loadingChats, getChats]);

    const [extraClassesForDiceAnimation, setExtraClassesForDiceAnimation] = React.useState('')
    const [rollingDice, setRollingDice] = React.useState(false)

    const rollDice = async () => {

        if (rollingDice) return

        try {
            setRollingDice(true)

            // set extra classes for dice animation to mb-12 rotate-90
            setExtraClassesForDiceAnimation('mb-16 -rotate-45 transition-all duration-200')
            await new Promise(resolve => setTimeout(resolve, 200));

            setExtraClassesForDiceAnimation('-rotate-90 transition-all duration-200')

            await new Promise(resolve => setTimeout(resolve, 200));

            setExtraClassesForDiceAnimation('transition-none')

            await new Promise(resolve => setTimeout(resolve, 100));

            setExtraClassesForDiceAnimation('')

            const result = await executeNaturalQuery("Genera una consulta aleatoria que pueda aportar valor", 0.5)

            if (result) {
                setMessages(prevMessages => [...prevMessages, { type: 'bot', message: result }]); // Add the result to the messages state

                // upload the message to the database as subcollection of the current chat
                const chatRef = doc(db, "chats", selectedChatId);
                const messageRef = doc(collection(chatRef, "messages"));
                await setDoc(messageRef, {
                    type: 'bot',
                    message: {
                        rawResults: JSON.stringify((JSON.parse(result.rawResults)).slice(0, 10)),
                        canvasId: result.canvasId,
                        query: result.query,
                    },
                    timestamp: serverTimestamp(),
                    created_by: currentUser.uid,
                });

                // increment by 1 messages field in the chat document
                await updateDoc(chatRef, { messages_count: increment(1) });
            }
        } catch (error) {
            console.error('Error rolling dice:', error);
        }
        finally {
            setRollingDice(false)
        }
    }

    const { setShouldSidebarBeOpen, shouldSidebarBeOpen } = useTopbar();

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);


    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize
        );
    }, []);


    return (
        <div className='flex flex-row w-full  max-w-full  bg-white h-screen'>
            <div className='flex flex-row w-full h-full'>
                <div className={`bg-gray-100 flex flex-col h-full transition-all duration-100 ${sidebarOpen ? 'w-96 ' : 'w-0'}`}>

                    {sidebarOpen &&
                        <div className='min-h-16 max-h-16 w-full px-3 flex flex-row justify-between items-center hover:text-gray-600 transition-all duration-100'>
                            <Tooltip title="Ocultar historial" placement="right" arrow>
                                <button className=' text-gray-500 flex items-center justify-center w-10 h-10 hover:text-gray-600 transition-all duration-100' onClick={() => setSidebarOpen(!sidebarOpen)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 24 24" className="icon-xl-heavy"><path fill="currentColor" fillRule="evenodd" d="M8.857 3h6.286c1.084 0 1.958 0 2.666.058.729.06 1.369.185 1.961.487a5 5 0 0 1 2.185 2.185c.302.592.428 1.233.487 1.961.058.708.058 1.582.058 2.666v3.286c0 1.084 0 1.958-.058 2.666-.06.729-.185 1.369-.487 1.961a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C17.1 21 16.227 21 15.143 21H8.857c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.232-.487-1.961C1.5 15.6 1.5 14.727 1.5 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.728.185-1.369.487-1.96A5 5 0 0 1 4.23 3.544c.592-.302 1.233-.428 1.961-.487C6.9 3 7.773 3 8.857 3M6.354 5.051c-.605.05-.953.142-1.216.276a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216-.05.617-.051 1.41-.051 2.546v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h.6V5h-.6c-1.137 0-1.929 0-2.546.051M11.5 5v14h3.6c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.134-.263.226-.611.276-1.216.05-.617.051-1.41.051-2.546v-3.2c0-1.137 0-1.929-.051-2.546-.05-.605-.142-.953-.276-1.216a3 3 0 0 0-1.311-1.311c-.263-.134-.611-.226-1.216-.276C17.029 5.001 16.236 5 15.1 5zM5 8.5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1M5 12a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1" clipRule="evenodd"></path></svg>
                                </button>
                            </Tooltip>

                            <Tooltip title="Nuevo chat" placement="right" arrow>
                                <button className=' text-gray-500 flex items-center justify-center w-10 h-10 hover:text-gray-600 transition-all duration-100' onClick={() => createChat('Nuevo chat')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 24 24" className="icon-xl-heavy"><path d="M15.673 3.913a3.121 3.121 0 1 1 4.414 4.414l-5.937 5.937a5 5 0 0 1-2.828 1.415l-2.18.31a1 1 0 0 1-1.132-1.13l.311-2.18A5 5 0 0 1 9.736 9.85zm3 1.414a1.12 1.12 0 0 0-1.586 0l-5.937 5.937a3 3 0 0 0-.849 1.697l-.123.86.86-.122a3 3 0 0 0 1.698-.849l5.937-5.937a1.12 1.12 0 0 0 0-1.586M11 4A1 1 0 0 1 10 5c-.998 0-1.702.008-2.253.06-.54.052-.862.141-1.109.267a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216C5.001 8.471 5 9.264 5 10.4v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h3.2c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.126-.247.215-.569.266-1.108.053-.552.06-1.256.06-2.255a1 1 0 1 1 2 .002c0 .978-.006 1.78-.069 2.442-.064.673-.192 1.27-.475 1.827a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C15.6 21 14.727 21 13.643 21h-3.286c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.233-.487-1.961C3 15.6 3 14.727 3 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.729.185-1.369.487-1.961A5 5 0 0 1 5.73 3.545c.556-.284 1.154-.411 1.827-.475C8.22 3.007 9.021 3 10 3A1 1 0 0 1 11 4"></path></svg>
                                </button>
                            </Tooltip>
                        </div>
                    }

                    {sidebarOpen &&
                        <div className='w-full flex flex-col items-start justify-start space-y-2 overflow-y-scroll'>
                            <h2 className='text-gray-600 px-3 font-semibold text-2xl select-none'>
                                Historial
                            </h2>
                            {Object.keys(chatsGroupedByDate).map((date, index) => {
                                return (
                                    chatsGroupedByDate[date].length > 0 &&
                                    <div key={index} className='w-full px-3 flex flex-col items-start justify-start space-y-2'>
                                        <small className='text-gray-500 select-none'>
                                            {date}
                                        </small>
                                        {chatsGroupedByDate[date].map((chat, index) => (
                                            <div key={index} className={`w-full min-h-10 max-h-10 py-2 overflow-hidden whitespace-nowrap cursor-pointer select-none rounded-xl flex flex-row items-center justify-start font-medium px-4 ${selectedChatId === chat.id ? 'bg-gray-600 hover:bg-gray-700 text-white' : 'bg-transparent hover:bg-gray-200 text-black'}`} onClick={() => handleChangeChat(chat)}>
                                                {chat.title}
                                                <button className='ml-auto opacity-0 hover:text-red-500 hover:opacity-100 transition-all duration-100
                                                ' onClick={async (e) => {
                                                        e.stopPropagation();


                                                        await deleteDoc(doc(db, "chats", chat.id));

                                                        // count from each date in chatsGroupedByDate
                                                        let count = 0;
                                                        const tempChatsGroupedByDate = chatsGroupedByDate;
                                                        for (const date in tempChatsGroupedByDate) {
                                                            const newChats = tempChatsGroupedByDate[date].filter(c => c.id !== chat.id);
                                                            tempChatsGroupedByDate[date] = newChats;
                                                            count += newChats.length;
                                                        }

                                                        // if selected chat, set selected chat to first chat
                                                        if (selectedChatId === chat.id) {
                                                            if (count > 0) {
                                                                const newChats = await getChats(false);
                                                                handleChangeChat(newChats[0]);
                                                            } else {
                                                                await getChats(true);
                                                            }
                                                        } else {
                                                            getChats(false);
                                                        }
                                                    }}
                                                >
                                                    <Delete />
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
                <div className='flex flex-col justify-between h-full w-full'>
                    <div className='h-16 w-full flex flex-row justify-between'>
                        <div className='h-16 flex flex-row'>
                            {!sidebarOpen &&
                                <div className='px-4 flex flex-row items-center'>
                                    {screenWidth < 768 &&
                                        <Tooltip title="Mostrar menu lateral" placement="bottom" arrow>
                                            <MenuIcon
                                                className='text-gray-500 w-10 h-10 hover:text-gray-600 transition-all duration-100'
                                                onClick={() => setShouldSidebarBeOpen(!shouldSidebarBeOpen)}
                                                fontSize='large'
                                            />
                                        </Tooltip>
                                    }
                                    <Tooltip title="Mostrar historial" placement="bottom" arrow>
                                        <button className='bg-white text-gray-500 flex items-center justify-center w-10 h-10 hover:text-gray-600 transition-all duration-100' onClick={() => setSidebarOpen(!sidebarOpen)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 24 24" className="icon-xl-heavy"><path fill="currentColor" fillRule="evenodd" d="M8.857 3h6.286c1.084 0 1.958 0 2.666.058.729.06 1.369.185 1.961.487a5 5 0 0 1 2.185 2.185c.302.592.428 1.233.487 1.961.058.708.058 1.582.058 2.666v3.286c0 1.084 0 1.958-.058 2.666-.06.729-.185 1.369-.487 1.961a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C17.1 21 16.227 21 15.143 21H8.857c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.232-.487-1.961C1.5 15.6 1.5 14.727 1.5 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.728.185-1.369.487-1.96A5 5 0 0 1 4.23 3.544c.592-.302 1.233-.428 1.961-.487C6.9 3 7.773 3 8.857 3M6.354 5.051c-.605.05-.953.142-1.216.276a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216-.05.617-.051 1.41-.051 2.546v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h.6V5h-.6c-1.137 0-1.929 0-2.546.051M11.5 5v14h3.6c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.134-.263.226-.611.276-1.216.05-.617.051-1.41.051-2.546v-3.2c0-1.137 0-1.929-.051-2.546-.05-.605-.142-.953-.276-1.216a3 3 0 0 0-1.311-1.311c-.263-.134-.611-.226-1.216-.276C17.029 5.001 16.236 5 15.1 5zM5 8.5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1M5 12a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1" clipRule="evenodd"></path></svg>
                                        </button>
                                    </Tooltip>

                                    <Tooltip title="Nuevo chat" placement="bottom" arrow>
                                        <button className='bg-white text-gray-500 flex items-center justify-center w-10 h-10 hover:text-gray-600 transition-all duration-100' onClick={() => createChat('Nuevo chat')}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 24 24" className="icon-xl-heavy"><path d="M15.673 3.913a3.121 3.121 0 1 1 4.414 4.414l-5.937 5.937a5 5 0 0 1-2.828 1.415l-2.18.31a1 1 0 0 1-1.132-1.13l.311-2.18A5 5 0 0 1 9.736 9.85zm3 1.414a1.12 1.12 0 0 0-1.586 0l-5.937 5.937a3 3 0 0 0-.849 1.697l-.123.86.86-.122a3 3 0 0 0 1.698-.849l5.937-5.937a1.12 1.12 0 0 0 0-1.586M11 4A1 1 0 0 1 10 5c-.998 0-1.702.008-2.253.06-.54.052-.862.141-1.109.267a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216C5.001 8.471 5 9.264 5 10.4v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h3.2c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.126-.247.215-.569.266-1.108.053-.552.06-1.256.06-2.255a1 1 0 1 1 2 .002c0 .978-.006 1.78-.069 2.442-.064.673-.192 1.27-.475 1.827a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C15.6 21 14.727 21 13.643 21h-3.286c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.233-.487-1.961C3 15.6 3 14.727 3 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.729.185-1.369.487-1.961A5 5 0 0 1 5.73 3.545c.556-.284 1.154-.411 1.827-.475C8.22 3.007 9.021 3 10 3A1 1 0 0 1 11 4"></path></svg>
                                        </button>
                                    </Tooltip>
                                </div>
                            }
                            {screenWidth > 768 &&
                                <div className=' w-48 px-3 flex flex-col justify-start pt-2 items-start'>
                                    <small className='text-gray-500 select-none'>
                                        Mis Empresas
                                    </small>
                                    <h2 className='text-[#326B8E] font-extrabold text-xl -mt-2 select-none'>
                                        AI Chat
                                    </h2>
                                </div>
                            }
                        </div>
                        {false &&
                            <div className='flex flex-row items-center px-8'>
                                <Tooltip title="Expandir todos los mensajes" placement="bottom" arrow>
                                    <button className='bg-white text-gray-500 w-10 h-10 mr-6 hover:text-gray-600 transition-all duration-100 text-3xl'>
                                        <UnfoldMoreIcon fontSize='inherit' />
                                    </button>
                                </Tooltip>
                                <Tooltip title="Contraer todos los mensajes" placement="bottom" arrow>
                                    <button className='bg-white text-gray-500 w-10 h-10 mr-8 hover:text-gray-600 transition-all duration-100 text-3xl'>
                                        <UnfoldLessIcon fontSize='inherit' />
                                    </button>
                                </Tooltip>
                                <button className='bg-[#326B8E] rounded-full w-10 h-10 hover:bg-[#2A5D7A] transition-all duration-100'>
                                    {currentUser?.email?.charAt(0).toUpperCase()}
                                </button>
                                <button className='bg-white text-black hover:text-gray-600 w-7 h-10 gray-600 transition-all duration-100'>
                                    <ArrowDropDownIcon />
                                </button>
                            </div>
                        }
                    </div>
                    <div className='min-w-full max-w-full flex flex-col h-full items-center justify-start py-4 space-y-2 overflow-y-auto'>
                        {messages.length > 0 &&

                            messages.map((msg, index) => {
                                return msg.type === 'user' ?
                                    <div key={index} className='bg-white min-w-full flex flex-row justify-end max-w-full'>
                                        <div className='bg-gray-100 ml-12 mr-6 py-3 rounded-3xl px-3 flex flex-col items-center justify-center break-words overflow-y-auto'>
                                            {msg.message}
                                        </div>
                                    </div> : msg.type === "bot" && (msg.message.rawResults && msg.message.query && msg.message.canvasId) ? (
                                        <Results key={index} rawResults={msg.message.rawResults} query={msg.message.query} naturalQuery={msg.message.naturalQuery} random={msg.message.random} canvasId={msg.message.canvasId} lastCanvasId={resultsHistory.length - 1 === index} />
                                    ) :
                                        <div key={index} className='min-w-full flex flex-row justify-start max-w-full'>
                                            <div className='bg-gray-200 mr-12 ml-6 py-3 rounded-3xl px-3 flex flex-col items-center justify-center break-words overflow-y-auto'>
                                                {msg.message}
                                            </div>
                                        </div>
                            })
                        }
                        <div ref={messagesEndRef}></div>
                    </div>
                    <div className='w-full flex flex-row items-center pb-4'>
                        <div className='w-52 h-full flex flex-row items-center justify-center'>
                            {false &&
                                <Tooltip title="Consulta aleatoria" placement="top" arrow>
                                    <button className={`w-9 h-9 ${extraClassesForDiceAnimation} ${!rollingDice ? 'text-[#DA8CEF] hover:text-[#b373c5]' : 'text-gray-300'}`} onClick={() => rollDice()} disabled={rollingDice}>
                                        <CasinoIcon />
                                    </button>
                                </Tooltip>
                            }
                        </div>
                        <div className='w-full h-full flex flex-col items-center justify-center'>

                            <TextareaAutosize
                                aria-label="maximum height"
                                minRows={1}
                                maxRows={7}
                                placeholder="Escribe aquí tu mensaje..."
                                rows={1}
                                className='textarea w-full'
                                style={{
                                    width: '100%',
                                    borderRadius: '24px',
                                    overflowY: 'auto',
                                    border: '2px solid black',
                                    padding: '8px 16px',
                                    resize: 'none', // Evita que el usuario cambie el tamaño
                                    backgroundColor: 'white', // Rojo
                                    color: 'black',
                                    boxSizing: 'border-box',
                                    '&:focus': {
                                        borderColor: 'black' // Color de borde cuando el elemento está enfocado
                                    }
                                }}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </div>
                        <div className='w-52 h-full flex flex-row items-center justify-start pl-4'>

                            <button className='bg-white w-10 h-10' onClick={() => sendMessage()} disabled={loadingRawQuery || loadingRandomQuery}>
                                <img src="/assets/send.png" alt="Send Icon" className={`transition-all duration-100 ${(loadingRawQuery || loadingRandomQuery) ? 'cursor-wait opacity-20' : 'hover:-rotate-45 hover:-mt-4 cursor-pointer hover:opacity-40 opacity-30'}`} />
                            </button>
                        </div>
                    </div>
                </div >
            </div>
        </div >
    )
}