import { useState, useEffect } from 'react';
import { calculateMetricValue } from '../utils/calculations';

export const useChartData = (ordersData, selectedStores, metric, startDate, endDate, selectedChartInterval) => {
    const [renderChartData, setRenderChartData] = useState([]);
    const [totalsByStore, setTotalsByStore] = useState({});

    const calculateTotalsByStore = (chartData) => {
        const totals = {};
        chartData.forEach(monthData => {
            Object.entries(monthData).forEach(([key, value]) => {
                if (key !== 'date') {
                    totals[key] = totals[key] || 0;
                    if (metric === "TotalAcumulado") {
                        totals[key] = value;
                    } else {
                        totals[key] += value;
                    }
                }
            });
        });

        if (metric === "AOV" && chartData.length > 0) {
            Object.keys(totals).forEach(key => {
                totals[key] = Math.round((totals[key] / chartData.length) * 100) / 100;
            });
        }

        setTotalsByStore(totals);
    };

    useEffect(() => {
        if (!ordersData || !selectedStores.length) {
            setRenderChartData([]);
            return;
        }

        const dates = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            dates.push(new Date(currentDate));

            switch (selectedChartInterval.value) {
                case 'DIA':
                    currentDate.setDate(currentDate.getDate() + 1);
                    break;
                case 'SEMANA':
                    currentDate.setDate(currentDate.getDate() + 7);
                    break;
                case 'MES':
                    currentDate.setMonth(currentDate.getMonth() + 1);
                    break;
                case 'ANO':
                    currentDate.setFullYear(currentDate.getFullYear() + 1);
                    break;
                default:
                    currentDate.setDate(currentDate.getDate() + 1);
            }
        }

        const newChartData = dates.map(date => {
            const entry = { date: date.toISOString() };

            selectedStores.forEach(store => {
                const storeOrders = ordersData[store.value]?.currentPeriod || [];
                const dateValue = calculateMetricValue(storeOrders, date, metric, selectedChartInterval.value);
                entry[store.value] = dateValue;
            });

            return entry;
        });

        setRenderChartData(newChartData);
        calculateTotalsByStore(newChartData);
    }, [ordersData, metric, selectedStores, startDate, endDate, selectedChartInterval]);

    return { renderChartData, totalsByStore };
};