import { useState, useEffect } from 'react';
import { auth, db } from '../helpers/firebase';
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { useTest } from '../helpers/TestContext';

function useAuth() {
  const { testMode } = useTest();

  const [currentUser, setCurrentUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);

  // const TEST_USER_ID = 'Ai9jIUXctMNyMgfC7RzmQiGWW043';
  // const TEST_USER_ID = 'XVoYyOpPDbMuLlIjL4fexwDCt1M2';
  // const TEST_USER_ID = 'NVfwsWHG2ReIPTJLYfCdo53nTGB2';
  const TEST_USER_ID = 'ZRi0TNwjK0ayv4CGFmeKhuSkbQ63';

  const fetchUserData = async (user) => {
    setLoadingUser(true);

    try {
      if (user) {
        const userRef = doc(db, 'users', testMode ? TEST_USER_ID : user.uid);
        const userSnap = await getDoc(userRef);

        // console.log('userSnap: ', userSnap.data());

        if (userSnap.exists()) {
          if (testMode) {
            setCurrentUser({ ...userSnap.data(), uid: userSnap.id, user: { ...userSnap.data(), uid: userSnap.id } });
            return;
          } else {
            setCurrentUser({ ...userSnap.data(), uid: userSnap.id, user });
          }
          if (!testMode) {
            const userDataToSave = {
              uid: user.uid,
              email: user.email,
              displayName: user.displayName,
              photoURL: user.photoURL,
              lastLogin: serverTimestamp(),
            };
            await setDoc(userRef, userDataToSave, { merge: true });
          }
        } else if (!testMode) {
          const userDataToSave = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            createdAt: serverTimestamp(),
          };
          await setDoc(userRef, userDataToSave, { merge: true });

          setCurrentUser({ ...userDataToSave, user });
        }
      }
    } catch (error) {
      console.error('Error fetching user data: ', error);
    } finally {
      setLoadingUser(false);
    }
  }

  useEffect(() => {
    let unsubscribe = () => { };

    try {
      if (testMode) {
        fetchUserData({ uid: TEST_USER_ID });
      } else {
        unsubscribe = onAuthStateChanged(auth, async (user) => {
          if (!user) {
            setCurrentUser(null);
            return;
          }
          if (!testMode) {
            setCurrentUser(user);
          }
          fetchUserData(user);
        });
      }
    } catch (error) {
      console.error('Error in useAuth: ', error);
    } finally {
      setLoadingUser(false);
    }

    return unsubscribe;
  }, [testMode]);

  return { currentUser, setCurrentUser, loadingUser }
}

export default useAuth;