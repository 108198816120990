import React, { useState } from 'react';
import { Bar, BarChart, Line, LineChart, CartesianGrid, XAxis, YAxis, Tooltip as RechartsTooltip, Legend } from 'recharts';
import { formatCurrency } from '../utils';
import { CustomTooltip } from './CustomTooltip';
import { CustomLegend } from './CustomLegend';
import OrderDetailsModal from './OrderDetailsModal';

export const Chart = ({
    graphicType,
    chartWidth,
    chartHeight,
    renderChartData,
    selectedChartInterval,
    metric,
    selectedStores,
    storeNames,
    totalsByStore,
    legendRef,
    ordersData // Add this prop to access the original orders data
}) => {
    const [selectedOrders, setSelectedOrders] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleBarClick = (data, index, e) => {
        if (!data || !data.activePayload || !data.activePayload[0]) return;

        const clickedBar = data.activePayload[0];
        const storeId = clickedBar.dataKey;
        const date = new Date(data.activeLabel);

        // Find orders for the clicked bar based on the interval
        const storeOrders = ordersData[storeId]?.currentPeriod || [];
        const filteredOrders = storeOrders.filter(order => {
            const orderDate = new Date(order.date_timestamp);

            switch (selectedChartInterval.value) {
                case 'DIA':
                    return orderDate.toISOString().split('T')[0] === date.toISOString().split('T')[0];
                case 'SEMANA': {
                    const weekStart = new Date(date);
                    weekStart.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1));
                    const weekEnd = new Date(weekStart);
                    weekEnd.setDate(weekStart.getDate() + 6);
                    return orderDate >= weekStart && orderDate <= weekEnd;
                }
                case 'MES':
                    return orderDate.getMonth() === date.getMonth() &&
                        orderDate.getFullYear() === date.getFullYear();
                case 'ANO':
                    return orderDate.getFullYear() === date.getFullYear();
                default:
                    return false;
            }
        });

        setSelectedOrders({
            orders: filteredOrders,
            storeName: storeNames[storeId] || storeId,
            date: date
        });
        setIsModalOpen(true);
    };

    const chartConfig = {
        width: chartWidth - 45,
        height: chartHeight,
        data: renderChartData,
        margin: { left: 30 },
        onClick: graphicType === "BarChart" ? handleBarClick : undefined
    };

    const formatDate = (date) => {
        const dateObj = new Date(date);
        switch (selectedChartInterval.value) {
            case 'DIA':
                return dateObj.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit' });
            case 'SEMANA':
                return `Sem ${dateObj.getDate()}/${dateObj.getMonth() + 1}`;
            case 'MES':
                return dateObj.toLocaleDateString('es-ES', { month: 'short' });
            case 'ANO':
                return dateObj.getFullYear().toString();
            default:
                return dateObj.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit' });
        }
    };

    const commonComponents = [
        <CartesianGrid key="grid" strokeDasharray="3 3" />,
        <XAxis key="xAxis" dataKey="date" tickFormatter={formatDate} />,
        <YAxis
            key="yAxis"
            tickFormatter={
                metric === "Ventas" || metric === "AOV" || metric === "TotalAcumulado"
                    ? formatCurrency
                    : value => value.toLocaleString()
            }
            width={70}
        />,
        <RechartsTooltip key="tooltip" content={<CustomTooltip metric={metric} storeNames={storeNames} />} />,
        <Legend
            key="legend"
            content={(props) => (
                <CustomLegend
                    {...props}
                    legendRef={legendRef}
                    metric={metric}
                    storeNames={storeNames}
                    totalsByStore={totalsByStore}
                    selectedStores={selectedStores}
                />
            )}
        />
    ];

    return (
        <>
            {graphicType === "BarChart" ? (
                <BarChart {...chartConfig}>
                    {commonComponents}
                    {selectedStores.map(store => (
                        <Bar
                            key={store.value}
                            dataKey={store.value}
                            fill={store?.data?.color || 'lightgray'}
                            name={storeNames[store.value] || store.value}
                            cursor="pointer"
                        />
                    ))}
                </BarChart>
            ) : (
                <LineChart {...chartConfig}>
                    {commonComponents}
                    {selectedStores.map(store => (
                        <Line
                            key={store.value}
                            type="monotone"
                            dataKey={store.value}
                            stroke={store?.data?.color || 'lightgray'}
                            strokeWidth={2}
                            dot={false}
                            name={storeNames[store.value] || store.value}
                        />
                    ))}
                </LineChart>
            )}

            <OrderDetailsModal
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setSelectedOrders(null);
                }}
                orders={selectedOrders?.orders}
                storeName={selectedOrders?.storeName}
                date={selectedOrders?.date}
                metric={metric}
            />
        </>
    );
};