
import { Contenedor } from './Contenedor'
import imagenFondo from '../../imagenes/fondo-preguntas-y-respuestas.jpg'
import "./PreguntasFrecuentes.css"

const preguntasFrecuentes = [
  [
    {
      pregunta: '¿Kompa puede integrar datos de otras plataformas además de Amazon y Mercado Libre?',
      respuesta:
        'Actualmente nos enfocamos en Amazon y Mercado Libre, pero estamos trabajando para expandir nuestras integraciones. Mantente atento a futuras actualizaciones.',
    },
    {
      pregunta: '¿Puedo probar Kompa antes de suscribirme?',
      respuesta: 'Sí, ofrecemos una prueba gratuita de 7 días para que puedas experimentar todo el poder de Kompa sin compromiso.',
    },
    {
      pregunta: '¿Qué tan segura es mi información en Kompa?',
      respuesta:
        'La seguridad es nuestra prioridad. Utilizamos encriptación de nivel bancario y nunca compartimos tus datos. Cumplimos con todas las regulaciones de protección de datos.',
    },
  ],
  [
    {
      pregunta: '¿Con qué frecuencia se actualizan los datos en Kompa?',
      respuesta:
        'Los datos se actualizan automáticamente cada día para garantizar información consistente y precisa. Además, ofrecemos la opción de actualizar manualmente tus datos en cualquier momento, lo que te permite ver tus analíticas en tiempo real cuando lo necesites. Esta flexibilidad te asegura tener siempre acceso a la información más reciente para tomar decisiones informadas.',
    },
    {
      pregunta:
        '¿Kompa ofrece soporte para la optimización de listados?',
      respuesta:
        'Sí, Kompa proporciona insights y recomendaciones basadas en datos para ayudarte a optimizar tus listados y mejorar tu visibilidad en las plataformas.',
    },
    {
      pregunta:
        '¿Puedo compartir los informes generados por Kompa con mi equipo?',
      respuesta:
        'Absolutamente. Kompa permite compartir informes y dashboards con miembros de tu equipo, facilitando la colaboración y la toma de decisiones conjunta.',
    },
  ],
  [
    {
      pregunta: '¿Cómo maneja Kompa los diferentes tipos de moneda?',
      respuesta:
        'Kompa soporta múltiples monedas y proporciona conversiones en tiempo real, permitiéndote ver tus datos financieros en la moneda que prefieras.',
    },
    {
      pregunta: '¿Puedo esperar nuevas funcionalidades en el futuro?',
      respuesta: 'Constantemente estamos mejorando Kompa basándonos en el feedback de nuestros usuarios. Regularmente lanzamos nuevas características y mejoras.',
    },
    {
      pregunta: '¿Qué hago si necesito ayuda con Kompa?',
      respuesta:
        'Ofrecemos soporte por correo electrónico, y una extensa base de conocimientos. Nuestro equipo de soporte está listo para ayudarte con cualquier pregunta o problema.',
    },
  ],
]

export function PreguntasFrecuentes() {
  return (
    <section
      id="preguntas-frecuentes"
      aria-labelledby="preguntas-frecuentes-titulo"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <img
        className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
        src={imagenFondo}
        alt=""
        width={1558}
        height={946}
      />
      <Contenedor className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="preguntas-frecuentes-titulo"
            className="font-display text-[2.55rem] tracking-tight text-slate-900"
          >
            Preguntas frecuentes
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            Datos que impulsan tu negocio: conoce, analiza y crece con Kompa. Tu socio inteligente en e-commerce.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {preguntasFrecuentes.map((columna, indiceColumna) => (
            <li key={indiceColumna}>
              <ul role="list" className="flex flex-col gap-y-8">
                {columna.map((pregunta, indicePregunta) => (
                  <li key={indicePregunta}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {pregunta.pregunta}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{pregunta.respuesta}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Contenedor>
    </section>
  )
}