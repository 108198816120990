import React from 'react';

const options = [
    { value: 'acos', label: 'ACoS' },
    { value: 'tacos', label: 'TACoS' },
    { value: 'adSpend', label: 'Gasto en publicidad' },
    { value: 'roas', label: 'ROAS' },
];

const getColor = (value) => {
    const colorMap = {
        acos: '#FFD700',
        tacos: '#B0FC38',
        adSpend: '#FF0000',
        roas: '#FFA500',
    };
    return colorMap[value] || '#000';
};

const renderLegend = (props) => {
    const { payload } = props;
    return (
        <div style={{ margin: '0 auto', textAlign: 'center' }}>
            <ul style={{
                listStyle: 'none',
                display: 'flex',
                justifyContent: 'left',
                marginBottom: 25,
                flexWrap: 'wrap'
            }}>
                {payload.map((entry, index) => (
                    <li key={`item-${index}`} style={{
                        color: entry.color,
                        marginBottom: 10,
                        paddingLeft: 15,
                        fontSize: 12,
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: 15
                    }}>
                        <svg width="10" height={entry.value === 'adSales' || entry.value === 'organicSales' ? "10" : "3"} style={{ verticalAlign: 'middle', marginRight: 5 }}>
                            <rect width="19" height={entry.value === 'adSales' || entry.value === 'organicSales' ? "10" : "3"} fill={entry.value === 'pageviews' ? '#F6B444' : entry.color} />
                        </svg>
                        <span className='text-kompamxblue' style={{ fontSize: 12 }}>
                            {entry.value === 'adSales' ? 'Ventas de publicidad'
                                : entry.value === 'organicSales' ? 'Ventas orgánicas'
                                    : entry.value === 'acos' ? 'ACoS'
                                        : entry.value === 'tacos' ? 'TACoS'
                                            : entry.value === 'adSpend' ? 'Gasto'
                                                : 'ROAS'}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const currencyFormatter = (value) => {
    return new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN'
    }).format(value);
};

export {
    options,
    getColor,
    renderLegend,
    currencyFormatter
};
