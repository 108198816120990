import { useRef, useState } from 'react';
import useAuth from './useAuth';

const API_BASE_URL = 'https://proper-swift-vocal.ngrok-free.app';

const useDB = (initialSearchTerm = '') => {
    const [tables, setTables] = useState([]);
    const [filteredTables, setFilteredTables] = useState([]);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
    const [selectedTable, setSelectedTable] = useState('');
    const [rawQuery, setRawQuery] = useState('SELECT TRIM(RDB$RELATION_NAME) AS NOMBRE_DE_TABLA FROM RDB$RELATIONS WHERE RDB$SYSTEM_FLAG = 0');
    const [naturalQuery, setNaturalQuery] = useState('Traer el inventario ordenado de mayor a menor existencia');
    const [responseQuery, setResponseQuery] = useState(null);
    const [rawResults, setRawResults] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingRawQuery, setLoadingRawQuery] = useState(false);
    const [loadingRandomQuery, setLoadingRandomQuery] = useState(false);
    const [loadingTables, setLoadingTables] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'none' });
    const [tablePage, setTablePage] = useState(1);
    const [tableSortOrder, setTableSortOrder] = useState('default');
    const [dataPage, setDataPage] = useState(1);
    const [currentDocId, setCurrentDocId] = useState(null);
    const [likeState, setLikeState] = useState("none");
    const [resultsHistory, setResultsHistory] = useState([]);
    const { currentUser } = useAuth();
    const [contextText, setContextText] = useState(`You are a helpful assistant that generates SQL queries for a Firebird database...`);
    const [nullCounts, setNullCounts] = useState({});
    const rawQueryTitle = useRef(null);

    const executeQuery = async (query) => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/query`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query,
                    test_uid: currentUser.uid
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return data.results;
        } catch (error) {
            console.error('API call failed:', error);
            throw error;
        }
    };

    const fetchTables = async (refresh = false) => {
        const storedTables = localStorage.getItem('tablesData');

        setLoadingTables(true);

        if (storedTables && !refresh) {
            setTables(JSON.parse(storedTables));
            setFilteredTables(JSON.parse(storedTables));
            setLoadingTables(false);
        } else {
            setTables([]);

            try {
                const result = await executeQuery(
                    "SELECT TRIM(RDB$RELATION_NAME) AS RDB$RELATION_NAME FROM RDB$RELATIONS WHERE RDB$SYSTEM_FLAG = 0"
                );

                const tablesWithCounts = await Promise.all(result.map(async (table) => {
                    const countRowsQuery = `SELECT COUNT(*) FROM ${table.RDB$RELATION_NAME}`;
                    const countFieldsQuery = `SELECT COUNT(*) FROM RDB$RELATION_FIELDS WHERE RDB$RELATION_NAME = '${table.RDB$RELATION_NAME}' AND RDB$SYSTEM_FLAG = 0`;
                    const countRowsResult = await executeQuery(countRowsQuery);
                    const countFieldsResult = await executeQuery(countFieldsQuery);

                    return {
                        ...table,
                        count: countRowsResult[0].COUNT,
                        fieldsCount: countFieldsResult[0].COUNT
                    };
                }));

                setTables(tablesWithCounts);
                setFilteredTables(tablesWithCounts);
                localStorage.setItem('tablesData', JSON.stringify(tablesWithCounts));
            } catch (error) {
                console.error('Error fetching tables:', error);
            } finally {
                setLoadingTables(false);
            }
        }
    };

    const fetchTableData = async (tableName, dataPage = 1, sortConfig = { key: null, direction: 'none' }) => {
        const offset = (dataPage - 1) * 12;
        let query = `SELECT FIRST 12 SKIP ${offset} * FROM ${tableName}`;
        if (sortConfig.key && sortConfig.direction !== 'none') {
            query += ` ORDER BY ${sortConfig.key} ${sortConfig.direction === 'asc' ? 'ASC' : 'DESC'}`;
        }

        setLoadingData(true);

        try {
            const result = await executeQuery(query);
            setData(result);
            setSelectedTable(tableName);

            const nullCounts = {};

            if (result.length > 0) {
                const columns = Object.keys(result[0]);

                for (const column of columns) {
                    const nullCountQuery = `SELECT COUNT(*) AS null_count FROM ${tableName} WHERE COALESCE(${column}, '') = ''`;
                    const nullCountResult = await executeQuery(nullCountQuery);
                    nullCounts[column] = nullCountResult[0].NULL_COUNT;
                }
            }

            setNullCounts(nullCounts);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoadingData(false);
        }
    };

    const executeRawQuery = async (rawQueryParam = null) => {
        setCurrentDocId(null);
        setLikeState("none");
        if (!rawQueryParam) {
            setResponseQuery(null);
        }

        const rawQueryConst = rawQueryParam || rawQuery;
        setLoadingRawQuery(true);

        try {
            const result = await executeQuery(rawQueryConst);

            const newResult = {
                rawResults: JSON.stringify(result, null, 2),
                query: rawQueryConst,
                canvasId: `canvas${Math.random().toString(36).substring(2, 15)}`,
            };

            setResultsHistory(prevHistory => [newResult, ...prevHistory]);
            setRawResults(JSON.stringify(result, null, 2));

            setTimeout(() => {
                if (rawQueryTitle.current) rawQueryTitle.current.scrollIntoView({ behavior: 'smooth' });
            }, 500);

            return newResult;
        } catch (error) {
            const errorResult = {
                rawResults: JSON.stringify(error, null, 2),
                query: rawQueryConst,
                canvasId: `canvas${Math.random().toString(36).substring(2, 15)}`,
            };
            setResultsHistory(prevHistory => [errorResult, ...prevHistory]);
            setRawResults(JSON.stringify(error, null, 2));
            return errorResult;
        } finally {
            setLoadingRawQuery(false);
        }
    };

    const executeNaturalQuery = async (promptParam = null, temperatureParam = 0.0) => {
        setCurrentDocId(null);
        setLikeState("none");

        const promptConst = promptParam || naturalQuery;
        const temperatureConst = temperatureParam;

        if (temperatureConst > 0.0) {
            setLoadingRandomQuery(true);
        } else {
            setLoadingRawQuery(true);
        }

        try {
            const response = await fetch(`${API_BASE_URL}/api/generate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    prompt: promptConst,
                    temperature: temperatureConst,
                    test_uid: currentUser.uid
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setResponseQuery("" + data.query_generated);

            const rawResults = JSON.stringify(data.results, null, 2);
            const newResult = {
                rawResults: rawResults,
                query: data.query_generated,
                naturalQuery: promptConst,
                random: temperatureConst > 0.0,
                canvasId: `canvas${Math.random().toString(36).substring(2, 15)}`,
            };

            setRawResults(rawResults);
            setResultsHistory(prevHistory => [newResult, ...prevHistory]);

            setTimeout(() => {
                if (rawQueryTitle.current) rawQueryTitle.current.scrollIntoView({ behavior: 'smooth' });
            }, 500);

            return newResult;
        } catch (error) {
            setResponseQuery(JSON.stringify(error, null, 2));
            const errorResult = {
                rawResults: JSON.stringify(error, null, 2),
                query: error && error.message ? error.message : 'Error generando la consulta',
                naturalQuery: naturalQuery,
                canvasId: `canvas${Math.random().toString(36).substring(2, 15)}`,
            };
            setResultsHistory(prevHistory => [errorResult, ...prevHistory]);
            return errorResult;
        } finally {
            setLoadingRawQuery(false);
            setLoadingRandomQuery(false);
        }
    };

    const toggleTableSort = (tableSortCriterionParam, tableSortOrderParam) => {
        let sortedTables;
        setTablePage(1);
        switch (tableSortOrderParam) {
            case 'default':
                sortedTables = [...tables].sort((a, b) => tableSortCriterionParam === 'rows' ? b.count - a.count : b.fieldsCount - a.fieldsCount);
                setTableSortOrder('desc');
                break;
            case 'desc':
                sortedTables = [...tables].sort((a, b) => tableSortCriterionParam === 'rows' ? a.count - b.count : a.fieldsCount - b.fieldsCount);
                setTableSortOrder('asc');
                break;
            case 'asc':
                sortedTables = [...tables];
                setTableSortOrder('default');
                break;
            default:
                sortedTables = [...tables];
                setTableSortOrder('default');
        }
        setFilteredTables(sortedTables);
    };

    const handleTableDataSort = (key) => {
        let direction = 'desc';
        if (sortConfig.key === key && sortConfig.direction === 'desc') {
            direction = 'asc';
        } else if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'none';
        }
        setSortConfig({ key, direction });
        setDataPage(1);
        fetchTableData(selectedTable, 1, { key, direction });
    };

    return {
        tables,
        filteredTables,
        setFilteredTables,
        data,
        searchTerm,
        setSearchTerm,
        selectedTable,
        rawQuery,
        setRawQuery,
        rawQueryTitle,
        naturalQuery,
        setNaturalQuery,
        responseQuery,
        setResponseQuery,
        rawResults,
        setRawResults,
        loadingData,
        loadingRawQuery,
        loadingTables,
        fetchTables,
        fetchTableData,
        executeRawQuery,
        executeNaturalQuery,
        toggleTableSort,
        handleTableDataSort,
        sortConfig,
        tablePage,
        setTablePage,
        tableSortOrder,
        setTableSortOrder,
        dataPage,
        setDataPage,
        contextText,
        setContextText,
        nullCounts,
        currentDocId,
        setCurrentDocId,
        likeState,
        setLikeState,
        resultsHistory,
        setResultsHistory,
        loadingRandomQuery,
    };
};

export default useDB;