import React, { useState, useEffect } from 'react';
import DragIndicator from '@mui/icons-material/DragIndicator';
import { auth, db } from '../../../helpers/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Checkbox, Divider, Menu, MenuItem } from '@mui/material';
import CustomMenu from '../../../components/CustomMenu';
import MostSoldProducts from '../../../components/charts/MostSoldProducts';
import TrafficConversionChart from '../../../components/charts/TrafficConversionChart';
import MeLiMetrics from '../../../components/charts/MeLiMetrics';
import StorePerformanceChart from '../../../components/charts/StorePerformanceChart';
import PeriodComparisonChart from '../../../components/charts/bar/PeriodComparisonChart';
import AdMetricsChart from '../../../components/charts/AdMetricsChart/index';
import Footer from '../../../components/Footer';

export default function MetricasDeReputacionMT({ store }) {
    const [charts, setCharts] = useState([
        { id: 'MeLiMetrics1', type: 'MeLiMetrics', visible: true, label: 'Métricas de Mercado Libre 1' },
    ]);
    const [dashboardAnchorEl, setDashboardAnchorEl] = useState(null);
    const [_, setProperGreet] = useState('');
    const [loading1, setLoading1] = useState(true);
    const [menuVisible, setMenuVisible] = useState(false);
    const [anchorPosition, setAnchorPosition] = useState({ x: 0, y: 0 });
    const [screenWidth, setScreenWidth] = useState(window.innerWidth - 75);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth - 75);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleMenuClick = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setAnchorPosition({
            x: rect.left, // O quizás rect.right, dependiendo de dónde desees que aparezca el menú
            y: rect.top + window.scrollY,
        });
        setMenuVisible(!menuVisible);
    };

    const saveInitialChartOrder = async () => {
        const uid = auth.currentUser?.uid;
        if (!uid) return;

        const userDocRef = doc(db, `users/${uid}`);

        // if one of the fields is missing print out which one and return
        if (!charts.every(chart => chart.id)) {
            console.log('One of the charts is missing an id');
            return;
        }

        if (!charts.every(chart => chart.visible)) {
            console.log('One of the charts is missing a visibility flag');
            return;
        }

        await setDoc(userDocRef, {
            chartsOrder_RepMT: charts.map(chart => chart.id),
            chartsVisibility_RepMT: charts.map(chart => chart.visible)
        }, { merge: true });
    };

    useEffect(() => {
        if (loading1) return; // Asegúrate de que no se ejecute mientras se cargan los datos iniciales

        const saveChartsState = async () => {
            const uid = auth.currentUser?.uid;
            if (!uid) return;

            const userDocRef = doc(db, `users/${uid}`);

            // if one of the fields is missing print out which one and return
            if (!charts.every(chart => chart.id)) {
                console.log('One of the charts is missing an id');
                return;
            }

            if (!charts.every(chart => chart.visible)) {
                console.log('One of the charts is missing a visibility flag');
                return;
            }

            await setDoc(userDocRef, {
                chartsOrder_RepMT: charts.map(chart => chart.id),
                chartsVisibility_RepMT: charts.map(chart => chart.visible)
            }, { merge: true });
        };

        saveChartsState();
    }, [charts]); // Observa cambios en charts para actualizar Firestore    

    useEffect(() => {
        const fetchChartOrder = async () => {
            const uid = auth.currentUser?.uid;
            if (!uid) return;

            setLoading1(true); // Asumiendo que usas este estado para manejar la carga

            const userDocRef = doc(db, `users/${uid}`);
            const docSnap = await getDoc(userDocRef);

            if (docSnap.exists()) {
                const { chartsOrder_RepMT } = docSnap.data();
                if (chartsOrder_RepMT) {
                    // Reordenar `charts` basado en `chartsOrder_RepMT`
                    const orderedCharts = chartsOrder_RepMT.map(id => charts.find(chart => chart.id === id)).filter(Boolean);
                    setCharts(orderedCharts);
                }
            }

            setLoading1(false);
        };

        fetchChartOrder();
    }, []);

    useEffect(() => {
        const fetchAndInitializeChartOrderAndVisibility = async () => {
            const uid = auth.currentUser?.uid;
            if (!uid) return;

            const userDocRef = doc(db, `users/${uid}`);
            const docSnap = await getDoc(userDocRef);

            if (docSnap.exists() && docSnap.data().chartsOrder_RepMT && docSnap.data().chartsVisibility_RepMT) {
                // Si el documento existe y los campos chartsOrder_RepMT y chartsVisibility_RepMT también existen, establece el orden
                const { chartsOrder_RepMT, chartsVisibility_RepMT } = docSnap.data();
                const orderedAndVisibleCharts = chartsOrder_RepMT.map((id, index) => {
                    const chart = charts.find(chart => chart.id === id);
                    return chart ? { ...chart, visible: chartsVisibility_RepMT[index] } : null;
                }).filter(Boolean);
                setCharts(orderedAndVisibleCharts);
            } else {
                // Si no existen los campos, llama a saveInitialChartOrder para crearlos
                await saveInitialChartOrder();
            }
        };

        fetchAndInitializeChartOrderAndVisibility();
    }, []);



    useEffect(() => {
        document.title = 'Mis Tiendas | Kompa';

        const date = new Date();
        const hours = date.getHours();

        let properGreet = '';

        if (hours >= 0 && hours < 12) {
            properGreet = 'Buenos días';
        } else if (hours >= 12 && hours < 18) {
            properGreet = 'Buenas tardes';
        } else {
            properGreet = 'Buenas noches';
        }

        setProperGreet(properGreet);

    }, []);

    const renderChartContent = (chart) => {
        switch (chart.type) {
            case 'MostSoldProducts':
                return <MostSoldProducts store={store} />;
            case 'TrafficConversionChart':
                return <TrafficConversionChart store={store} />;
            case 'MeLiMetrics':
                return <MeLiMetrics store={store} />;
            case 'StorePerformanceChart':
                return <StorePerformanceChart store={store} />;
            case 'PeriodComparison':
                return <PeriodComparisonChart store={store} />;
            case 'AdMetricsChart':
                return <AdMetricsChart store={store} />;
            default:
                return null;
        }
    };

    const [lastScrollTop, setLastScrollTop] = useState(0);

    const handleScroll = (e) => {
        const scrollTop = e.target.scrollTop;  // Obtiene la posición actual del scroll
        if (scrollTop < lastScrollTop) {
            // scrollear hasta arriba el big-container, suavemente
            const bigContainer = document.getElementById('big-container');
            bigContainer.scrollTop = 0;

        }
        setLastScrollTop(scrollTop);  // Actualiza la última posición del scroll
    };

    useEffect(() => {
        if (loading1) return; // Asegúrate de que no se ejecute mientras se cargan los datos iniciales

        const contentToPdf = document.getElementById('content-to-pdf');
        contentToPdf.addEventListener('scroll', handleScroll);

        return () => {
            contentToPdf.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop, loading1]);  // Dependencias en las que el efecto depende

    if (loading1) {
        return <div className='h-screen flex justify-center items-center bg-gray-100'>
            <div className='spinner' />
        </div>;
    }

    return (
        <>
            <CustomMenu charts={charts} setCharts={setCharts} anchorPosition={anchorPosition} menuIsVisible={menuVisible} setMenuIsVisible={setMenuVisible} />

            <Menu
                id="dashboard-menu"
                anchorEl={dashboardAnchorEl}
                keepMounted
                open={Boolean(dashboardAnchorEl)}
                onClose={() => setDashboardAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <p className="mx-4 my-2 select-none text-kompamxblue text-sm font-semibold">
                    Ocultar o Reordenar Gráficas
                </p>

                <Divider />

                {charts.map((chart, index) => (
                    <MenuItem key={chart.id} onClick={() => {
                        // Actualizar la visibilidad del gráfico específico
                        const updatedCharts = charts.map((c, i) => {
                            if (i === index) {
                                return { ...c, visible: !c.visible };
                            }
                            return c;
                        });
                        setCharts(updatedCharts);
                    }}>
                        <div className='flex flex-row justify-start items-center w-full'>
                            <DragIndicator className='text-kompamxblue opacity-20 hover:opacity-100 transition-all duration-300' />
                            <div className='flex flex-row justify-start items-center'>
                                <Checkbox checked={chart.visible} />
                                {chart.label}
                            </div>
                        </div>
                    </MenuItem>
                ))}
            </Menu>
            <div id="big-container" style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}>

                <div id='content-to-pdf' className='flex flex-col justify-start items-center bg-gray-100 py-5 px-5' style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}>
                    {/* <div
                        className='flex flex-row justify-between items-center w-full mb-5'
                    >
                        <div className='justify-end items-end text-right mb-5 flex flex-row'>
                            <Tooltip title='Ocultar o Reordenar Gráficas' arrow>
                                <div
                                    className="text-kompamxblue cursor-pointer mx-2 my-2 flex flex-row rounded-full hover:bg-gray-200 transition-all duration-200 p-1"
                                    onClick={handleMenuClick}
                                >
                                    <Dashboard />
                                </div>
                            </Tooltip>
                        </div>

                    </div> */}

                    {screenWidth > 768 ? (
                        Array.from({
                            // Primero filtra los gráficos para incluir solo los visibles, luego calcula la longitud necesaria para el nuevo array basado en los gráficos visibles.
                            length: Math.ceil(charts.filter(chart => chart.visible).length / 2)
                        }).map((_, rowIndex) => {
                            // A partir de los gráficos visibles, obtén los que corresponden a esta fila en particular.
                            const rowCharts = charts
                                .filter(chart => chart.visible)
                                .slice(rowIndex * 2, rowIndex * 2 + 2);

                            return (
                                <div key={rowIndex} className='flex flex-row justify-center items-center w-full mb-4'>
                                    {rowCharts.map((chart, index) => (
                                        <div key={index}>
                                            {renderChartContent(chart)}
                                        </div>
                                    ))}
                                </div>
                            );
                        })
                    ) : (
                        charts.filter(chart => chart.visible).map((chart, index) => (
                            <div key={index} className='flex flex-row justify-center items-center w-full mb-4'>
                                {renderChartContent(chart)}
                            </div>
                        ))
                    )}
                </div>
                <Footer />
            </div>
        </>
    );
}
