import React from 'react';
import { formatCurrency } from '../utils';

const OrderDetailsModal = ({ isOpen, onClose, orders, storeName, date, metric }) => {
    if (!isOpen || !orders || !Array.isArray(orders)) return null;

    const formatDate = (dateStr) => {
        return new Date(dateStr).toLocaleDateString('es-ES', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] flex flex-col relative">
                {/* Header */}
                <div className="p-4 border-b">
                    <h2 className="text-lg font-semibold">
                        Pedidos de {storeName} - {formatDate(date)}
                    </h2>
                    <button
                        onClick={onClose}
                        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                    >
                        ✕
                    </button>
                </div>

                {/* Scrollable Content */}
                <div className="flex-1 overflow-auto p-4">
                    <div className="space-y-4">
                        {orders.map((order) => (
                            <div key={order.order_id} className="border rounded-lg p-4 bg-white shadow-sm">
                                <div className="flex justify-between items-start mb-2">
                                    <div className="font-medium">Pedido #{order.order_id}</div>
                                    <div className="text-right">
                                        <div className="font-semibold text-green-600">
                                            {formatCurrency(order.total_float)}
                                        </div>
                                        <div className="text-sm text-gray-500">
                                            {formatDate(order.date_timestamp)}
                                        </div>
                                    </div>
                                </div>

                                {order.items && order.items.length > 0 && (
                                    <div className="mt-3">
                                        <div className="text-sm font-medium mb-2">Productos:</div>
                                        <div className="space-y-2">
                                            {order.items.map((item) => (
                                                <div key={item.order_item_id} className="flex items-start p-2 bg-gray-50 rounded">
                                                    {item.thumbnail && (
                                                        <img
                                                            src={item.thumbnail}
                                                            alt={item.title}
                                                            className="w-12 h-12 object-cover rounded mr-3"
                                                        />
                                                    )}
                                                    <div className="flex-1">
                                                        <div className="text-sm">{item.title}</div>
                                                        <div className="text-xs text-gray-500 mt-1">
                                                            <span className="font-medium">
                                                                {formatCurrency(item.unit_price)} × {item.quantity}
                                                            </span>
                                                            {item.seller_sku && (
                                                                <span className="ml-2 text-gray-400">
                                                                    SKU: {item.seller_sku}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="text-right">
                                                        <div className="font-medium">
                                                            {formatCurrency(item.unit_price * item.quantity)}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderDetailsModal;