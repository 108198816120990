import React, { useState, useEffect } from 'react';
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';
import Select from 'react-select';
import { Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useTopbar } from '../../../helpers/TopbarContext';
import { useOrders } from '../../../helpers/OrdersContext';
import { useAds } from '../../../helpers/AdsContext';
import { options, getColor, renderLegend, currencyFormatter } from './utils';

const AdMetricsChart = ({ store, selectedOptionProp }) => {
    const [selectedOption, setSelectedOption] = useState(selectedOptionProp || options[0]);
    const [chartWidth, setChartWidth] = useState(window.innerWidth < 768 ? (window.innerWidth - 15) : ((window.innerWidth) * 0.5) - 50);
    const [selectedStore, setSelectedStore] = useState(store);
    const [chartData, setChartData] = useState([]);

    const { selectedChartInterval } = useTopbar();
    const { ordersData, stores, loading: ordersLoading } = useOrders();
    const { loading: adsLoading, adsInfo } = useAds();

    useEffect(() => {
        if (selectedOptionProp) setSelectedOption(selectedOptionProp);
    }, [selectedOptionProp]);

    useEffect(() => {
        if (store) {
            setSelectedStore(store);
        } else if (stores.length > 0 && !selectedStore) {
            setSelectedStore(stores[0]);
        }
    }, [store, stores]);

    useEffect(() => {
        const handleResize = () => {
            setChartWidth(window.innerWidth < 768 ? (window.innerWidth - 15) : ((window.innerWidth) * 0.5) - 50);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const aggregateData = (adsData, ordersData, interval) => {
        const aggregated = {};

        // Aggregate ads data
        adsData?.forEach(item => {
            let key;
            const date = new Date(item.date);

            switch (interval) {
                case 'SEMANA':
                    const day = date.getDay();
                    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
                    key = new Date(date.setDate(diff)).toISOString().split('T')[0];
                    break;
                case 'MES':
                    key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
                    break;
                case 'ANO':
                    key = `${date.getFullYear()}`;
                    break;
                default: // 'DIA'
                    key = item.date;
            }

            if (!aggregated[key]) {
                aggregated[key] = {
                    date: key,
                    adSales: 0,
                    adSpend: 0,
                    organicSales: 0,
                    impressions: 0,
                    clicks: 0
                };
            }

            aggregated[key].adSales += item.sales || 0;
            aggregated[key].adSpend += item.cost || 0;
            aggregated[key].impressions += item.impressions || 0;
            aggregated[key].clicks += item.clicks || 0;
        });

        // Aggregate orders data
        ordersData?.forEach(order => {
            const orderDate = new Date(order.date_timestamp);
            let key;

            switch (interval) {
                case 'SEMANA':
                    const day = orderDate.getDay();
                    const diff = orderDate.getDate() - day + (day === 0 ? -6 : 1);
                    key = new Date(orderDate.setDate(diff)).toISOString().split('T')[0];
                    break;
                case 'MES':
                    key = `${orderDate.getFullYear()}-${String(orderDate.getMonth() + 1).padStart(2, '0')}`;
                    break;
                case 'ANO':
                    key = `${orderDate.getFullYear()}`;
                    break;
                default: // 'DIA'
                    key = orderDate.toISOString().split('T')[0];
            }

            if (!aggregated[key]) {
                aggregated[key] = {
                    date: key,
                    adSales: 0,
                    adSpend: 0,
                    organicSales: 0,
                    impressions: 0,
                    clicks: 0
                };
            }

            aggregated[key].organicSales += order.total_float;
        });

        // Calculate derived metrics
        Object.values(aggregated).forEach(item => {
            const totalSales = item.adSales + item.organicSales;
            item.acos = item.adSales > 0 ? (item.adSpend / item.adSales) * 100 : 0;
            item.roas = item.adSpend > 0 ? (item.adSales / item.adSpend) : 0;
            item.tacos = totalSales > 0 ? (item.adSpend / totalSales) * 100 : 0;
            item.organicSales = Math.max(0, item.organicSales - item.adSales);
        });

        return Object.values(aggregated).sort((a, b) => new Date(a.date) - new Date(b.date));
    };

    useEffect(() => {
        if (!selectedStore || !adsInfo || adsLoading || ordersLoading) return;

        const storeOrders = ordersData[selectedStore.value]?.currentPeriod || [];
        const storeAdsData = adsInfo[selectedStore.value] || [];

        const processedData = aggregateData(storeAdsData, storeOrders, selectedChartInterval.value);
        setChartData(processedData);
    }, [selectedStore, ordersData, adsInfo, selectedChartInterval, adsLoading, ordersLoading]);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="bg-white p-3 border border-gray-300 shadow-md">
                    <p className="label">{formatLabel(label)}</p>
                    <ul className="list-disc list-inside">
                        {payload.map((entry, index) => (
                            <li key={`item-${index}`} style={{ color: entry.color }}>
                                <span className='text-kompamxblue'>
                                    {entry.name === 'adSales' ? 'Ventas de publicidad'
                                        : entry.name === 'organicSales' ? 'Ventas orgánicas'
                                            : entry.name === 'acos' ? 'ACoS'
                                                : entry.name === 'tacos' ? 'TACoS'
                                                    : entry.name === 'adSpend' ? 'Gasto en publicidad'
                                                        : 'ROAS'}
                                </span>
                                <span className='text-kompamxblue font-semibold'>
                                    : {entry.name === 'acos' || entry.name === 'tacos' || entry.name === 'roas'
                                        ? `${entry.value.toFixed(2)}%`
                                        : currencyFormatter(entry.value)}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }
        return null;
    };

    const formatLabel = (label) => {
        const date = new Date(label);
        switch (selectedChartInterval.value) {
            case 'SEMANA':
                return `${date.toLocaleDateString('es-ES', { day: '2-digit', month: 'short', year: 'numeric' })}`;
            case 'MES':
                return date.toLocaleDateString('es-ES', { month: 'long', year: 'numeric' });
            case 'ANO':
                return date.getFullYear().toString();
            default: // 'DIA'
                return date.toLocaleDateString('es-ES', { day: '2-digit', month: 'short', year: 'numeric' });
        }
    };

    return (
        <div className='flex flex-col items-center justify-center bg-white rounded-lg shadow-md m-1' style={{ minWidth: chartWidth, maxWidth: chartWidth, height: 400 }}>
            <div className="flex flex-row items-center px-5 text-kompamxblue text-base justify-start w-full" style={{ minHeight: "3.5rem" }}>
                <div className="font-semibold mr-2 whitespace-nowrap">
                    {store ? `${selectedOption.label} de ${store.data.custom_name}` : `Rendimiento de ${selectedOption.label}`}
                </div>
                <Tooltip arrow title={`El gráfico muestra el rendimiento diario de la publicidad basado en la métrica ${selectedOption.label}`}>
                    <InfoOutlined style={{ fontSize: 18 }} className='ml-auto mr-2' />
                </Tooltip>
                <div className='flex flex-row'>
                    {!store &&
                        <Select
                            options={stores}
                            className='mr-2'
                            placeholder='Selecciona una tienda'
                            value={selectedStore}
                            onChange={setSelectedStore}
                        />
                    }
                    {!selectedOptionProp &&
                        <Select
                            className=''
                            value={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                        />
                    }
                </div>
            </div>

            <hr className="w-full border-gray-300" />

            {adsLoading || ordersLoading ? (
                <div className="flex justify-center items-center h-full w-full">
                    <div className="spinner" />
                </div>
            ) : chartData && chartData.length > 0 ? (
                <div className="flex flex-col items-center justify-center w-full h-full">
                    <ResponsiveContainer>
                        <ComposedChart
                            data={chartData}
                            margin={{ top: 30, right: 30, bottom: 30, left: 50 }}
                        >
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis
                                dataKey="date"
                                tickFormatter={(tick) => formatLabel(tick)}
                                angle={-35}
                                textAnchor="end"
                                height={60}
                            />
                            <YAxis yAxisId="left" orientation="left" tickFormatter={currencyFormatter} />
                            <YAxis yAxisId="right" orientation="right" tickFormatter={(value) => `${value.toFixed(2)}%`} />
                            <RechartsTooltip content={<CustomTooltip />} />
                            <Legend content={renderLegend} align="left" verticalAlign="top" layout="horizontal" />
                            <Bar yAxisId="left" dataKey="adSales" stackId="a" fill="#205096" />
                            <Bar yAxisId="left" dataKey="organicSales" stackId="a" fill="#757575" />
                            <Line
                                yAxisId={selectedOption.value === 'adSpend' ? 'left' : 'right'}
                                type="linear"
                                dataKey={selectedOption.value}
                                stroke={getColor(selectedOption.value)}
                                dot={false}
                                strokeWidth={2}
                            />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            ) : (
                <div className="flex justify-center items-center h-full w-full">
                    <p className="text-gray-500 text-lg">No hay datos para mostrar</p>
                </div>
            )}
        </div>
    );
};

export default AdMetricsChart;