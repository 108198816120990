import React from 'react';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';

const animatedComponents = makeAnimated();

const NoOptionsMessage = props => (
    <components.NoOptionsMessage {...props}>
        <span className="text-gray-400">
            No hay más tiendas para seleccionar.
        </span>
    </components.NoOptionsMessage>
);

const selectStyle = (color) => ({
    control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? `${color} !important` : 'inherit',
        boxShadow: state.isFocused ? `0px 0px 0px 1px ${color}` : 'none',
    })
});

export const StoreSelect = ({ selectRef, stores, selectedStores, setSelectedStores }) => {
    const handleSelectStores = (selectedOptions) => {
        if (selectedOptions.length < 2) {
            toast.error("Selecciona al menos dos tiendas.");
            return;
        }
        if (selectedOptions.length > 4) {
            toast.error("No puedes seleccionar más de cuatro tiendas.");
            return;
        }
        setSelectedStores(selectedOptions);
    };

    return (
        <div className="flex items-center w-full justify-center px-2 py-2" ref={selectRef}>
            <Select
                components={{ ...animatedComponents, NoOptionsMessage }}
                isMulti
                options={stores}
                value={selectedStores}
                onChange={handleSelectStores}
                placeholder="Selecciona tiendas"
                isClearable={false}
                styles={{
                    ...selectStyle(),
                    multiValue: (styles) => ({ ...styles, backgroundColor: '#f0f0f0' }),
                    multiValueLabel: (styles) => ({ ...styles, color: 'black' }),
                    multiValueRemove: (styles) => ({
                        ...styles,
                        color: 'black',
                        ':hover': { backgroundColor: 'red', color: 'white' }
                    }),
                }}
            />
        </div>
    );
};
