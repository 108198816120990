import React, { useMemo, useState } from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, Cell, ReferenceLine } from 'recharts';
import Select from 'react-select';
import { InfoOutlined } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { useTopbar } from '../../helpers/TopbarContext';
import { useOrders } from '../../helpers/OrdersContext';
import { useAds } from '../../helpers/AdsContext';

const StorePerformanceChart = ({ store, onlyAdsSales }) => {
    const {
        startDate,
        endDate,
        selectedChartInterval
    } = useTopbar();

    const { ordersData, stores, loading: ordersLoading } = useOrders();
    const { adsInfo, loading: adsLoading } = useAds();

    // Component state
    const [selectedStore, setSelectedStore] = useState(null);
    const [chartWidth, setChartWidth] = useState(window.innerWidth < 768 ? (window.innerWidth - 15) : ((window.innerWidth) * 0.5) - 50);

    // Update selected store when prop changes or stores load
    React.useEffect(() => {
        if (store) {
            setSelectedStore(store);
        } else if (stores?.length > 0 && !selectedStore) {
            setSelectedStore(stores[0]);
        }
    }, [store, stores]);

    // Handle window resize
    React.useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setChartWidth((window.innerWidth - 15));
                return;
            }
            const newChartWidth = ((window.innerWidth * 0.5) - 50);
            setChartWidth(newChartWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Process data for the chart
    const data = useMemo(() => {
        if (!selectedStore || !ordersData || !adsInfo) return [];

        const storeOrders = ordersData[selectedStore.value]?.currentPeriod || [];
        const storeAds = adsInfo[selectedStore.value] || [];

        // Create a map of dates for easy lookup
        const dateMap = new Map();

        // Process orders data
        storeOrders.forEach(order => {
            const date = order.date_timestamp.toISOString().split('T')[0];
            if (!dateMap.has(date)) {
                dateMap.set(date, {
                    name: date,
                    ventas: 0,
                    publicidad: 0,
                    ganancia: 0
                });
            }
            const entry = dateMap.get(date);
            entry.ventas += onlyAdsSales ? 0 : order.total_float;
        });

        // Process ads data
        storeAds.forEach(ad => {
            if (!dateMap.has(ad.date)) {
                dateMap.set(ad.date, {
                    name: ad.date,
                    ventas: 0,
                    publicidad: 0,
                    ganancia: 0
                });
            }
            const entry = dateMap.get(ad.date);
            entry.publicidad += ad.cost || 0;
            if (onlyAdsSales) {
                entry.ventas += ad.sales || 0;
            }
            entry.ganancia = entry.ventas - entry.publicidad;
        });

        // Convert map to array and sort by date
        return Array.from(dateMap.values())
            .sort((a, b) => new Date(a.name) - new Date(b.name));
    }, [selectedStore, ordersData, adsInfo, onlyAdsSales]);

    // Calculate total profit
    const totalGanancia = useMemo(() =>
        data.reduce((acc, curr) => acc + curr.ganancia, 0),
        [data]);

    // Custom components
    const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
        const date = new Date(payload.value);
        let formattedDate;
        switch (selectedChartInterval.value) {
            case 'SEMANA':
                formattedDate = `Sem ${date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit' })}`;
                break;
            case 'MES':
                formattedDate = date.toLocaleDateString('es-ES', { month: 'short', year: 'numeric' });
                break;
            case 'ANO':
                formattedDate = date.getFullYear().toString();
                break;
            default: // 'DIA'
                formattedDate = date.toLocaleDateString('es-ES', { day: '2-digit', month: 'short' });
        }
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                    {formattedDate}
                </text>
            </g>
        );
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const date = new Date(label);
            let formattedDate;
            switch (selectedChartInterval.value) {
                case 'SEMANA':
                    formattedDate = `Semana del ${date.toLocaleDateString('es-ES', { day: '2-digit', month: 'long', year: 'numeric' })}`;
                    break;
                case 'MES':
                    formattedDate = date.toLocaleDateString('es-ES', { month: 'long', year: 'numeric' });
                    break;
                case 'ANO':
                    formattedDate = date.getFullYear().toString();
                    break;
                default: // 'DIA'
                    formattedDate = date.toLocaleDateString('es-ES', { day: '2-digit', month: 'long', year: 'numeric' });
            }
            return (
                <div style={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '10px' }}>
                    <p>{formattedDate}</p>
                    {payload.map((entry, index) => (
                        <p key={`item-${index}`} style={{ color: entry.dataKey === 'ganancia' ? (entry.value < 0 ? '#E43631' : '#48A047') : entry.color }}>
                            {`${entry.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}: ${entry.value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    const renderLegend = (props) => {
        const { payload } = props;
        return (
            <div style={{ margin: '0 auto', textAlign: 'right' }}>
                <ul style={{ listStyle: 'none', marginLeft: 10, padding: 0 }}>
                    {payload.map((entry, index) => (
                        <li key={`item-${index}`} style={{ color: entry.value == 'ganancia' ? '#48A047' : entry.color, marginBottom: 10, fontSize: 12, display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <svg width="20" height={entry.value == 'ganancia' ? "20" : "5"} style={{ verticalAlign: 'middle', marginRight: 5 }}>
                                <rect width="20" height={entry.value == 'ganancia' ? "20" : "5"} fill={entry.value == 'ganancia' ? '#48A047' : entry.color} />
                            </svg>
                            <span className='text-gray-500'>
                                {entry.value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const loading = ordersLoading || adsLoading;

    return (
        <div className='flex flex-col items-start justify-start bg-white rounded-lg shadow-md m-1' style={{ minWidth: chartWidth, maxWidth: chartWidth, height: 400 }}>
            <div className="flex flex-col md:flex-row items-center px-5 text-kompamxblue text-base justify-center md:justify-between w-full" style={{ minHeight: "3.5rem" }}>
                <div className="flex items-center justify-start w-full">
                    <div className="flex flex-row items-center justify-start w-full">
                        <div className="font-semibold mr-2 lg:whitespace-nowrap">
                            Rentabilidad {!onlyAdsSales ? 'General' : 'de Publicidad'}
                        </div>
                        <Tooltip arrow title={'Selecciona una tienda para ver sus métricas de rentabilidad y tendencias de costos'}>
                            <InfoOutlined style={{ fontSize: 18 }} className='ml-auto mr-2' />
                        </Tooltip>
                    </div>
                    {!store &&
                        <Select
                            options={stores}
                            className=''
                            placeholder='Selecciona una tienda'
                            value={selectedStore}
                            onChange={setSelectedStore}
                        />
                    }
                </div>
            </div>
            <hr className="w-full border-gray-300 mb-4" />
            {loading ? (
                <div className='h-full w-full flex justify-center items-center'>
                    <div className="spinner" />
                </div>
            ) : !selectedStore ? (
                <div className="flex justify-center items-center h-full w-full">
                    <p className="text-gray-500 text-lg">No hay datos para mostrar</p>
                </div>
            ) : data.length === 0 ? (
                <div className="flex justify-center items-center h-full w-full">
                    <p className="text-gray-500 text-lg">No hay datos para mostrar</p>
                </div>
            ) : (
                <div className='flex flex-row items-center justify-between w-full pb-3 h-full'>
                    <ComposedChart
                        width={chartWidth - 140}
                        height={300}
                        data={data}
                        margin={{ top: 20, bottom: 40, left: 20 }}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="name" tick={<CustomizedAxisTick />} />
                        <YAxis tickFormatter={(value) => value.toLocaleString('en-US', { style: 'currency', currency: "USD", minimumFractionDigits: 0 })} />
                        <RechartsTooltip content={<CustomTooltip />} />
                        <Bar dataKey="ganancia" barSize={20}>
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.ganancia < 0 ? '#E43631' : '#48A047'} />
                            ))}
                        </Bar>
                        <Line type="linear" dataKey="ventas" stroke="#1E6BB9" strokeWidth={2} dot={false} />
                        <Line type="linear" dataKey="publicidad" stroke="#F4A240" strokeWidth={2} dot={false} />
                        <ReferenceLine y={0} stroke="#CCCCCC" strokeDasharray="3 3" />
                        <Legend content={renderLegend} align="right" verticalAlign="middle" layout="vertical" />
                    </ComposedChart>
                    <div className='flex flex-col items-start justify-start'>
                        <h2 className='text-kompamx-800 text-xl uppercase font-bold w-32 mb-2'>
                            Ganancia Total
                        </h2>
                        <h1 className='text-kompamx-800 text-2xl font-bold mb-2'>
                            {totalGanancia.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                        </h1>
                        <h3 className='text-gray-500 text-md'>
                            MXN
                        </h3>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StorePerformanceChart;