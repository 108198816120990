import React from 'react';
import logoMeLi from '../../../../../assets/ml_trans.png';
import az from '../../../../../assets/merchantspring/az.jpg';

export const CustomTooltip = ({ active, payload, label, metric }) => {
    if (!active || !payload || !payload.length) return null;

    const formatCurrency = (value) => {
        if (typeof value !== 'number') return '$0.00';
        return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        });
    };

    const formatValue = (value, metric) => {
        if (typeof value !== 'number') return '0';
        if (metric === "Ventas" || metric === "AOV" || metric === "TotalAcumulado") {
            return formatCurrency(value);
        }
        return value.toLocaleString();
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return '';

        try {
            return date.toLocaleDateString('es-ES', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
        } catch (error) {
            return dateString;
        }
    };

    const getLogo = (storeId = '') => {
        if (storeId.includes('az')) {
            return az;
        }
        return logoMeLi;
    };

    return (
        <div className="bg-white p-3 border border-gray-200 shadow-lg rounded">
            <p className="font-medium mb-2 border-b border-gray-200 pb-2">
                {formatDate(label)}
            </p>
            <div className="space-y-2">
                {payload.map((entry, index) => (
                    <div key={`item-${index}`} className="flex items-center gap-2">
                        <img
                            src={getLogo(entry.dataKey)}
                            alt="logo"
                            className="w-5 h-5"
                        />
                        <div className="flex items-center gap-2">
                            <span style={{ color: entry.color || '#000' }} className="font-medium">
                                {entry.name}:
                            </span>
                            <span className="font-medium">
                                {formatValue(entry.value, metric)}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div >
    );
};

export default CustomTooltip;