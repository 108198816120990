import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import useAuth from './hooks/useAuth';
import useStores from './hooks/useStores';
import { TopbarProvider } from './helpers/TopbarContext';

// Components
import TopBar from './components/TopBar';
import Sidebar from './components/Sidebar';
import { PaymentOptions } from './components/payment/PaymentOptions';
import StripeCheckoutWrapper from './components/payment/StripeCheckoutWrapper';

// Main Pages
import Tablero from './pages/sidebar/Tablero';
import Informes from './pages/Informes';
import AgregarTienda from './pages/AgregarTienda';
import AmazonConnect from './pages/(auth)/old/AmazonConnect';
import MercadoLibreConnect from './pages/(auth)/old/MercadoLibreConnect';
import Ads from './pages/Ads';
import RelacionarTiendas from './pages/RelacionarTiendas';
import Chat from './pages/Chat';
import TaskViewer from './pages/TaskViewer';
import OnBoarding from './pages/OnBoarding';
import NotFound from './pages/NotFound';
import IniciarSesion from './pages/(auth)/IniciarSesion';
import Registro from './pages/(auth)/Registro';

// Store Management Pages
import GraficosYAnalisisMT from './pages/sidebar/mistiendas/GraficosYAnalisisMT';
import MetricasDeReputacionMT from './pages/sidebar/mistiendas/MetricasDeReputacionMT';
import TablaDeProductosMT from './pages/sidebar/mistiendas/TablaDeProductosMT';

// Comparison Tool Pages
import ComparativaDeProductosC from './pages/sidebar/comparador/ComparativaDeProductosC';
import GraficosYAnalisisC from './pages/sidebar/comparador/GraficosYAnalisisC';
import GraficosPorProductoC from './pages/sidebar/comparador/GraficosPorProductoC';

// Styles
import "./helpers/spinner.css";
import './helpers/App.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/tailwind.css';
import './styles/fonts.css';
import StoreComparisonChart from './components/charts/bar/StoreComparison/index';
import { OrdersProvider } from './helpers/OrdersContext';
import { AdsProvider } from './helpers/AdsContext';
import { TestProvider } from './helpers/TestContext';
import { TrafficProvider } from './helpers/TrafficContext';

function RootLayout({ children }) {
  return (
    <div className="flex h-full flex-col bg-white antialiased">
      {children}
    </div>
  );
}

function App() {
  return (
    <>
      <Helmet>
        <html lang="es" className="h-full scroll-smooth" />
        <title>Kompa - Tu socio inteligente en e-commerce</title>
        <meta name="description" content="Datos que impulsan tu negocio: conoce, analiza y crece con Kompa. Tu socio inteligente en e-commerce." />
      </Helmet>
      <RootLayout>
        <Router>
          <TestProvider>
            <TopbarProvider>
              <OrdersProvider>
                <AdsProvider>
                  <TrafficProvider>
                    <MainComponent />
                  </TrafficProvider>
                </AdsProvider>
              </OrdersProvider>
            </TopbarProvider>
          </TestProvider>
        </Router>
      </RootLayout>
    </>
  );
}

function MainComponent() {
  const location = useLocation();
  const [store, setStore] = useState(null);
  const { stores, loadingStores } = useStores();
  const { loadingUser, currentUser } = useAuth();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Route definitions for navigation control
  const publicRoutes = ['/iniciar-sesion', '/registro', '/on-boarding', '/opciones-pago', '/pago'];
  const connectRoutes = ['/agregar-tienda', '/amazon-connect', '/ml-connect'];
  const currentPath = location.pathname;

  // Updated navigation control logic
  const shouldShowNavigation = () => {
    const validSubscriptions = ['free-trial', 'premium'];
    // console.log(currentUser?.subscription_type, !publicRoutes.includes(currentPath), !connectRoutes.includes(currentPath), validSubscriptions.includes(currentUser?.subscription_type), stores.length > 0);
    // console.log(currentUser?.subscription_type, !publicRoutes.includes(currentPath), !connectRoutes.includes(currentPath), validSubscriptions.includes(currentUser?.subscription_type), stores.length > 0);
    return currentUser &&
      currentUser?.subscription_type && // Check if subscription_type exists
      !publicRoutes.includes(currentPath) &&
      validSubscriptions.includes(currentUser?.subscription_type) &&
      stores.length > 0;
  };

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (loadingUser || loadingStores) {
    return (
      <div className="flex justify-center items-center h-screen w-screen bg-gray-100">
        <div className="spinner" />
      </div>
    );
  }

  // New redirect logic for undefined subscription
  if (currentUser && (!currentUser?.subscription_type || currentUser?.subscription_type === undefined) &&
    currentPath !== '/opciones-pago' && currentPath !== '/pago') {
    return <Navigate to="/opciones-pago" replace />;
  }

  // Existing redirect logic
  if (currentUser?.subscription_type === 'expired' && currentPath !== '/pago') {
    return <Navigate to="/pago" replace />;
  }

  if (currentUser && stores.length === 0 && !connectRoutes.includes(currentPath) && !publicRoutes.includes(currentPath)) {
    return <Navigate to="/agregar-tienda" replace />;
  }

  return (
    <>
      <div className="flex">

        {shouldShowNavigation() && (
          <div>
            <Sidebar />
          </div>
        )}
        <div className={`flex-grow ${screenWidth > 768 && shouldShowNavigation() ? 'pl-[74px]' : ''}`}>
          {shouldShowNavigation() && (
            <TopBar
              store={store}
              setStore={setStore}
            />
          )}

          <Routes>
            <Route path="/comparador-de-tiendas" element={<StoreComparisonChart metricProp={"Ventas"} />} />

            {/* Public Routes */}
            {(!currentUser) ?
              <Route path="/" element={<OnBoarding />} /> :
              <Route path="/" element={
                !currentUser?.subscription_type ?
                  <Navigate to="/opciones-pago" replace /> :
                  <Tablero stores={stores} />
              } />
            }
            <Route path="/iniciar-sesion" element={
              currentUser ?
                <Navigate to={
                  !currentUser?.subscription_type ?
                    "/opciones-pago" :
                    stores.length > 0 ? "/tablero" : "/agregar-tienda"
                } replace /> :
                <IniciarSesion />
            } />
            <Route path="/registro" element={
              currentUser ?
                <Navigate to={
                  !currentUser?.subscription_type ?
                    "/opciones-pago" :
                    stores.length > 0 ? "/tablero" : "/agregar-tienda"
                } replace /> :
                <Registro />
            } />
            <Route path="/on-boarding" element={<OnBoarding />} />

            {/* Payment Routes */}
            <Route path="/opciones-pago" element={
              !currentUser ? <Navigate to="/iniciar-sesion" replace /> : <PaymentOptions />
            } />
            <Route path="/pago" element={
              !currentUser ? <Navigate to="/iniciar-sesion" replace /> : <StripeCheckoutWrapper />
            } />

            {/* Protected Routes */}
            {currentUser && currentUser?.subscription_type && (
              <>
                {/* Store Connection Routes */}
                <Route path="/agregar-tienda" element={<AgregarTienda />} />
                <Route path="/amazon-connect" element={<AmazonConnect />} />
                <Route path="/ml-connect" element={<MercadoLibreConnect />} />

                {/* Main Application Routes */}
                {stores.length > 0 && (
                  <>
                    <Route path="/tablero" element={<Tablero stores={stores} />} />
                    <Route path="/informes" element={<Informes />} />
                    <Route path="/relacionar-tiendas" element={<RelacionarTiendas />} />
                    <Route path="/ads" element={<Ads />} />
                    <Route path="/tasks" element={<TaskViewer />} />
                    <Route path="/aichat" element={<Chat />} />

                    {/* Store Management Routes */}
                    <Route path="/mis-tiendas/graficos" element={<GraficosYAnalisisMT store={store} />} />
                    <Route path="/mis-tiendas/tabla-de-productos" element={<TablaDeProductosMT store={store} />} />
                    <Route path="/mis-tiendas/metricas-de-reputacion" element={<MetricasDeReputacionMT store={store} />} />

                    {/* Comparison Tool Routes */}
                    <Route path="/comparador/graficos" element={<GraficosYAnalisisC />} />
                    <Route path="/comparador/comparativa-de-productos" element={<ComparativaDeProductosC />} />
                    <Route path="/comparador/graficos-por-producto" element={<GraficosPorProductoC />} />
                  </>
                )}
              </>
            )}

            {/* 404 Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;