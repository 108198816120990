import React, { useState, useEffect } from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, Cell, ReferenceLine } from 'recharts';
import Select from 'react-select';
import { InfoOutlined } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { useTopbar } from '../../helpers/TopbarContext';
import { useTraffic } from '../../helpers/TrafficContext';

const TrafficConversionChart = ({ store }) => {
    const [selectedStore, setSelectedStore] = useState(store);
    const [chartWidth, setChartWidth] = useState(window.innerWidth < 768 ? (window.innerWidth - 15) : ((window.innerWidth * 0.5) - 50));

    const { selectedChartInterval } = useTopbar();
    const { trafficData, stores, loading } = useTraffic();

    useEffect(() => {
        if (store) {
            setSelectedStore(store);
        } else if (!selectedStore && stores.length > 0) {
            setSelectedStore(stores[0]);
        }
    }, [store, stores, selectedStore]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setChartWidth((window.innerWidth - 15));
                return;
            }
            const newChartWidth = (window.innerWidth * 0.5) - 50;
            setChartWidth(newChartWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const data = selectedStore && trafficData[selectedStore.value]?.aggregatedData || [];
    const totalViews = data.reduce((sum, item) => sum + item.pageviews, 0);
    const totalSales = data.reduce((sum, item) => sum + item.sales, 0);
    const averageConversion = totalViews > 0
        ? ((totalSales / totalViews) * 100).toFixed(2)
        : '0.00';

    const CustomizedAxisTick = ({ x, y, payload }) => {
        const date = new Date(payload.value);
        let formattedDate;
        switch (selectedChartInterval?.value) {
            case 'SEMANA':
                formattedDate = `Sem ${date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit' })}`;
                break;
            case 'MES':
                formattedDate = date.toLocaleDateString('es-ES', { month: 'short', year: 'numeric' });
                break;
            case 'ANO':
                formattedDate = date.getFullYear().toString();
                break;
            default: // 'DIA'
                formattedDate = date.toLocaleDateString('es-ES', { day: '2-digit', month: 'short' });
        }
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                    {formattedDate}
                </text>
            </g>
        );
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const date = new Date(label);
            let formattedDate;
            switch (selectedChartInterval?.value) {
                case 'SEMANA':
                    formattedDate = `Semana del ${date.toLocaleDateString('es-ES', { day: '2-digit', month: 'long', year: 'numeric' })}`;
                    break;
                case 'MES':
                    formattedDate = date.toLocaleDateString('es-ES', { month: 'long', year: 'numeric' });
                    break;
                case 'ANO':
                    formattedDate = date.getFullYear().toString();
                    break;
                default: // 'DIA'
                    formattedDate = date.toLocaleDateString('es-ES', { day: '2-digit', month: 'long', year: 'numeric' });
            }
            return (
                <div className="bg-white border border-gray-200 p-4 shadow-lg rounded">
                    <p className="font-semibold">{formattedDate}</p>
                    {payload.map((entry, index) => (
                        <p key={`item-${index}`}
                            style={{ color: entry.dataKey === 'pageviews' ? barColor : entry.color }}>
                            {`${entry.name === 'pageviews' ? 'Vistas' : 'Tasa de Conversión'}: ${entry.dataKey === 'pageviews'
                                ? entry.value.toLocaleString('en-US')
                                : entry.value.toFixed(2)}${entry.dataKey === 'conversion'
                                    ? `% (${payload[1].payload.sales.toLocaleString('en-US')} ${payload[1].payload.sales === 1 ? 'venta' : 'ventas'})`
                                    : ''
                                }`}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    const renderLegend = (props) => {
        const { payload } = props;
        return (
            <div className="mx-auto text-center">
                <ul className="list-none p-0 flex justify-left mb-6 flex-wrap">
                    {payload.map((entry, index) => (
                        <li key={`item-${index}`}
                            className="mb-2 text-xs flex items-center mr-4">
                            <svg width="20" height={entry.value === 'pageviews' ? "20" : "5"}
                                className="align-middle mr-2">
                                <rect width="20"
                                    height={entry.value === 'pageviews' ? "20" : "5"}
                                    fill={entry.value === 'pageviews' ? barColor : entry.color} />
                            </svg>
                            <span className="text-kompamxblue text-xs">
                                {entry.value === 'pageviews'
                                    ? `Vistas (${totalViews.toLocaleString('en-US')} vistas)`
                                    : `Tasa de Conversión (${averageConversion}%)`}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const barColor = ((selectedStore && selectedStore.data.color) || '#1E6BB9');

    return (
        <div className="flex flex-col items-start justify-start bg-white rounded-lg shadow-md m-1"
            style={{ minWidth: chartWidth, maxWidth: chartWidth, height: 400 }}>
            <div className="flex flex-col md:flex-row items-center px-5 text-kompamxblue text-base justify-center md:justify-between w-full"
                style={{ minHeight: "3.5rem" }}>
                <div className="flex items-center justify-start w-full">
                    <div className="font-semibold mr-2">
                        Tendencia de Tráfico y Conversión
                    </div>
                    <Tooltip arrow title="Muestra la tendencia de tráfico y la tasa de conversión de la tienda seleccionada.">
                        <InfoOutlined style={{ fontSize: 18 }} />
                    </Tooltip>
                    {!store && (
                        <Select
                            className="ml-4 w-64"
                            options={stores}
                            value={selectedStore}
                            onChange={setSelectedStore}
                            placeholder="Selecciona una tienda"
                            isSearchable={false}
                        />
                    )}
                </div>
            </div>
            <hr className="w-full border-gray-300 mb-2" />

            <div className="flex items-center md:mt-0 w-full justify-center px-2 mb-2 h-full">
                {loading ? (
                    <div className="spinner" />
                ) : selectedStore ? (
                    <ComposedChart
                        width={chartWidth - 40}
                        height={300}
                        data={data}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 40,
                            bottom: 25
                        }}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="name" tick={<CustomizedAxisTick />} />
                        <YAxis />
                        <YAxis yAxisId="right" orientation="right" tickFormatter={(value) => `${value}%`} />
                        <RechartsTooltip content={<CustomTooltip />} />
                        <Legend content={renderLegend} align="left" verticalAlign="top" layout="horizontal" />

                        <Bar dataKey="pageviews" barSize={20}>
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={barColor} />
                            ))}
                        </Bar>
                        <Line
                            yAxisId="right"
                            type="linear"
                            dataKey="conversion"
                            stroke="#1E6BB9"
                            strokeWidth={2}
                            dot={false}
                        />

                        <ReferenceLine y={0} stroke="#CCCCCC" strokeDasharray="3 3" />
                    </ComposedChart>
                ) : (
                    <div className="flex justify-center items-center h-full w-full">
                        <p className="text-gray-500 text-lg">Selecciona una tienda para ver los datos</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TrafficConversionChart;