// Helper function to format currency
const formatCurrency = (value) => {
    if (!value) return;
    return value.toLocaleString('es-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
};

const getInitials = (name) => {
    return name
        .split(' ')
        .map(word => word[0])
        .join('. ');
};


export { formatCurrency, getInitials };