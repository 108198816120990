import React, { useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';

export const CustomLegend = ({
    payload,
    legendRef,
    metric,
    totalsByStore,
    selectedStores
}) => {
    const [containerWidth, setContainerWidth] = useState(window.innerWidth);
    const [displayMode, setDisplayMode] = useState('full');

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setContainerWidth(width);

            // Determine display mode based on width
            if (width < 400) {
                setDisplayMode('none');
            } else if (width < 600) {
                setDisplayMode('initials');
            } else {
                setDisplayMode('full');
            }
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getInitials = (name) => {
        if (!name) return '';
        return name
            .split(' ')
            .map(word => word[0])
            .join('')
            .toUpperCase();
    };

    const formatNumber = (number, useRounding) => {
        if (number == null) return '0';

        try {
            if (useRounding) {
                return Math.round(number).toLocaleString();
            } else {
                if (number % 1 === 0) {
                    return number.toLocaleString();
                }
                return number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }
        } catch (error) {
            console.error('Error formatting number:', error);
            return '0';
        }
    };

    const getTotal = (dataKey) => {
        if (!totalsByStore || !dataKey) return 0;
        return totalsByStore[dataKey] || 0;
    };

    const getStoreName = (dataKey) => {
        const matchedStore = selectedStores?.find(store => store.value === dataKey);
        if (matchedStore) {
            return matchedStore.data?.custom_name ||
                matchedStore.data?.name ||
                matchedStore.value;
        }
        return dataKey;
    };

    if (!Array.isArray(payload)) {
        return null;
    }

    return (
        <div className="flex justify-center items-center p-2" ref={legendRef}>
            <ul className="flex list-none p-0">
                {payload.map((entry, index) => {
                    const fullStoreName = getStoreName(entry.dataKey);
                    const total = getTotal(entry.dataKey);

                    // Determine display name based on display mode
                    let displayName;
                    if (displayMode === 'none') {
                        displayName = '';
                    } else if (displayMode === 'initials') {
                        displayName = getInitials(fullStoreName);
                    } else {
                        displayName = fullStoreName;
                    }

                    return (
                        <li key={`item-${index}`} className="mr-5 flex items-center">
                            <svg
                                width={entry.type === 'line' ? "20" : "10"}
                                height={entry.type === 'line' ? "5" : "20"}
                                className="align-middle mr-2"
                            >
                                <rect
                                    width={entry.type === 'line' ? "20" : "10"}
                                    height={entry.type === 'line' ? "5" : "20"}
                                    fill={entry.color || 'lightgray'}
                                />
                            </svg>
                            {/* <Tooltip title={fullStoreName}> */}
                            <span
                                style={{ color: (entry.color || 'lightgray') }}
                                className="text-sm flex items-center"
                            >
                                <span className="mr-2">{displayName}</span>
                                <span className="text-sm font-medium">
                                    {(metric === "Ventas" || metric === "AOV") && "$"}
                                    {formatNumber(total, displayMode !== 'full')}
                                </span>
                            </span>
                            {/* </Tooltip> */}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default CustomLegend;