import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Importa useNavigate
import Footer from '../../../components/Footer';
import { getDocs, collection } from 'firebase/firestore';
import { auth, db } from '../../../helpers/firebase';
import Select from 'react-select';
import SalesMixChart from '../../../components/charts/pie/SalesMixChart';
import StoreComparisonChart from '../../../components/charts/bar/StoreComparison/index';
import { useTopbar } from '../../../helpers/TopbarContext';

export default function GraficosPorProductoC() {
    const { relation } = useTopbar();
    const navigate = useNavigate();  // Declara la función navigate

    const handleCreateRelation = () => {
        navigate('/relacionar-tiendas');  // Navega a la ruta deseada
    };

    const [loading, setLoading] = useState(true);
    const [selectedRelation, setSelectedRelation] = useState(null);
    const [relationsOptions, setRelationsOptions] = useState([]);
    const [productsProp, setProductsProp] = useState([])

    useEffect(() => {
        // getDocs from users/{auth.currentUser.uid}/relatedStores/{relation.id}/relations
        const getRelations = async () => {
            if (!relation) return;
            setLoading(true);

            try {
                const relationsRef = collection(db, `users/${auth.currentUser.uid}/relatedStores/${relation.id}/relations`);
                const relationsSnapshot = await getDocs(relationsRef);

                let relationsOptions = [];

                relationsSnapshot.forEach((doc) => {

                    const docData = doc.data();

                    relationsOptions.push({
                        value: doc.id,
                        label: <div className="flex justify-start items-center">
                            <div className="flex items-center">
                                <img src={docData.thumbnail} alt={docData.name} className="w-8 h-8 rounded-full bg-red-400 mr-2" />
                                {/* <img src={docData.to.thumbnail} alt={docData.name} className="w-8 h-8 rounded-full bg-red-400 mr-2" /> */}
                            </div>
                            <div className="flex items-center ">
                                <span className="text-gray-500 whitespace-nowrap overflow-hidden overflow-ellipsis w-72">
                                    {docData.producto}
                                </span>
                            </div>
                        </div>,
                        from: doc.data().from,
                        to: doc.data().to
                    });
                });

                setRelationsOptions(relationsOptions);

                if (relationsOptions.length > 0) {
                    setSelectedRelation(relationsOptions[0]);
                }
            } catch (error) {
                console.error('Error getting relations: ', error);
            } finally {
                setLoading(false);
            }
        };

        getRelations();
    }, [relation]);

    const [lastScrollTop, setLastScrollTop] = useState(0);

    const handleScroll = (e) => {
        const scrollTop = e.target.scrollTop;  // Obtiene la posición actual del scroll
        if (scrollTop < lastScrollTop) {
            // scrollear hasta arriba el big-container, suavemente
            const bigContainer = document.getElementById('big-container');
            bigContainer.scrollTop = 0;

        }
        setLastScrollTop(scrollTop);  // Actualiza la última posición del scroll
    };

    useEffect(() => {
        const contentToPdf = document.getElementById('content-to-pdf');
        contentToPdf.addEventListener('scroll', handleScroll);

        return () => {
            contentToPdf.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);  // Dependencias en las que el efecto depende

    const productIdsProp = useMemo(() => [selectedRelation?.from?.id, selectedRelation?.to?.id], [selectedRelation?.from?.id, selectedRelation?.to?.id]);
    const storeIdsProp = useMemo(() => [relation?.data?.storeFrom, relation?.data?.storeTo], [relation?.data?.storeFrom, relation?.data?.storeTo]);

    useEffect(() => {
        if (!selectedRelation || !relation) return;

        setProductsProp([{
            id: selectedRelation?.from?.id,
            storeId: relation?.data?.storeFrom
        }, {
            id: selectedRelation?.to?.id,
            storeId: relation?.data?.storeTo
        }]);
    }, [selectedRelation, relation]);

    return (
        <div id="big-container" style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}>

            <div id='content-to-pdf' className='flex flex-col justify-start items-center bg-gray-100 py-0 px-0' style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}>

                {(!relation || !relation.data || !relation.data.storeFrom || !relation.data.storeTo)
                    ? (loading ? (
                        <div className="flex justify-center items-center h-screen w-screen bg-gray-100">
                            <div className="spinner" />
                        </div >
                    ) :
                        // Contenido mostrado cuando NO hay relación
                        <div className="text-center p-5 max-w-lg h-full flex items-center justify-center flex-col">
                            <h1 className="text-xl font-semibold text-gray-800 mb-2">
                                No cuentas con ninguna relación aún
                            </h1>
                            <p className="text-gray-600 mb-4">
                                Las relaciones sirven para comparar tiendas con mismos productos en diferentes marketplaces
                            </p>
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-200" onClick={handleCreateRelation}>
                                Crear una relación entre tiendas
                            </button>
                        </div>
                    ) : (
                        <div className="text-center h-full flex items-center  justify-start flex-col w-full">
                            <div
                                className='flex lex-row justify-between items-center w-full mb-5 px-5 '
                            >
                                <div className="flex flex-col items-end justify-center ">
                                    <p className="text-gray-600 w-full h-full mb-2">
                                        Selecciona un producto para ver los gráficos
                                    </p>
                                    <Select
                                        className="w-96"
                                        options={relationsOptions}
                                        value={selectedRelation}
                                        isSearchable
                                        placeholder="Selecciona una relación"
                                        onChange={setSelectedRelation}
                                    />
                                </div>
                            </div>

                            {selectedRelation && selectedRelation.from && selectedRelation.to && productsProp.length > 0 &&
                                <div className="flex flex-col items-center justify-center pb-10">
                                    <div className="flex flex-row items-center justify-center">
                                        <SalesMixChart productsProp={productsProp} />
                                        <StoreComparisonChart
                                            productIdsProp={productIdsProp}
                                            metricProp="Ventas"
                                            storeIdsProp={storeIdsProp}
                                        />
                                    </div>
                                    <div className="flex flex-row items-center justify-center">
                                        <StoreComparisonChart
                                            productIdsProp={productIdsProp}
                                            metricProp="Pedidos"
                                            storeIdsProp={storeIdsProp}
                                        />
                                        <StoreComparisonChart
                                            productIdsProp={productIdsProp}
                                            metricProp="AOV"
                                            storeIdsProp={storeIdsProp}
                                        />
                                    </div>


                                    <StoreComparisonChart
                                        productIdsProp={productIdsProp}
                                        metricProp="Unidades"
                                        storeIdsProp={storeIdsProp}
                                    />

                                </div>
                            }
                        </div>
                    )}

            </div>
            <Footer />
        </div>
    );
}
