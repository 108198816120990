import React, { useEffect, useMemo, useState } from 'react';
import { Launch, FirstPage, NavigateBefore, NavigateNext, LastPage } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { InfoOutlined } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import mx from '../../assets/flags/4x3/mx.svg';
import amazonIcon from '../../assets/icons/amazon_icon.jpg';
import mercadolibreIcon from '../../assets/icons/mercadolibre_icon.jpg';
import { useOrders } from '../../helpers/OrdersContext';

const ColumnHeader = ({ name, type }) => {
    return (
        <div className={`text-gray-500 text-sm font-semibold uppercase flex flex-row items-center ${name === "Producto" ? "pl-3 w-96" : name === "Tienda" ? "w-48" : name === "Disponibilidad" ? "w-36" : "w-24"} ${name === "Producto" ? "text-left justify-start" : name === "Disponibilidad" ? "text-center justify-center" : "text-right justify-end"}`}>
            <span className="flex items-center">
                {name}
            </span>
        </div>
    );
};

function getClassNamesForColumn(columnId) {
    switch (columnId) {
        case 'producto':
            return 'w-96';
        case 'tienda':
            return 'w-48';
        default:
            return 'w-24';
    }
}

export default function MostSoldProducts({ store, sortingTypeProp }) {
    const [mostSoldProducts, setMostSoldProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const limitPerPage = 5;
    const [totalPages, setTotalPages] = useState(0);
    const [chartWidth, setChartWidth] = useState(window.innerWidth < 768 ? (window.innerWidth - 15) : ((window.innerWidth) * 0.5) - 50);
    const [sortingType, setSortingType] = useState(sortingTypeProp || 'desc');

    const { ordersData, stores, loading } = useOrders();

    // Create a Map for quick store lookups
    const storeMap = useMemo(() => {
        const map = new Map();
        stores.forEach(store => {
            map.set(store.value, {
                customName: store.label,
                color: store.data?.color
            });
        });
        return map;
    }, [stores]);

    useEffect(() => {
        if (sortingTypeProp) setSortingType(sortingTypeProp);
    }, [sortingTypeProp]);

    useEffect(() => {
        const handleResize = () => {
            setChartWidth(window.innerWidth < 768 ? (window.innerWidth - 15) : ((window.innerWidth) * 0.5) - 50);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (!ordersData) return;

        const processProducts = () => {
            const productMap = new Map();

            Object.entries(ordersData).forEach(([storeId, storeData]) => {
                if (store && store.id !== storeId) return;

                storeData.currentPeriod?.forEach(order => {
                    order.items?.forEach(item => {
                        const key = `${item.seller_sku}-${item.title}`;

                        if (!productMap.has(key)) {
                            productMap.set(key, {
                                id: item.product_id,
                                producto: item.title,
                                thumbnail: item.thumbnail,
                                quantity: item.available_quantity || 0,
                                seller_sku: item.seller_sku,
                                store_id: storeId,
                                listing_permalink: storeId.startsWith('ml_') ? `https://articulo.mercadolibre.com.mx/${item.product_id}` : null,
                                asin1: !storeId.startsWith('ml_') ? item.product_id : null,
                                store: storeMap.get(storeId) || { customName: storeId, color: '#000000' },
                                currentPeriodData: {
                                    ventas: 0,
                                    unidades_vendidas: 0
                                },
                                comparisonPeriodData: {
                                    ventas: 0,
                                    unidades_vendidas: 0
                                }
                            });
                        }

                        const product = productMap.get(key);
                        product.currentPeriodData.ventas += item.unit_price * item.quantity;
                        product.currentPeriodData.unidades_vendidas += item.quantity;
                    });
                });

                // Process comparison period
                storeData.comparisonPeriod?.forEach(order => {
                    order.items?.forEach(item => {
                        const key = `${item.seller_sku}-${item.title}`;
                        if (productMap.has(key)) {
                            const product = productMap.get(key);
                            product.comparisonPeriodData.ventas += item.unit_price * item.quantity;
                            product.comparisonPeriodData.unidades_vendidas += item.quantity;
                        }
                    });
                });
            });

            // Convert to array and sort
            const productsArray = Array.from(productMap.values())
                .sort((a, b) => sortingType === 'desc' ?
                    b.currentPeriodData.ventas - a.currentPeriodData.ventas :
                    a.currentPeriodData.ventas - b.currentPeriodData.ventas
                );

            setTotalPages(Math.ceil(productsArray.length / limitPerPage));
            const paginatedProducts = productsArray.slice(currentPage * limitPerPage, (currentPage + 1) * limitPerPage);
            setMostSoldProducts(paginatedProducts);
        };

        processProducts();
    }, [ordersData, currentPage, sortingType, store, stores, storeMap]);

    const columnNames = {
        producto: 'Producto',
        canal: 'Canal',
        tienda: 'Tienda',
        ventas: 'Ventas',
        unidades_vendidas: 'Unidades Vendidas',
        disponibilidad: 'Disponibilidad'
    };

    function renderColumnData(data, columnId, currentPeriodData, comparisonPeriodData) {
        switch (columnId) {
            case 'producto':
                return (
                    <div className="w-96 flex flex-row pl-3 border-b border-gray-200 items-center">

                        <img src={data.thumbnail || "https://via.placeholder.com/150"} alt="Producto" className="w-10 h-10 mr-2 object-contain" />
                        <div className="flex flex-col items-start justify-start">
                            <a
                                href={data.asin1 ?
                                    `https://www.amazon.com.mx/dp/${data.asin1}` :
                                    data.listing_permalink
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex flex-row items-center justify-start"
                            >
                                <p className="text-sm text-blue-500 underline w-72 truncate">
                                    {data.producto || data.id || 'N/A'}
                                </p>
                                <Launch className="ml-2" style={{ fontSize: 15 }} />
                            </a>
                        </div>
                    </div>
                );
            case 'canal':
                return (
                    <div className="w-24 flex flex-row justify-end items-center border-b border-gray-200">
                        <img
                            src={data.store_id.startsWith('ml_') ? mercadolibreIcon : amazonIcon}
                            alt="Canal"
                            className="w-4 h-4 mr-2"
                        />
                        <img src={mx} alt="Country" className="w-4 h-4" />
                    </div>
                );
            case 'tienda':
                return (
                    <div className="w-48 flex flex-row pl-3 items-center border-b border-gray-200">
                        <p className="w-48 text-sm text-right">
                            {data.store?.customName || 'N/A'}
                        </p>
                    </div>
                );
            case 'ventas':
                return (
                    <div className="w-24 flex flex-col items-end justify-center border-b border-gray-200">
                        <p className="w-24 text-sm text-right">
                            {currentPeriodData.ventas.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
                        </p>
                        <p className="w-24 text-xs text-right text-gray-500">
                            {comparisonPeriodData.ventas.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
                        </p>
                    </div>
                );
            case 'unidades_vendidas':
                return (
                    <div className="w-24 flex flex-col items-end justify-center border-b border-gray-200">
                        <p className="w-24 text-sm text-right">
                            {currentPeriodData.unidades_vendidas}
                        </p>
                        <p className="w-24 text-xs text-right text-gray-500">
                            {comparisonPeriodData.unidades_vendidas}
                        </p>
                    </div>
                );
            case 'disponibilidad':
                return (
                    <div style={{ minWidth: '9rem', maxWidth: '9rem' }} className="w-36 flex items-center justify-center border-b border-gray-200">
                        <div className={`whitespace-nowrap flex items-center justify-center text-sm rounded-full px-2 py-1 text-white ${data.quantity > 5 ? 'bg-green-500' : data.quantity > 0 ? 'bg-yellow-500' : 'bg-red-500'}`}>
                            {data.quantity} disponibles
                        </div>
                    </div>
                );
        }
    }

    return (
        <div className='flex flex-col items-start justify-center bg-white rounded-lg shadow-md m-1' style={{ minWidth: chartWidth, maxWidth: chartWidth, height: 400 }}>
            <div className="flex flex-row items-center px-5 text-kompamxblue text-base justify-between w-full" style={{ minHeight: "3.5rem" }}>
                <div className="flex items-center justify-start w-full">
                    <div className="flex flex-row items-center justify-start w-full">
                        <div className="font-semibold mr-2 lg:whitespace-nowrap">
                            {sortingType === 'desc' ? "Productos más vendidos" : "Productos menos vendidos"}
                        </div>
                        <Tooltip arrow title={sortingType === 'desc' ? "En esta tabla se muestran los productos más vendidos de tus tiendas" : "En esta tabla se muestran los productos menos vendidos de tus tiendas"}>
                            <InfoOutlined style={{ fontSize: 18 }} className='ml-auto' />
                        </Tooltip>
                        {!sortingTypeProp && (
                            <div className="text-xs text-gray-500 hover:text-gray-700 cursor-pointer transition duration-100 rounded-full p-1 hover:bg-gray-100">
                                <Tooltip arrow title={sortingType === 'desc' ? "Ordenar de menor a mayor" : "Ordenar de mayor a menor"}>
                                    {sortingType === 'asc' ?
                                        <KeyboardArrowUpIcon
                                            style={{ fontSize: 22 }}
                                            onClick={() => setSortingType('desc')} />
                                        :
                                        <KeyboardArrowDownIcon
                                            style={{ fontSize: 22 }}
                                            onClick={() => setSortingType('asc')}
                                        />
                                    }
                                </Tooltip>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <hr className="w-full border-gray-300" />

            <div className="overflow-x-auto w-full h-full">
                {!loading && mostSoldProducts.length === 0 ? (
                    <div className='h-full flex justify-center items-center'>
                        <p className="text-gray-500 text-lg">No hay productos para mostrar</p>
                    </div>
                ) : (
                    <div className="table-wrapper h-full">
                        <div className='flex h-12'>
                            <div className='bg-gray-100 border-t-2 border-gray-200 flex flex-row items-center'>
                                {Object.keys(columnNames).map((columnId, index) => (
                                    <ColumnHeader
                                        key={index}
                                        name={columnNames[columnId]}
                                        type={columnId}
                                    />
                                ))}
                            </div>
                        </div>
                        {loading ? (
                            <div className='h-full flex justify-center items-center'>
                                <div className="spinner" />
                            </div>
                        ) : (
                            mostSoldProducts.map((product, index) => (
                                <div
                                    key={index}
                                    className={`flex w-full h-12 flex-row items-center`}
                                >
                                    {Object.keys(columnNames).map((columnId, index2) => (
                                        <div
                                            key={index2}
                                            className={`flex w-full h-12 flex-row ${getClassNamesForColumn(columnId)}`}
                                        >
                                            {renderColumnData(product, columnId, product.currentPeriodData, product.comparisonPeriodData)}
                                        </div>
                                    ))}
                                </div>
                            ))
                        )}
                    </div>
                )}
            </div>

            <div className="flex flex-row justify-end items-center px-4 w-full py-1">
                <IconButton onClick={() => setCurrentPage(0)} disabled={currentPage === 0}>
                    <FirstPage />
                </IconButton>
                <IconButton onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0}>
                    <NavigateBefore />
                </IconButton>
                <IconButton onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage >= totalPages - 1}>
                    <NavigateNext />
                </IconButton>
                <IconButton onClick={() => setCurrentPage(totalPages - 1)} disabled={currentPage === totalPages - 1}>
                    <LastPage />
                </IconButton>
            </div>
        </div>
    );
}

